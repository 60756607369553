import React from 'react'

import styles from "./modalHelp.module.scss";
import GradientButton from "../../UI/GradientButton/GradientButton";


export default function ModalHelp({ visible, onClose })
{
    return (
        <>
            <div
                className={styles.wrapper}
                style={
                    !visible ? { display: "none" } : { display: "flex" }
                }
            >

                <div className={styles.container}>
                    <div className={styles.title}>
                        <p>Инструкция по использованию студии</p>
                    </div>
                    <div className={styles.body}>
                        <div className={styles.body__text_wrapper}>
                            <p>
                                На данный момент существует мобильная и web-версия студии, которые отличаются по своему функционалу. В мобильной версии доступны не все опции web-версии. Например, менее удобно редактировать треки, т.к. точность и контакт от нажатия пальцем менее корректный, чем точность клика мышки. Поэтому сейчас мы рекомендуем вам использовать именно web-версию, чтобы весь доступный функционал работал на все 100%.
                                <br /> В скором времени мы улучшим мобильную версию до уровня web.
                            </p>
                            <h2>Начало работы</h2>

                            <ol>
                                <li>Вы можете загрузить собственный трек и начать запись без регистрации.</li>
                                <li>Если вы хотите сохранить проект, чтобы к нему потом вернуться, то потребуется регистрация на сайте <a target="_blank" href="https://muznavigator.com/">https://muznavigator.com/</a></li>
                                <li>Если вы планируете использовать треки из каталога <a target="_blank" href="https://muznavigator.com/">https://muznavigator.com/</a>, то вам необходима регистрация в качестве покупателя.</li>
                                <li>
                                    На всех треках каталога будет кнопка в студию, при нажатии которой трек будет сохраняться в вашем кабинете сайта <a target="_blank" href="https://muznavigator.com/">https://muznavigator.com/</a>.
                                    <br /><br />
                                    Из этого кабинета вы можете перейти в студию и начать работу.
                                </li>
                                <li>
                                    Скачать записанный готовый трек из каталога <a target="_blank" href="https://muznavigator.com/">https://muznavigator.com/</a> можно только после его оплаты, но сохранять проект работы (ваши записанные сессии) с ним вы можете до оплаты в личном кабинете.
                                </li>
                            </ol>

                            <h2>Настройка микрофона</h2>

                            <ol>
                                <li>
                                    В нижней части студии есть иконка <span>микрофон</span>, кликните на нее и попробуйте разные режимы, в том числе и мониторинг.
                                    <br /><br />Мониторинг - это возможность слышать свой голос во время записи, при этом можно включить обработку, но она записываться не будет.
                                </li>
                                <li>
                                    Не все компьютеры поддерживают запись и мониторинг одновременно, возможны задержки. Это зависит от вашего устройства. Если задержка есть, мониторинг нужно отключить.
                                </li>
                                <li>
                                    Важно понимать, если вы будете записывать вокал, используя штатный микрофон без наушников, то качество записи исполнения будет очень низкое: микрофон записывает и звучащую фонограмму. Без наушников качественная запись невозможна.
                                </li>
                            </ol>

                            <h2>Качество записи</h2>

                            <ol>
                                <li>
                                    Чем лучше ваша гарнитура или микрофон, тем выше качество записи.
                                </li>
                                <li>
                                    Не используйте беспроводную гарнитуру: она дает гарантированную задержку.
                                </li>
                                <li>
                                    Подберите нужное расстояние от микрофона – это 10-20 см.
                                </li>
                                <li>
                                    Пойте всегда в микрофон, не отворачивайтесь. Чем больше будете вкладывать энергию в вокал, тем лучше будете звучать.
                                </li>
                                <li>
                                    Режим <span>метроном</span> работает только, когда включена запись.
                                </li>
                            </ol>

                            <h2>Обработка звука, редакция</h2>

                            <p>Вам доступны следующие возможности:</p>

                            <ol>
                                <li>
                                    Можно обрезать трек в любом месте, неограниченное количество раз.
                                </li>
                                <li>
                                    Перенос треков на соседние дорожки: вы можете собрать из кусочков нужную вам дорожку.
                                </li>
                                <li>
                                    Копирование треков.
                                </li>
                                <li>
                                    Изменение громкости каждого трека.
                                </li>
                                <li>
                                    Изменение панорамы каждого трека.
                                </li>
                                <li>
                                    Можете отключать любую дорожку, ставить на мьют.
                                </li>
                            </ol>

                            <h2>Обработка звука эффектами</h2>

                            <ol>
                                <li>
                                    Вам доступны отдельные плагины в разделе <span>эффекты</span>.
                                    <br />
                                    Каждый плагин можно настроить индивидуально на каждую дорожку.
                                </li>
                                <li>
                                    Чтобы отключить плагин, вам необходимо в верхней части панели, где появляются значки включенных плагин, нажать на него. Откроется настройка плагина: в правой верхней части будет функция <span>off</span>, которую необходимо  поставить в нужное положение и нажать <span>применить</span>. После этого плагин отключится.
                                </li>
                                <li>
                                    После каждой редакции плагина необходимо всегда нажимать кнопку <span>применить</span>.
                                </li>
                                <li>
                                    На одну дорожку можно применить все плагины.
                                </li>
                                <li>
                                    Можно использовать готовые пресеты. При включении пресета все плагины, которые задействованы в этом пресете, появятся в верхней части, рядом с кнопками <span>эффекты</span>.
                                    <br />Вы можете зайти в каждый плагин и отредактировать.
                                </li>
                                <li>
                                    Для отключения пресета нажмите на кнопку <span>пресеты</span>: появится весь список, и в самом верху - слово <span>нет</span> - это отключение всех пресетов.
                                </li>
                                <li>
                                    На одну дорожку может быть включен только один пресет.
                                </li>
                            </ol>

                            <h2>Важные советы</h2>

                            <ol>
                                <li>
                                    Если после записи вы услышали, что вокальная дорожка отстает от минуса, то это можно быстро исправить, используйте функцию - синхронизация.
                                </li>
                                <li>
                                    Так же  можно изменять темп минуса.
                                    <br />В правом нижнем углу будет уже стоять темп, в котором сейчас играет ваш минус. Стрелками рядом -+ вы можете его изменить. После изменения нажмите на галочку, чтобы изменения применились.
                                </li>
                                <li>
                                    Рядом с темпом автоматически определяется тональность минуса.
                                    <br />Важно, чтобы тональность определилась. Если это поле будет пустое, то нужно перезагрузить минус еще раз. Автотюн не работает без определения темпа.
                                </li>
                                <li>
                                    На одну дорожку нельзя дописывать кусочки вокала.
                                    <br />Рекомендуем вам  следующую схему записи вокала.
                                    <br />Записать куплет или целиком песню, послушать, вырезать лучшие кусочки вокала и переместить их на соседнюю дорожку. После этого полностью удалить плохой дубль.  Исполнить трек еще раз, выбрать опять часть вокала, которая вам нравится, вырезать её, вставить на ту же дорожку, на которую вы до этого переместили удачное исполнение. Таким образом, на одной дорожке вы можете записывать черновики, выбирать лучшее и перемещать на соседнюю дорожку, собирая единую вокальную сессию.
                                </li>
                                <li>
                                    После записи вокала рекомендуем всегда использовать плагин <span>нормалайз</span>, поднимающий уровень звучания вокала.
                                </li>
                                <li>
                                    Рекомендуем не опускать уровень громкости минуса. Если после сохранения трек будет играть тихо, потребуется его отмастерить: в интернете много есть сервисов, где можно онлайн бесплатно поднять уровень громкости трека.
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div className={styles.footer}>
                        <GradientButton size={"modal"} onClick={onClose}>
                            Закрыть
                        </GradientButton>
                    </div>
                </div>
            </div>
        </>
    );
}