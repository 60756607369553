import React, { useCallback, useEffect, useRef } from 'react';
import useState from 'react-usestateref'
import {Howl, Howler} from 'howler';


export default function useAudioPlayer() {

    const [tracksData, setTracksData, tracksDataRef] = useState([]);
    const [duration, setDuration, durationRef] = useState(0);
    const [currentTime, setCurrentTime, currentTimeRef] = useState(0);

    //console.log('INIT useAudioPlayer');

    const tracksPlayerRefs = useRef([]);
    const tracksIds = useRef([]);

    const _timer = useRef(null);

    const initPlayer = useCallback((tracks, pos) => {
        setTracksData([]);
        setCurrentTime(0);
        tracksPlayerRefs.current = [];

        setTracksData(tracks);

        if (pos) {
            setCurrentTime(parseFloat(pos))
        } else {
            setCurrentTime(0.001);
        }

        for (let i = 0; i < tracks.length; i++) {
            tracksPlayerRefs.current[i] = new Howl({
                src: tracks[i].audio,
                volume: tracks[i].mute ? 0 : tracks[i].volume,

                onstereo: function() {
                },

                onplay: function() {
                }
            });

            tracksPlayerRefs.current[i].play();
            tracksPlayerRefs.current[i].pause();

        }
    }, []);

    /**
     * Play all tracks
     * @type {(function())|*}
     */
    const play = useCallback(() => {
        for (let i = 0; i < tracksPlayerRefs.current.length; i++) {
            if (currentTimeRef.current > 0) {
                tracksPlayerRefs.current[i].seek(currentTimeRef.current);
            }

            tracksPlayerRefs.current[i].stereo(tracksDataRef.current[i].balance);
            tracksPlayerRefs.current[i].play();
        }


        _timer.current = setInterval(() => {
            if (!tracksPlayerRefs.current[0]) {
                return false;
            }

            let maxDuration = 0;
            for (let i = 0; i < tracksPlayerRefs.current.length; i++) {
                if (tracksPlayerRefs.current[i].duration() > maxDuration) {
                    maxDuration = tracksPlayerRefs.current[i].duration();
                }
            }

            const maxCurrentTime = Math.max.apply(Math, tracksPlayerRefs.current.map(function(o) { return o.seek(); }));

            setCurrentTime(maxCurrentTime);
            setDuration(maxDuration);
        }, 10);


    }, []);

    /**
     * Pause all tracks
     * @type {(function())|*}
     */
    const pause = useCallback(() => {
        if (!tracksPlayerRefs.current) {
            return false;
        }

        for (let i = 0; i < tracksPlayerRefs.current.length; i++) {
            tracksPlayerRefs.current[i].stop();
        }

        clearInterval(_timer.current);

    }, []);

    /**
     * Set position of all tracks
     */
    const setPosition = useCallback((start) => {
        if (!tracksPlayerRefs.current) {
            return false;
        }

        for (let i = 0; i < tracksPlayerRefs.current.length; i++) {
            if (tracksPlayerRefs.current[i]) {
                tracksPlayerRefs.current[i].seek(start);
            }
        }

    }, []);

    /**
     * Change volume of all tracks
     * @type {(function())|*}
     */
    const changeTrackVolume = useCallback((track, volume) => {
        if (!tracksPlayerRefs.current || !tracksDataRef.current) {
            return false;
        }

        // get index of track by id
        const index = tracksDataRef.current.findIndex((item) => item.id === track);

        if (tracksPlayerRefs.current[index] !== undefined) {
            tracksPlayerRefs.current[index].volume(volume);
        }
    }, []);

    /**
     * Change balance of all tracks
     * @type {(function())|*}
     */
    const changeTrackBalance = useCallback((track, balance) => {
        if (!tracksPlayerRefs.current || !tracksDataRef.current) {
            return false;
        }

        // get index of track by id
        const index = tracksDataRef.current.findIndex((item) => item.id === track);

        if (tracksPlayerRefs.current[index] !== undefined) {
            //tracksPlayerRefs.current[index].stereo(-1);
            tracksPlayerRefs.current[index].stereo(parseFloat(balance));
        }
    }, []);

    /**
     * Change mute of all tracks
     * @type {(function())|*}
     */
    const changeTrackMute = useCallback((track, mute) => {
        if (!tracksPlayerRefs.current || !tracksDataRef.current) {
            return false;
        }

        // get index of track by id
        const index = tracksDataRef.current.findIndex((item) => item.id === track);

        if (tracksPlayerRefs.current[index] !== undefined) {
            tracksPlayerRefs.current[index].mute(mute);
        }
    }, []);


    useEffect(() => {
        return () => {
            //console.log('UNMOUNT useAudioPlayer');
            clearInterval(_timer.current);
        }
    }, []);

    /*useEffect(() => {

        if (tracksPlayerRefs.current.length) {
            for (let index = 0; index < tracksPlayerRefs.current.length; index++) {
                if (tracksPlayerRefs.current[index]) {
                    tracksPlayerRefs.current[index].stereo(tracksDataRef.current[index].balance);
                }
            }
        }

    }, [tracksData]);*/

    return [
        initPlayer,
        play,
        pause,
        setPosition,
        currentTime,
        duration,
        changeTrackVolume,
        changeTrackBalance,
        changeTrackMute
    ];
}

export { useAudioPlayer };