import React from "react";
import PropTypes from 'prop-types';
import Modal from "../Modal";
import { ReactComponent as HourGlass } from "../../../assets/icons/hourglass.svg";

export default function ModalSave({ title, subtitle, isVisible, onClose }) {
    return (
        <>
            <Modal
                type={"ИДЁТ СОХРАНЕНИЕ"}
                title={title}
                sub={subtitle}
                gray={"отменить"}
                svg={<HourGlass />}
                visible={isVisible}
                onClose={onClose}
            />
        </>
    );
}

ModalSave.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
};

ModalSave.defaultProps = {
    title: "Пожалуйста, подождите, пока процесс не завершится.",
    subtitle: "Внимание! Это может занять некоторое время."
}

