import React, {useContext, useCallback, useState, useRef} from 'react'
import { useDrop } from 'react-dnd'
import styles from './audioTrack.module.scss'
import cn from 'classnames'
import context from "../../context/Context";
import AudioTrackFragment from "../AudioTrackFragment/audioTrackFragment";
import {cut} from "../../api/api";
import {toast} from "react-toastify";

export default function AudioTrack({ item, localMousePos }) {

    const {
        updateAudioById,
        getAudioById,
        maxTrackDuration,
        gridVariantsItems,
        gridVariant,
        currentAudioTrack,
        setCurrentAudioTrack,
        setCurrentAudioFragment,
        playingPosition,
        activeCursor,
        changeActiveCursor,
        isPlaying,
        setIsProcessModalVisible,
        setCopyingFragment,
        copyingFragment,
        copyingFragmentTrack,
        setCopyingFragmentTrack,
        mockAudioData,
        addToHistoryHandler,
        isRecording
    } = useContext(context)

    const gridOptions = gridVariantsItems.filter((item) => item.id === gridVariant)[0];

    const moveBox = useCallback(
        (id, left, top, sourceTrackId) => {
            if (left < 0)
                left = 0;

            // Change audio track
            if (sourceTrackId !== item.id) {
                let sourceTrack = getAudioById(sourceTrackId);
                let targetTrack = getAudioById(item.id);

                const audioFragment = sourceTrack.samples.filter((sample) => sample.id === id)[0];

                targetTrack.audio = '';
                targetTrack.samples.push({
                    ...audioFragment,
                }); // audio fragment

                // Change position
                targetTrack.samples.map((sample, index) => {
                    if (sample.id === id) {
                        targetTrack.samples[index].start = left;
                    }
                });

                // Sort samples
                targetTrack.samples.sort((a, b) => {
                    return a.start - b.start;
                });

                // Fix potential overlapping
                targetTrack.samples.map((sample, index) => {
                    if (index > 0) {
                        let left = sample.start;
                        const prev = targetTrack.samples[index - 1];
                        if (left < prev.start + prev.duration * gridOptions.secondsInPixels + 1) {
                            left = prev.start + prev.duration * gridOptions.secondsInPixels + 1;

                            targetTrack.samples[index].start = left;
                        }
                    }
                })

                // Save changes to new track
                updateAudioById(targetTrack.id, targetTrack);

                // Remove track from source
                sourceTrack.samples = sourceTrack.samples.filter((sample) => sample.id !== id);
                sourceTrack.audio = '';
                updateAudioById(sourceTrack.id, sourceTrack);
            } else {

                // Change position
                item.samples.map((sample, index) => {
                    if (sample.id === id) {
                        item.samples[index].start = left;
                    }
                });

                // Sort samples
                item.samples.sort((a, b) => {
                    return a.start - b.start;
                });

                // Fix potential overlapping
                item.samples.map((sample, index) => {
                    if (index > 0) {
                        let left = sample.start;
                        const prev = item.samples[index - 1];
                        if (left < prev.start + prev.duration * gridOptions.secondsInPixels + 1) {
                            left = prev.start + prev.duration * gridOptions.secondsInPixels + 1;

                            item.samples[index].start = left;
                        }
                    }
                })

                // Save changes
                item.audio = '';
                updateAudioById(item.id, item);
            }

            addToHistoryHandler()
        },
        [item, mockAudioData],
    )

    const [, drop] = useDrop(
        () => ({
            accept: 'audio',
            drop(dropItem, monitor) {
                //alert('drop- id: ' + dropItem.id + ' left: ' + dropItem.left + ' top: ' + dropItem.top);

                /*console.log('Source track ID: ', dropItem.sourceTrackId);
                console.log(monitor)
                console.log('TrackID: ', item.id);*/

                const delta = monitor.getDifferenceFromInitialOffset()
                const left = Math.round(dropItem.left + delta.x)
                const top = Math.round(dropItem.top + delta.y)

                moveBox(dropItem.id, left, top, dropItem.sourceTrackId)
                return undefined
            },
        }),
        [moveBox],
    )

    const handleClick = (e) => {

        if (isRecording) {
            return;
        }

        // Check if click on fragment
        if (!e.target.classList.contains(styles.wrapper)) {
            // find current fragment
            let currentFragment = item.samples.filter((sample) => {
                return sample.start <= localMousePos.x && sample.start + sample.duration * gridOptions.secondsInPixels >= localMousePos.x
            })[0];

            if (currentFragment === undefined) {
                return;
            }

            setCurrentAudioFragment(currentFragment.id);

            const trackId = item.id;
            const left = currentFragment.start;
            const audio = currentFragment.audio;

            // eslint-disable-next-line default-case
            switch (activeCursor) {
                case 'cut':

                    let track = getAudioById(trackId);

                    if (!isPlaying) {
                        setIsProcessModalVisible(true);

                        const baseUrl = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:5001';
                        const cutPosition = localMousePos.x - left;

                        cut(audio, 0, cutPosition / gridOptions.secondsInPixels, function (response) {


                            const secondOffset = left + response.data.duration.seconds * gridOptions.secondsInPixels + 1;

                            track.samples.push({
                                id: response.data.id,
                                audio: baseUrl + "/" + response.data.audio,
                                mp3: baseUrl + "/" + response.data.audio,
                                start: left,
                                duration: response.data.duration.seconds,
                                position: 0,
                                fadeIn: 0,
                                fadeOut: 0,
                                minus: item.minus
                            });

                            //

                            cut(audio, cutPosition / gridOptions.secondsInPixels, 0, function (response) {

                                let track = getAudioById(trackId);
                                track.samples.push({
                                    id: response.data.id,
                                    audio: baseUrl + "/" + response.data.audio,
                                    mp3: baseUrl + "/" + response.data.audio,
                                    start: secondOffset,
                                    duration: response.data.duration.seconds,
                                    position: 0,
                                    fadeIn: 0,
                                    fadeOut: 0,
                                    minus: item.minus
                                });

                                setIsProcessModalVisible(false);

                                // Remove current sample
                                track.samples = track.samples.filter((item) => item.id !== currentFragment.id);
                                track.samples.sort((a, b) => {
                                    return a.start - b.start;
                                });
                                track.audio = '';

                                track.samples.forEach((e, index) => {
                                    if (e.start > 1) {
                                        const prev = track.samples[index - 1];

                                        if (e.start < prev.start + prev.duration * gridOptions.secondsInPixels + 1) {
                                            e.start = prev.start + prev.duration * gridOptions.secondsInPixels + 1;
                                        }
                                    }
                                })

                                updateAudioById(track.id, track);

                                addToHistoryHandler();


                            }, function (error) {
                            });

                        }, function (error) {
                        });

                    }

                    changeActiveCursor('default');

                    break;

                case 'copy':
                    if (!isPlaying) {
                        setCopyingFragment(currentFragment.id);
                        setCopyingFragmentTrack(trackId);

                        toast.success('Фрагмент скопирован. Выберите место для вставки.');
                    }

                    break;

                case 'delete':
                    if (!isPlaying) {
                        let item = getAudioById(trackId)
                        item.audio = '';
                        item.samples = item.samples.filter((item) => item.id !== currentFragment.id);
                        updateAudioById(trackId, item);

                        addToHistoryHandler();
                    }

                    changeActiveCursor('default');

                    break;
            }

        } else {
            setCurrentAudioFragment(0);

            // paste fragment

            const position = Math.floor(localMousePos.x / gridOptions.secondsInPixels);

            if (!isPlaying && copyingFragment !== null && activeCursor === 'copy') {

                const trackSource = getAudioById(copyingFragmentTrack);
                const fragment = trackSource.samples.filter((item) => item.id === copyingFragment)[0];

                item.audio = '';
                item.samples.push({
                    ...fragment,
                    id: fragment.id + Date.now(),
                    start: position * gridOptions.secondsInPixels + 1
                });

                item.samples.sort((a, b) => {
                    return a.start - b.start;
                });

                item.samples.forEach((e, index) => {
                    if (e.start > 1) {
                        const prev = item.samples[index - 1];

                        if (prev === undefined) {
                            return;
                        }

                        if (e.start < prev.start + prev.duration * gridOptions.secondsInPixels + 1) {
                            e.start = prev.start + prev.duration * gridOptions.secondsInPixels + 1;
                        }
                    }
                });

                updateAudioById(item.id, item);

                setCopyingFragment(null);
                setCopyingFragmentTrack(null);

                changeActiveCursor('default');

                addToHistoryHandler();
            }
        }

        setCurrentAudioTrack(item.id);
    }

    const setFadeInHandler = (id, val) => {
        let track = getAudioById(item.id);
        track.audio = '';
        track.samples.map((sample, index) => {
            if (sample.id === id) {
                track.samples[index].fadeIn = val;
            }
        });
        updateAudioById(track.id, track);
    }

    const setFadeOutHandler = (id, val) => {
        let track = getAudioById(item.id);
        track.audio = '';
        track.samples.map((sample, index) => {
            if (sample.id === id) {
                track.samples[index].fadeOut = val;
            }
        });
        updateAudioById(track.id, track);
    }

    return (
        <div ref={drop}
             className={cn(styles.wrapper, currentAudioTrack === item.id && styles[item.style], item.mute && styles.mute)}
             style={{width: maxTrackDuration * gridOptions.secondsInPixels}}
             onClick={handleClick}>
            {item.samples.length > 0 && (
                <>
                    {item.samples.map((sample) => (
                        <AudioTrackFragment
                            audio={sample.audio}
                            id={sample.id}
                            key={sample.id}
                            style={item.style}
                            left={sample.start}
                            width={sample.duration * gridOptions.secondsInPixels}
                            mute={item.mute}
                            trackId={item.id}
                            duration={sample.duration}
                            fadeIn={item.minus ? 0 : sample.fadeIn}
                            fadeOut={item.minus ? 0 : sample.fadeOut}
                            setFadeIn={(val) => setFadeInHandler(sample.id, val)}
                            setFadeOut={(val) => setFadeOutHandler(sample.id, val)}
                            isMinus={item.minus}
                        />
                    ))}
                </>
            )}
        </div>
    )
}