import React, { useState } from "react";
import { SwiperSlide } from "swiper/react";
import useMediaQuery from "../../../utils/hooks/common/useMediaQuery";
import EffectsBody from "../../EffectsBody/EffectsBody";
import Slider from "../../Slider/Slider";
import SliderCarousel from "../../SliderCarousel/SliderCarousel";
import GradientButton from "../../UI/GradientButton/GradientButton";
import styles from "./fxSnap.module.scss";

export default function FxSnap({
                                   active, setActive, amount, setAmount, speed, setSpeed, onSubmit
}) {
    const mob915 = useMediaQuery(915);

    const speedVariants = [
        { number: "20" },
        { number: "16" },
        { number: "12" },
        { number: "8" },
        { number: "4" },
        { number: "1" },
    ];

    const amountVariants = [
        { number: "100" },
        { number: "80" },
        { number: "60" },
        { number: "40" },
        { number: "20" },
        { number: "0" },
    ];

    const changeSpeed = (e) => {
        setSpeed(e.target.value);
    }

    const changeAmount = (e) => {
        setAmount(e.target.value);
    }

    return (
        <EffectsBody
            color={"yellow"}
            title={"Autotune"}
            thumbColor={"yellow"}
            width={mob915 !== true && "sm"}
            active={active}
            setActive={setActive}
            buttonsColor={"yellowRevert"}
            onSubmit={onSubmit}
        >
            <div className={styles.slider}>
                <Slider number={speedVariants} color={4} min={1} max={20} step={1} value={speed} setValue={changeSpeed} footer={"Speed"} colorInput={"yellow"} />
                <Slider number={amountVariants} color={4} min={0} max={100} step={1} value={amount} setValue={changeAmount} footer={"Amount"} colorInput={"yellow"} />
            </div>
        </EffectsBody>
    );
}
