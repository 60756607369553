import React, {useState, useRef} from 'react'

import styles from "./modalProjectForm.module.scss";
import GradientButton from "../../UI/GradientButton/GradientButton";

export default function ModalProjectForm({ visible, onClose, onSubmit })
{
    const formRef = useRef()

    const [formData, setFormData] = useState({
        title: '',
    });

    const {
        title,
    } = formData;

    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const saveHandler = () => {
        onSubmit(formData)
        onClose()
    }

    const submitHandler = (e) => {
        e.preventDefault()
        onSubmit(formData)
        onClose()
    }

    return (
        <>
            <div
                className={styles.modal__background}
                style={
                    !visible ? { display: "none" } : { display: "flex" }
                }
            ></div>
            <div
                className={styles.wrapper}
                style={
                    !visible ? { display: "none" } : { display: "flex" }
                }
            >
                <div className={styles.container}>
                    <div className={styles.body}>
                        <p>Сохранение проекта</p>
                        <p>Введите название проекта.</p>
                    </div>
                    <div className={styles.formWrapper}>

                        <form ref={formRef} onSubmit={submitHandler}>
                            <div className={styles.form_group}>
                                <label htmlFor="title">
                                    Название проекта:
                                </label>
                                <input
                                    type="text" name="title" id="title" required
                                    placeholder="" value={title} onChange={onChange} />
                            </div>
                        </form>
                    </div>
                    <div className={styles.footer}>
                        <GradientButton size={"modal"} color={"violet"} onClick={saveHandler}>
                            Сохранить
                        </GradientButton>
                        <GradientButton size={"modal"} onClick={onClose}>
                            Отмена
                        </GradientButton>
                    </div>
                </div>
            </div>
        </>
    );
}