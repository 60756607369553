import React, {useContext, useState} from 'react';
import styles from "./synchronizer.module.scss";

import { ReactComponent as Plus } from "../../assets/icons/plus.svg";
import { ReactComponent as Minus } from "../../assets/icons/minus.svg";
import context from "../../context/Context";
 
export default function Synchronizer ({ handleMoveFragment })
{
  const {
    currentAudioFragment,
    moveAudioFragment,
  } = useContext(context)

  const [value, setValue] = useState(0)

  const handleMinusClick = () => {
    if (currentAudioFragment) {
      if (moveAudioFragment(currentAudioFragment, 'left', 1)) {
        setValue(value - 1)
      }
    }
  }

  const handlePlusClick = () => {
    if (currentAudioFragment) {
      moveAudioFragment(currentAudioFragment, 'right', 1)
      setValue(value + 1)
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.synchronizer}>
        <h3>Синхронизация</h3>
        <p>Если вокал отстает от минуса,<br />выделите вокал и двигайте + или -</p>

        <div className={styles.synchronizer__control}>
          <div className={styles.synchronizer__control__minus} onClick={handleMinusClick}>
            <Minus />
          </div>
          <div className={styles.synchronizer__control__value}>
            {value}
          </div>
          <div className={styles.synchronizer__control__plus} onClick={handlePlusClick}>
            <Plus />
          </div>
        </div>
      </div>
    </div>
  )
}