import axios from 'axios'

const API_LOGIN_URL = '/api/users/login'

// Log in user
const login = async (userData) => {

    const response = await axios.post(API_LOGIN_URL, userData)

    if (response.data) {
        if (userData.rememberMe) {
            localStorage.setItem('user', JSON.stringify(response.data))
        } else {
            sessionStorage.setItem('user', JSON.stringify(response.data))
        }
        //localStorage.setItem('user', JSON.stringify(response.data))
    }

    return response.data
}

// Log out user
const logout = () => {
    localStorage.removeItem('user');
    sessionStorage.removeItem('user');
}


const authService = {
    login,
    logout,
}

export default authService