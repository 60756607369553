import React, {useContext, useEffect, useState} from "react";
import { Tooltip } from 'react-tooltip';
import useMediaQuery from "../../../utils/hooks/common/useMediaQuery";
import Context from "../../../context/Context";

import { ReactComponent as Plus } from "../../../assets/icons/plus.svg";
import { ReactComponent as Minus } from "../../../assets/icons/minus.svg";
import { ReactComponent as CheckIcon } from "../../../assets/icons/check.svg";
import cn from "classnames";
import styles from "./tempoControl.module.scss";
import {changeTempo} from "../../../api/api";

export default function TempoControl()
{
    const id = 1;

    const mob1024 = useMediaQuery(1024);
    const {
        minusTempo,
        setMinusTempo,
        addCommandToQueue,
        getAudioById,
        updateAudioById,
        isPlaying,
        mockAudioData
    } = useContext(Context)

    const [disabled, setDisabled] = useState(true)
    const [isUnsaved, setIsUnsaved] = useState(false)
    const [tempo, setTempo] = useState(minusTempo)
    const [key, setKey] = useState('-')

    const tempoPlus = () => {
        if (disabled) return;

        setTempo(tempo + 1)
    }

    const tempoMinus = () => {
        if (disabled) return;

        if (tempo > 0) {
            setTempo(tempo - 1)
        }
    }

    const applyTempo = () => {
        if (disabled) return;

        let item = getAudioById(id)

        // Change tempo
        addCommandToQueue(changeTempo, {
            url: item.samples[0].audio,
            tempo: minusTempo,
            newTempo: tempo
        }, function (response) {

            let item = getAudioById(id)
            item.samples[0].audio = "/" + response.data.file;
            item.samples[0].mp3 = "/" + response.data.file;
            item.samples[0].duration = response.data.duration.seconds;
            item.audio = "/" + response.data.file;
            item.bpm = tempo;

            updateAudioById(id, item);
            setMinusTempo(tempo);
            setIsUnsaved(false);

        }, function (error) {
            console.error(error);
        });

    }

    useEffect(() => {
        if (minusTempo === 0 || isPlaying) {
            setDisabled(true);
        } else if (minusTempo > 0) {
            setDisabled(false);
        }

        setTempo(minusTempo);
    }, [minusTempo, isPlaying]);

    useEffect(() => {
        if (minusTempo === tempo) {
            setIsUnsaved(false);
        } else {
            setIsUnsaved(true);
        }
    }, [tempo]);

    useEffect(() => {
        if (mockAudioData && mockAudioData[0].key) {
            setKey(mockAudioData[0].key);
        }
    }, [mockAudioData]);

    return (
        <>
            <div className={cn(styles.wrapper, disabled && styles.temp_section_disabled)}>
                <div className={styles.key} data-tooltip-id="my-tooltip-key">
                    <p>
                        {key}
                    </p>
                </div>
                <p className={styles.title}>Темп</p>
                <div className={styles.tempo}>
                    <div className={styles.plus} onClick={tempoMinus}  data-tooltip-id="my-tooltip-temp-minus">
                        <Minus />
                    </div>
                    <p>
                        {tempo}
                        {mob1024 === true && <span>темп</span>}
                    </p>
                    <div className={styles.minus} onClick={tempoPlus} data-tooltip-id="my-tooltip-temp-plus">
                        <Plus />
                    </div>
                    <div className={cn(styles.check, !isUnsaved && styles.check_disabled)} onClick={applyTempo} data-tooltip-id="my-tooltip-temp-apply">
                        <CheckIcon />
                    </div>
                </div>
            </div>

            <Tooltip
                id="my-tooltip-key"
                place="top"
                content="Тональность"
            />

            <Tooltip
                id="my-tooltip-temp-plus"
                place="top"
                content="Увеличить темп"
            />

            <Tooltip
                id="my-tooltip-temp-minus"
                place="top"
                content="Уменьшить темп"
            />

            <Tooltip
                id="my-tooltip-temp-apply"
                place="top"
                content="Применить изменения"
            />
        </>
    )
}