import React, { useEffect, useRef } from "react";
import useState from 'react-usestateref'
import getBlobDuration from 'get-blob-duration'
import Tuna from "tunajs";
import {ToastContainer} from "react-toastify";

import Context from "./context/Context";
import usePrevious from "./utils/hooks/common/usePrevious";
import useSoundAdvanced from "./utils/hooks/common/useSoundAdvanced";
import useBeatDetect from "./utils/hooks/project/useBeatDetect";
import useQueue from "./utils/hooks/project/useQueue";
import useMediaQuery from "./utils/hooks/common/useMediaQuery";
import useTrackHistory from "./utils/hooks/project/useTrackHistory";
import useAuthStatus from "./utils/hooks/common/useAuthStatus";

import ControlPanel from "./components/ControlPanel/ControlPanel";
import Header from "./components/Header/Header";
import Mixers from "./components/Mixers/Mixers";
import RecordingPanel from "./components/RecordingPanel/RecordingPanel";
import AudioTracks from "./components/AudioTracks/audioTracks";
import ModalProcessing from "./components/Modal/Modals/ModalProcessing";
import ModalMicOptions from "./components/Modals/ModalMicOptions/modalMicOptions";

import { gridVariants } from "./utils/constants/grid";
import { mockAudioItems } from "./utils/constants/mockAudioItems";
import {
    normalize,
    concat,
    getPresets,
    findKey,
    getExternalTrackData,
    loadOrCreateProjectForTrack,
    getProjectData
} from "./api/api";

import metronomeClickPrimary from './assets/metronome_sounds/click2.wav';
import metronomeClickSecondary from './assets/metronome_sounds/click1.wav';

import 'react-toastify/dist/ReactToastify.min.css'
import "./assets/scss/index.scss";
import useMetronome from "./utils/hooks/project/useMetronome";
import ModalError from "./components/Modal/Modals/ModalError";
import useErrorModal from "./utils/hooks/project/useErrorModal";
import ModalLogin from "./components/Modals/ModalLogin/modalLogin";
import useSoundAdvancedNew from "./utils/hooks/common/useSoundAdvancedNew";
import useAudioPlayer from "./utils/hooks/common/useAudioPlayer";

import {login, reset} from "./features/auth/authSlice";
import {useDispatch, useSelector} from "react-redux";
import ModalLoad from "./components/Modal/Modals/ModalLoad";
import CookiesConsent from "./components/CookiesConsent/CookiesConsent";
import Metrika from "./components/Metrika/Metrika";

function App()
{
    const dispatch = useDispatch()

    const helpIsRead = localStorage.getItem('helpIsRead') || false;

    const {loggedIn, checkingStatus} = useAuthStatus()
    const {user} = useSelector((state) => state.auth)

    const searchParams = new URLSearchParams(document.location.search)
    const trackId = searchParams.get('track_id') || null
    const token = searchParams.get('token') || null

    //console.log('searchParams trackId - ', trackId);
    //console.log('searchParams token - ', token);

    const mob915 = useMediaQuery(915);
    const beatDetect = useBeatDetect()

    const _playingTimer = useRef(null);

    const [minusTempo, setMinusTempo] = useState(0);

    // Config
    const [presets, setPresets] = useState([]);
    const [currentPresetId, setCurrentPresetId] = useState('');
    const [currentEffectId, setCurrentEffectId] = useState('');

    const [secondsInPixels, setSecondsInPixels] = useState(30);
    const [maxTrackDuration, setMaxTrackDuration] = useState(300);
    const [gridVariantsItems, setGridVariantsItems] = useState(gridVariants);
    const [gridVariant, setGridVariant] = useState(3);
    const previousGridVariant = usePrevious(gridVariant);
    const [micOptions, setMicOptions] = useState([]);
    const [isModalMicVisible, setIsModalMicVisible] = useState(false);

    const gridOptions = gridVariantsItems.filter((item) => item.id === gridVariant)[0];

    const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);

    const [projectId, setProjectId] = useState(null);
    const [projectDate, setProjectDate] = useState(null);
    const [projectName, setProjectName] = useState(null);
    const [projectText, setProjectText] = useState('');
    const [projectTrackId, setProjectTrackId] = useState(null);

    const [isLoadProcessModalVisible, setIsLoadProcessModalVisible] = useState(false);

    const [isMainFocused, setIsMainFocused, isMainFocusedRef] = useState(true);

    // UI
    const [mixerIsActive, setMixerIsActive] = useState(false);
    const [mockAudioData, setMockAudioData, mockAudioDataRef] = useState(mockAudioItems);
    const [isProcessModalVisible, setIsProcessModalVisible] = useState(false)
    const [isUploadModalVisible, setIsUploadModalVisible] = useState(false)
    const [currentAudioTrack, setCurrentAudioTrack, currentAudioTrackRef] = useState(null);
    const [currentAudioFragment, setCurrentAudioFragment, currentAudioFragmentRef] = useState(null);

    // Recording
    const [isRecordingPanelActive, setIsRecordingPanelActive] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [currentRecordingData, setCurrentRecordingData] = useState(null);
    const [recordingTime, setRecordingTime] = useState(0);

    const _recordingTimer = useRef(null);

    // Editing
    const [activeCursor, setActiveCursor] = useState('default');
    const [copyingFragment, setCopyingFragment] = useState(null);
    const [copyingFragmentTrack, setCopyingFragmentTrack] = useState(null);

    // Playing
    const [isPlaying, setIsPlaying] = useState(false);
    const [playingPosition, setPlayingPosition, playingPositionRef] = useState(0);
    const [isPreparingToPlay, setIsPreparingToPlay] = useState(false);
    const [isNeedToProcess, setIsNeedToProcess] = useState(false);
    const [playAfterProcessing, setPlayAfterProcessing] = useState(false);

    // Metronome
    const [isMetronomeEnabled, setIsMetronomeEnabled] = useState(false);
    const [metronomeVolume, setMetronomeVolume] = useState(1);
    const [startMetronomeClick, stopMetronomeClick] = useMetronome();

    // Refs
    const mediaRecorderRef = useRef(null);
    const audioStreamRef = useRef(null);

    // Playing refs
    /*const [playTrack1, {pause: pauseTrack1, setPosition: setTrack1Position, currentTime: timeTrack1, duration: durationTrack1, schedulePlay: schedulePlayTrack1}] = useSoundAdvancedNew(mockAudioData[0].audio, mockAudioData[0].mute, mockAudioData[0].volume, mockAudioData[0].balance, playingPosition);
    const [playTrack2, {pause: pauseTrack2, setPosition: setTrack2Position, currentTime: timeTrack2, duration: durationTrack2, schedulePlay: schedulePlayTrack2}] = useSoundAdvancedNew(mockAudioData[1].audio, mockAudioData[1].mute, mockAudioData[1].volume, mockAudioData[1].balance, playingPosition);
    const [playTrack3, {pause: pauseTrack3, setPosition: setTrack3Position, currentTime: timeTrack3, duration: durationTrack3, schedulePlay: schedulePlayTrack3}] = useSoundAdvancedNew(mockAudioData[2].audio, mockAudioData[2].mute, mockAudioData[2].volume, mockAudioData[2].balance, playingPosition);
    const [playTrack4, {pause: pauseTrack4, setPosition: setTrack4Position, currentTime: timeTrack4, duration: durationTrack4, schedulePlay: schedulePlayTrack4}] = useSoundAdvancedNew(mockAudioData[3].audio, mockAudioData[3].mute, mockAudioData[3].volume, mockAudioData[3].balance, playingPosition);
    const [playTrack5, {pause: pauseTrack5, setPosition: setTrack5Position, currentTime: timeTrack5, duration: durationTrack5, schedulePlay: schedulePlayTrack5}] = useSoundAdvancedNew(mockAudioData[4].audio, mockAudioData[4].mute, mockAudioData[4].volume, mockAudioData[4].balance, playingPosition);
    const [playTrack6, {pause: pauseTrack6, setPosition: setTrack6Position, currentTime: timeTrack6, duration: durationTrack6, schedulePlay: schedulePlayTrack6}] = useSoundAdvancedNew(mockAudioData[5].audio, mockAudioData[5].mute, mockAudioData[5].volume, mockAudioData[5].balance, playingPosition);
    const [playTrack7, {pause: pauseTrack7, setPosition: setTrack7Position, currentTime: timeTrack7, duration: durationTrack7, schedulePlay: schedulePlayTrack7}] = useSoundAdvancedNew(mockAudioData[6].audio, mockAudioData[6].mute, mockAudioData[6].volume, mockAudioData[6].balance, playingPosition);
    const [playTrack8, {pause: pauseTrack8, setPosition: setTrack8Position, currentTime: timeTrack8, duration: durationTrack8, schedulePlay: schedulePlayTrack8}] = useSoundAdvancedNew(mockAudioData[7].audio, mockAudioData[7].mute, mockAudioData[7].volume, mockAudioData[7].balance, playingPosition);
*/

    const [initPlayer, play, pause, setPosition, currentTime, duration, changeTrackVolume, changeTrackBalance, changeTrackMute] = useAudioPlayer();

    // History
    const [history, currentHistoryIndex, addToHistory, undo, redo, clearHistory] = useTrackHistory(JSON.parse(JSON.stringify(mockAudioData)))

    const addToHistoryHandler = () => {
        const item = JSON.parse(JSON.stringify(mockAudioDataRef.current));
        addToHistory(item);
    }

    const undoHandler = () => {
        if (!isPlaying) {
            if (currentHistoryIndex > 0) {
                if (history[currentHistoryIndex - 1] === undefined) {
                    setMockAudioData(mockAudioItems);
                } else {
                    const item = JSON.parse(JSON.stringify(history[currentHistoryIndex - 1]));
                    setMockAudioData(item);
                }

                undo();
            }
        }
    }

    const redoHandler = () => {
        if (!isPlaying) {
            if (currentHistoryIndex < history.length - 1) {
                const item = JSON.parse(JSON.stringify(history[currentHistoryIndex + 1]));
                setMockAudioData(item);
                redo();
            }
        }
    }

    const closeLoginModal = () => {
        setIsLoginModalVisible(false);
    }

    const clearTimeline = () => {
        setMockAudioData(mockAudioItems);
        clearHistory();
    }

    // Queue

    //const [queue, setQueue] = useState([]);
    const [queue, setQueue] = useState([]);
    //const queueRef = useRef(queue);

    const [queueInProgress, setQueueInProgress] = useState(false);

    const addCommandToQueue = (command, params, onSuccess, onFail, inBackground = false) => {

        /*setQueue([...queue, {
            command: command,
            params: params,
            inBackground: inBackground,
            onSuccess: onSuccess,
            onFail: onFail
        }]);*/

        setQueue(prevState => {
            return [...prevState, {
                command: command,
                params: params,
                inBackground: inBackground,
                onSuccess: onSuccess,
                onFail: onFail
            }];
        });
    }

    const processQueue = () => {
        if (queue.length > 0 && queueInProgress === false) {
            setQueueInProgress(true);

            const command = queue[0];

            const commandFunc = command.command;

            // if key prop exists, update it
            if (command.params.key) {
                command.params.key = mockAudioData[0].key;
            }

            commandFunc(command.params, function (response) {
                command.onSuccess(response);
                setQueueInProgress(false);
                setQueue(prevState => [...prevState].slice(1));

            }, function (error) {
                command.onFail(error);
                setQueueInProgress(false);
                setQueue(prevState => [...prevState].slice(1));
            });
        }
    }

    useEffect(() => {
        //console.error('QUEUE: ', queue)

        if (queue.map((item) => !item.inBackground).includes(true)) {
            setIsProcessModalVisible(true);
        } else {
            if (playAfterProcessing) {
                playAllTracks(true);
            } else {
                setIsProcessModalVisible(false);
            }
        }

        processQueue();
    }, [queue, queueInProgress]);

    const initAudioPlayer = () => {
        let playerAudioData = [];

        mockAudioDataRef.current.map((item) => {
            if (item.audio) {
                playerAudioData.push({
                    id: item.id,
                    audio: item.audio,
                    mute: item.mute,
                    volume: item.volume,
                    balance: parseFloat(item.balance)
                });
            }
        });

        initPlayer(playerAudioData, playingPositionRef.current);
    }

    /**
     * Play all tracks
     */
    const playAllTracks = async (withDelay = false) => {

        initAudioPlayer();

        // Delay to process audio before playing
        if (withDelay) {
            await new Promise(resolve => setTimeout(resolve, 1500));
            setIsProcessModalVisible(false);
            setPlayAfterProcessing(false);
        }

        play();
        setIsPlaying(true);
    }


    /**
     * Pause all tracks
     */
    const pauseAllTracks = () => {
        setIsPlaying(false);
        pause();
    }

    const setPlayingPositionHandler = (position) => {

        if (position < duration || duration === 0) {
            setPlayingPosition(position);
        } else {
            setPlayingPosition(0);
        }
    }

    const changeGridPlus = () => {
        if (gridVariant < gridVariantsItems.length) {
            setGridVariant(gridVariant + 1);
        }
    }

    const changeGridMinus = () => {
        if (gridVariant > 1) {
            setGridVariant(gridVariant - 1);
        }
    }

    const changeGridCallback = () => {
        const prevGridOptions = gridVariantsItems.filter((item) => item.id === previousGridVariant)[0];

        mockAudioData.map((item, index) => {
            if (item.samples.length) {
                item.samples.map((sample, index) => {
                    if (sample.start > 0)
                    {
                        const prevLeft = (sample.start - 1) / prevGridOptions.secondsInPixels;
                        let left = prevLeft * gridOptions.secondsInPixels + 1;

                        if (index > 0) {
                            const prev = item.samples[index - 1];
                            if (left < prev.start + prev.duration * gridOptions.secondsInPixels + 1) {
                                left = prev.start + prev.duration * gridOptions.secondsInPixels + 1;

                                item.samples[index].start = left;
                            }
                        }

                        item.samples[index].start = left;
                        updateAudioById(item.id, item);
                    }
                });
            }
        });
    }

    useEffect(() => {
        changeGridCallback();
    }, [gridVariant]);

    const changeActiveCursor = (cursor) => {
        if (!isPlaying) {
            setActiveCursor(cursor);
        }
    }

    useEffect(() => {

        if (isPlaying && !isRecording) {
            setPlayingPosition(currentTime);

            //console.log('playingPosition - ', currentTime)
            //console.log('duration - ', duration);

            if (duration > 0 && (currentTime >= duration || currentTime === 0)) {
                setIsPlaying(false);
                setPlayingPosition(0);
            }
        }

    }, [currentTime]);

    /**
     * Start recording
     *
     * @param id
     * @returns {Promise<void>}
     */
    const startRecordingHandler = async (id) => {
        try {
            const constraints = {
                audio: {
                    echoCancellation: false,
                    autoGainControl: true,
                    noiseSuppression: true,
                    latency: 0.003,
                    deviceId: micOptions.deviceId
                }, video: false
            };

            const newStream = await navigator.mediaDevices.getUserMedia(constraints);
            audioStreamRef.current = newStream;

            const options = {
                mimeType: "audio/webm;codecs=opus",
                audioBitsPerSecond: 256000,
                sampleRate: 96000,
                numberOfAudioChannels: 2
            }

            const newMediaRecorder = new MediaRecorder(audioStreamRef.current, options);
            mediaRecorderRef.current = newMediaRecorder;
            newMediaRecorder.onstop = () => {
                requestIdleCallback(() => {
                    //...
                })
            }
            mediaRecorderRef.current.ondataavailable = (event) => {
                if (event.data && event.data.size > 0) {
                    setCurrentRecordingData(event.data);
                }
            };

            mediaRecorderRef.current.start();
            setIsRecording(true);

            _recordingTimer.current = setInterval(() => {
                setRecordingTime(prevCount => prevCount + 0.05);
                setPlayingPosition(prevCount => prevCount + 0.05);
            }, 50);

            if (micOptions.playback) {
                const context = new (window.AudioContext || window.webkitAudioContext)();
                if (context.state === 'suspended') {
                    await context.resume();
                }

                const source = context.createMediaStreamSource(audioStreamRef.current);

                // If effect enabled in settings
                if (micOptions.effect && micOptions.impulse) {
                    const tuna = new Tuna(context);
                    const effect = new tuna.Convolver({
                        highCut: 22050,                         //20 to 22050
                        lowCut: 20,                             //20 to 22050
                        dryLevel: 1,                            //0 to 1+
                        wetLevel: 1,                            //0 to 1+
                        level: 1,
                        impulse: "../assets/impulses/" + micOptions.impulse,
                        bypass: 0
                    });

                    source.connect(effect);
                    effect.connect(context.destination);
                } else {
                    source.connect(context.destination);
                }

                let speaker = new Audio();
                speaker.srcObject = source.mediaStream;
                await speaker.play(); //play audio through headphones/speakers
            }

            if (!isPlaying) {
                playAllTracks();
            }

            if (isMetronomeEnabled) {

                if (currentTime > 0) {
                    startMetronomeClick(minusTempo, metronomeVolume, playingPositionRef.current);
                } else {
                    startMetronomeClick(minusTempo, metronomeVolume);
                }
            }

        } catch (error) {
            console.error("Error accessing microphone:", error);
        }
    };

    const stopRecordingHandler = () => {
        pauseAllTracks();

        if (isMetronomeEnabled) {
            stopMetronomeClick();
        }

        mediaRecorderRef.current.stop();
        audioStreamRef.current.getTracks().forEach((track) => track.stop());
        setIsRecording(false);

        clearInterval(_recordingTimer.current);
        setRecordingTime(0);

        addToHistoryHandler();
    };

    const prepareToPlay = () =>
    {
        mockAudioDataRef.current.map((item) => {
            if (item.samples.length > 0 && !item.audio) {
                let endPreviousFragment = 0;
                let apiParams = [];

                // Combine fragments into one audio and add FX
                item.samples.map((sample, index) => {
                    let startInSeconds = sample.start === 0 ? 0 : (sample.start - 1) / gridOptions.secondsInPixels;
                    if (endPreviousFragment < startInSeconds) {
                        apiParams.push({
                            type: 'silence',
                            duration: startInSeconds - endPreviousFragment,
                        });
                    }

                    apiParams.push({
                        type: 'fragment',
                        fileName: sample.mp3,
                        fadeIn: sample.fadeIn,
                        fadeOut: sample.fadeOut,
                        duration: sample.duration,
                    });

                    endPreviousFragment = startInSeconds + sample.duration;
                });

                if (apiParams.length > 0) {
                    addCommandToQueue(concat, {
                        FX: item.FX,
                        tracks: apiParams,
                        tempo: item.bpm,
                        preset: item.preset,
                        key: mockAudioData[0].key
                    }, function (response) {
                        if (response.data.success) {
                            const newItem = getAudioById(item.id);
                            newItem.audio = "/" + response.data.file;
                            updateAudioById(newItem.id, newItem);
                        } else {
                            alert('Error while processing audio: ' + response.data.message);
                        }
                    }, function (error) {
                        alert(error);
                    });
                }
            }
        });
    }

    const playPauseRecordingHandler = () => {
        if (!isPlaying) {
            let needToPrepare = false;

            mockAudioDataRef.current.map((item) => {
                if (item.samples.length > 0 && !item.audio) {
                    needToPrepare = true;
                }
            });

            if (needToPrepare) {
                setPlayAfterProcessing(true);
                prepareToPlay();
            } else {
                playAllTracks();

            }
        } else {
            pauseAllTracks();
        }
    };

    useEffect(() => {
        let needToPrepare = false;

        mockAudioData.map((item) => {
            if (item.samples.length > 0 && !item.audio) {
                needToPrepare = true;
            }

            if (isPlaying) {
                if (item.audio) {
                    changeTrackVolume(item.id, item.volume);
                    changeTrackMute(item.id, item.mute);
                    changeTrackBalance(item.id, item.balance);
                }
            }
        });

        setIsNeedToProcess(needToPrepare);

        if (isPreparingToPlay && !needToPrepare) {
            setIsPreparingToPlay(false);
            playPauseRecordingHandler();
        }

    }, [mockAudioData]);

    /*const startStopMetronomeHandler = () => {
        if (isMetronomePlaying) {
            setIsMetronomePlaying(false);
        } else {
            setIsMetronomePlaying(true);

            setTimeout(() => {
                click1Ref.current.play();
            }, 0);
        }
    };*/

    const showMicrophoneModal = () => {
        setIsModalMicVisible(true);
    }

    const saveMicOptions = (deviceId, playback, effect, impulse) => {
        let options = {
            deviceId: deviceId,
            playback: playback,
            effect: effect,
            impulse: impulse
        };

        localStorage.setItem('mic-options', JSON.stringify(options));

        setIsModalMicVisible(false);
        setMicOptions(options);
    }

    const getAudioById = (id) => {
        return mockAudioDataRef.current.filter((item) => item.id === id)[0];
    }

    /**
     * Update track by ID
     *
     * @param id ID of the track
     * @param newState New track data
     */
    const updateAudioById = (id, newState) => setMockAudioData(prevState => [...prevState].map((item) => {
        if (item.id === id) return {...item, ...newState};
        return item;
    }));

    const toggleAudioMute = (id) => {
        let item = getAudioById(id);
        item.mute = !item.mute;

        updateAudioById(id, item);
    }

    const moveAudioFragment = (fragmentId, direction, value) => {
        if (!currentAudioTrackRef.current) {
            return;
        }

        let item = getAudioById(currentAudioTrackRef.current);
        let fragment = item.samples.filter((item) => item.id === fragmentId)[0];

        if (direction === 'left' && fragment.start - value >= 0) {
            fragment.start = fragment.start - value;
        } else if(direction === 'left' && fragment.start - value < 0) {
            return false;
        }

        if (direction === 'right') {
            fragment.start = fragment.start + value;
        }

        // Sort samples
        item.samples.sort((a, b) => {
            return a.start - b.start;
        });

        // Fix potential overlapping
        item.samples.map((sample, index) => {
            if (index > 0) {
                let left = sample.start;
                const prev = item.samples[index - 1];
                if (left < prev.start + prev.duration * gridOptions.secondsInPixels + 1) {
                    left = prev.start + prev.duration * gridOptions.secondsInPixels + 1;

                    item.samples[index].start = left;
                }
            }
        })

        // Save changes
        item.audio = '';
        updateAudioById(currentAudioTrackRef.current, item);

        // add to history
        addToHistoryHandler();

        return true;
    }

    const handleKeyDown = (e) => {
        if (e.code === 'Space' && isMainFocusedRef.current) {
            e.preventDefault();

            playPauseRecordingHandler();
        }

        // handle left and right arrows
        if (currentAudioFragmentRef.current && (e.code === 'ArrowLeft' || e.code === 'ArrowRight')) {
            e.preventDefault();

            if (e.code === 'ArrowLeft') {
                // move fragment left
                moveAudioFragment(currentAudioFragmentRef.current, 'left', 1);
            }

            if (e.code === 'ArrowRight') {
                // move fragment right
                moveAudioFragment(currentAudioFragmentRef.current, 'right', 1);
            }
        }
    }

    // Key press listener
    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);

        return () => window.removeEventListener("keydown", handleKeyDown);
    }, [isPlaying]);

    // upload data to server and update track
    useEffect(() => {

        if (currentRecordingData) {
            setIsProcessModalVisible(true);

            const baseUrl = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:5001';

            normalize(currentRecordingData, async function (response) {
                let item = getAudioById(currentAudioTrack)

                item.FX.normalize = {
                    active: true,
                    params: {
                        gain: 7,
                        ceiling: -5.5
                    }
                }

                const recordingDuration = await getBlobDuration(currentRecordingData);
                const start = (playingPosition - recordingDuration) * gridOptions.secondsInPixels - 1

                //item.samples = [];
                item.samples.push({
                    id: Math.floor(Date.now() / 1000) + '-' + Math.floor(Math.random() * 50000),
                    audio: baseUrl + "/" + response.data.fileOrigin,
                    mp3: baseUrl + "/" + response.data.fileProcessed,
                    start: start,
                    duration: response.data.duration.seconds,
                    position: 0,
                    bpm: 0,
                    fadeIn: 0,
                    fadeOut: 0,
                    minus: false
                });

                item.samples.sort((a, b) => {
                    return a.start - b.start;
                });
                item.audio = '';

                item.samples.forEach((e, index) => {
                    if (e.start > 1) {
                        const prev = item.samples[index - 1];

                        if (prev === undefined) {
                            return;
                        }

                        if (e.start < prev.start + prev.duration * gridOptions.secondsInPixels + 1) {
                            e.start = prev.start + prev.duration * gridOptions.secondsInPixels + 1;
                        }
                    }
                })

                updateAudioById(currentAudioTrack, item);

                //setPlayingPositionHandler(0);

                // Detect BPM
                beatDetect.getBeatInfo({
                    url: baseUrl + "/" + response.data.fileOrigin
                }).then(info => {
                    item.bpm = info.bpm;
                    updateAudioById(currentAudioTrack, item);
                }).catch(error => {
                    // The error string
                });

                setIsProcessModalVisible(false);

            }, function (error) {});
        }

    }, [currentRecordingData]);

    // Load mic options from local storage and presets from server
    useEffect(() => {
        if (typeof window !== 'undefined') {
            let options = localStorage.getItem('mic-options');
            if (!options) {
                // show modal without close button
                setIsModalMicVisible(true);
            } else {
                setMicOptions(JSON.parse(options));
            }
        }

        if (presets.length <= 0) {
            getPresets(function (response) {
                setPresets(response.data);
            }, function (error) {});
        }

    }, []);

    // Hide login modal after login
    useEffect(() => {
        if (loggedIn) {
            setIsLoginModalVisible(false)
        }

    }, [loggedIn]);

    // Close window confirmation
    useEffect(() => {
        window.onbeforeunload = function() {
            return 'Вы уверены, что хотите закрыть страницу?';
        };

        return () => {
            window.onbeforeunload = null;
        }
    }, []);

    // Auth user by token in URL
    useEffect(() => {
        if (token !== null && !loggedIn && !checkingStatus) {
            dispatch(login({
                token: token,
            }))
        }

    }, [loggedIn, checkingStatus, token]);

    // Load track data from server
    useEffect(() => {
        if (trackId !== null && loggedIn) {
            setProjectTrackId(trackId)
            setIsLoadProcessModalVisible(true)

            // load track data and add to mockAudioData
            loadOrCreateProjectForTrack(trackId, user.token, function (response) {
                if (response.data.success == true && response.data.projectId == undefined) {

                    setProjectText(response.data.text);

                    const id = 1; //response.data.id;

                    let item = getAudioById(id)
                    item.audio = "/" + response.data.audio;
                    item.samples = [];
                    item.samples.push({
                        id: Math.floor(Date.now() / 1000) + '-' + Math.floor(Math.random() * 50000),
                        audio: "/" + response.data.audio,
                        mp3: "/" + response.data.audio,
                        start: 0,
                        duration: response.data.duration.seconds,
                        position: 0,
                        volume_up_duration: 0,
                        volume_down_duration: 0,
                        bpm: 0,
                        fadeIn: 0,
                        fadeOut: 0,
                        minus: true
                    });

                    updateAudioById(id, item);


                    // Detect BPM
                    beatDetect.getBeatInfo({
                        url: "/" + response.data.audio
                    }).then(info => {
                        item.bpm = info.bpm;
                        updateAudioById(id, item);
                        setMinusTempo(Math.round(info.bpm));

                    }).catch(error => {
                        // The error string
                    });

                    // Find key
                    addCommandToQueue(findKey, item.samples[0].audio, function (response) {
                        let item = getAudioById(id)
                        item.key = response.data.key;
                        updateAudioById(id, item);

                        addToHistoryHandler();

                        initAudioPlayer();

                        setTimeout(() => {
                            setIsLoadProcessModalVisible(false);
                        }, 3000);

                    }, function (error) {
                        console.error(error);
                    }, false);
                } else if (response.data.success == true) {

                    getProjectData(user.token, response.data.projectId, function (response) {

                        const project = response.data;

                        // Set project meta-data
                        setProjectId(project._id);
                        setProjectName(project.title);
                        setProjectText(project.text);
                        setProjectDate(project.updatedAt);

                        // Clear current tracks
                        clearTimeline();

                        // Add data from file
                        if (project.tracks && project.tracks.length) {
                            project.tracks.forEach((track) => {
                                let localTrackData = getAudioById(track.position);
                                localTrackData.audio = track.audio;
                                localTrackData.bpm = track.bpm;
                                localTrackData.key = track.key;
                                localTrackData.volume = track.volume;
                                localTrackData.balance = track.balance;
                                localTrackData.style = track.color;
                                localTrackData.minus = track.type === 'minus';
                                localTrackData.mute = track.muted;
                                localTrackData.FX = track.fx ? track.fx : {};
                                localTrackData.preset = track.preset;

                                let samples = [];

                                if (track.samples && track.samples.length) {

                                    track.samples.forEach((sample) => {
                                        samples.push({
                                            id: sample._id,
                                            title: sample.title,
                                            audio: sample.audio,
                                            mp3: sample.audio,
                                            start: sample.start,
                                            duration: sample.duration,
                                            fadeIn: sample.fadeIn ? sample.fadeIn : 0,
                                            fadeOut: sample.fadeOut ? sample.fadeOut : 0,
                                            minus: track.position != '1' ? false : true
                                        });
                                    });
                                }

                                localTrackData.samples = samples;
                                updateAudioById(track.position, localTrackData);
                            });

                            setMinusTempo(project.tracks[0].bpm);
                        }

                        initAudioPlayer();

                        setTimeout(() => {
                            setIsLoadProcessModalVisible(false);
                        }, 3000);

                    }, function (error) {
                        console.error(error);

                        setIsLoadProcessModalVisible(false);
                    });
                }


            }, function (error) {});
        }

    }, [trackId, loggedIn]);

    const globalState = {

        // Config
        secondsInPixels,
        micOptions,
        saveMicOptions,
        maxTrackDuration,

        // Grid
        gridVariantsItems,
        gridVariant,
        changeGridMinus,
        changeGridPlus,

        // Selected audio track
        currentAudioTrack,
        setCurrentAudioTrack,

        // Selected audio fragment
        currentAudioFragment,
        setCurrentAudioFragment,

        // Recording panel
        isRecordingPanelActive,
        setIsRecordingPanelActive,

        // Recording handlers and values
        startRecordingHandler,
        stopRecordingHandler,
        isRecording,
        setIsRecording,

        // Metronome
        metronomeVolume,
        setMetronomeVolume,
        isMetronomeEnabled,
        setIsMetronomeEnabled,

        // Editing
        activeCursor,
        changeActiveCursor,

        // Get and Update track data by ID
        getAudioById,
        updateAudioById,
        toggleAudioMute,

        // Refs
        mediaRecorderRef,
        audioStreamRef,

        // UI,
        setIsProcessModalVisible,
        isProcessModalVisible,

        isPlaying,
        playingPosition,
        setPlayingPosition,

        minusTempo,
        setMinusTempo,

        isNeedToProcess,
        setIsNeedToProcess,
        prepareToPlay,

        /*setTrack1Position,
        setTrack2Position,
        setTrack3Position,
        setTrack4Position,
        setTrack5Position,
        setTrack6Position,
        setTrack7Position,
        setTrack8Position,*/

        // Presets and Effects
        presets,
        currentPresetId,
        setCurrentPresetId,
        currentEffectId,
        setCurrentEffectId,

        mockAudioData,
        recordingTime,
        copyingFragment,
        setCopyingFragment,
        copyingFragmentTrack,
        setCopyingFragmentTrack,

        addCommandToQueue,
        setPlayingPositionHandler,

        setMockAudioData,
        addToHistoryHandler,
        undoHandler,
        redoHandler,
        history,
        currentHistoryIndex,
        projectId,
        setProjectId,
        projectName,
        setProjectName,
        projectText,
        setProjectText,
        projectDate,
        setProjectDate,

        isMainFocused,
        setIsMainFocused,

        clearHistory,
        clearTimeline,

        setIsLoginModalVisible,
        projectTrackId,
        setProjectTrackId,

        isUploadModalVisible,
        setIsUploadModalVisible,

        initAudioPlayer,

        mixerIsActive,
        setMixerIsActive,
        helpIsRead,

        moveAudioFragment
    }

    return (
        <>
            <Context.Provider value={globalState}>
                <div className="main__container">

                    {/* Page header */}
                    <Header
                        mixerIsActive={mixerIsActive}
                        setMixerIsActive={setMixerIsActive}
                        currentPresetId={currentPresetId}
                        setCurrentPresetId={setCurrentPresetId}
                    />

                    {/* Mixers modal */}
                    <Mixers
                        mixerIsActive={mixerIsActive}
                        setMixerIsActive={setMixerIsActive}
                    />

                    {/* Audio tracks pane */}
                    <AudioTracks
                        items={mockAudioData}
                        setActive={setIsRecordingPanelActive}
                    />

                    {/* Control panel visible always instead of recording in the process */}
                    <ControlPanel
                        hide={isRecordingPanelActive}
                        playRecording={playPauseRecordingHandler}
                        showMicrophoneModal={showMicrophoneModal}
                    />

                    {/* Recording panel is visible only when user start recording */}
                    <RecordingPanel
                        hide={!isRecordingPanelActive}
                        setActive={setIsRecordingPanelActive}
                        active={isRecordingPanelActive}
                    />

                    <ModalMicOptions visible={isModalMicVisible} onClose={() => setIsModalMicVisible(false)} />

                    <ModalProcessing isVisible={isProcessModalVisible} />
                    <ModalProcessing type="upload" isVisible={isUploadModalVisible} />
                    <ModalLoad isVisible={isLoadProcessModalVisible} />

                    <ModalLogin visible={isLoginModalVisible} onClose={closeLoginModal} />

                    {/* Footer visible only on desktop */}
                    {/*!mob915 && (<Footer/>)*/}

                    <CookiesConsent />
                </div>

                    <div className="main__container__small_screen">
                        <div>
                            Переверните телефон в горизонтальное положение
                            <small>Или измените масштаб</small>
                        </div>
                    </div>
                <ToastContainer />
                <Metrika />
            </Context.Provider>
        </>
    );

    // mobile 915
}

export default App;
