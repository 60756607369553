import React from "react";
import Modal from "../Modal";
import { ReactComponent as Error } from "../../../assets/icons/cancel.svg";

export default function ModalError({ title, subtitle, message, visible, onClose }) {
    return (
        <>
            <Modal
                type={title}
                title={subtitle}
                sub={message}
                gray={"закрыть"}
                svg={<Error />}
                visible={visible}
                onClose={onClose}
            />
        </>
    );
}
