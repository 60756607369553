import React, {useState} from "react";
import useMediaQuery from "../../../utils/hooks/common/useMediaQuery";
import EffectsBody from "../../EffectsBody/EffectsBody";
import styles from "./fxComp.module.scss";
import Slider from "../../Slider/Slider";

export default function FxComp({ active, setActive, softness, setSoftness, onSubmit }) {
    const mob915 = useMediaQuery(915);
    
    const softnessVariants = [
        { number: "100" },
        { number: "80" },
        { number: "60" },
        { number: "40" },
        { number: "20" },
        { number: "0" },
    ];

    const changeSoftness = (e) => {
        setSoftness(e.target.value);
    }

    return (
        <EffectsBody
            color={"primary"}
            title={"Compressor"}
            thumbColor={"primary"}
            width={mob915 !== true && "sm"}
            active={active}
            setActive={setActive}
            buttonsColor={"greenRevert"}
            onSubmit={onSubmit}
        >
            <div className={styles.slider}>
                <Slider number={softnessVariants} color={0} min={0} max={100} step={1} value={softness} setValue={changeSoftness} footer={"Softness"} colorInput={"primary"} />
            </div>
        </EffectsBody>
    );
}
