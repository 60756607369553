import React, { useEffect } from 'react'

const Metrika = () => {
    useEffect(() => {
        window.ym('48164585', 'hit')
    }, [])

    return null
}

export default Metrika