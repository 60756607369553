import React from 'react'
import cn from "classnames";
import styles from "./contextMenu.module.scss";

export default function ContextMenu({ items, left, top, right, bottom, visible, onClick, itemsActive, width = 'max-content' })
{
    const activeElements = itemsActive ? itemsActive : [];

    return (
        <div className={cn(
            styles.wrapper,
            !visible && styles.hidden,
            left && styles.left,
            top && styles.top,
            right && styles.right,
            bottom && styles.bottom)} style={{width: width}}>
            <ul>
                {items.map((item, index) => (
                    <li key={index} onClick={() => onClick(item.id)} className={cn(styles.item, activeElements.includes(item.id) && styles.active)}>
                        <a href="#">{item.title ? item.title : item.name}</a>
                    </li>
                ))}
            </ul>
        </div>
    )
}