import React, {useState} from "react";
import useMediaQuery from "../../../utils/hooks/common/useMediaQuery";
import EffectsBody from "../../EffectsBody/EffectsBody";
import Slider from "../../Slider/Slider";
import styles from "./fxDelay.module.scss";
import FxValueSwitcher from "../../UI/FxValueSwitcher/FxValueSwitcher";

export default function FxDelay({
                                    active, setActive, time, setTime, effect, setEffect, feedback, setFeedback, onSubmit
}) {
    const mob915 = useMediaQuery(915);

    const stepsVariants = [
        { number: "0" },
        { number: "-10" },
        { number: "-20" },
        { number: "-30" },
        { number: "-40" },
    ];

    const changeFeedback = (e) => {
        setFeedback(e.target.value);
    }

    const changeEffect = (e) => {
        setEffect(e.target.value);
    }

    return (
        <EffectsBody
            color={"violet"}
            title={"Delay"}
            thumbColor={"violet"}
            width={mob915 !== true && "sm"}
            active={active}
            setActive={setActive}
            buttonsColor={"greenRevert"}
            onSubmit={onSubmit}
        >
            <div className={styles.slider}>
                <Slider number={stepsVariants} color={1} min={-40} max={0} step={0.5} value={feedback} setValue={changeFeedback} footer={"Feedback"} colorInput={"violet"} />
                <Slider number={stepsVariants} color={1} min={-40} max={0} step={0.5} value={effect} setValue={changeEffect} footer={"Effect"} colorInput={"violet"} />
                <FxValueSwitcher active={time} setActive={setTime} />
            </div>
        </EffectsBody>
    );
}
