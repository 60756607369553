import axios from 'axios';

const baseUrl = "";

export async function upload(file, callbackSuccess, callbackError) {
    const formData = new FormData();
    formData.append("audio", file);

    axios
        .post(baseUrl + "/api/tracks/upload", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then((response) => {
            // handle the response
            callbackSuccess(response);
        })
        .catch((error) => {
            callbackError(error)
        });
}

export async function normalize(file, callbackSuccess, callbackError) {
    const formData = new FormData();
    formData.append("audio", file);

    axios
        .post(baseUrl + "/api/tracks/vocal", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then((response) => {
            // handle the response
            callbackSuccess(response);
        })
        .catch((error) => {
            callbackError(error)
        });
}

export async function cut(url, start, duration, callbackSuccess, callbackError) {
    const formData = new FormData();
    formData.append("url", url);
    formData.append('start', start);
    formData.append('duration', duration);

    axios
        .post(baseUrl + "/api/tracks/cut", formData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then((response) => {
            // handle the response
            callbackSuccess(response);
        })
        .catch((error) => {
            callbackError(error)
        });
}

export async function concat(params, callbackSuccess, callbackError) {

    axios
        .post(baseUrl + "/api/tracks/concat", params, {
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then((response) => {
            // handle the response
            callbackSuccess(response);
        })
        .catch((error) => {
            callbackError(error)
        });
}

export async function getPresets(callbackSuccess, callbackError) {
    axios
        .get(baseUrl + "/api/tracks/presets", {
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then((response) => {
            // handle the response
            callbackSuccess(response);
        })
        .catch((error) => {
            callbackError(error)
        });
}

export async function findKey(url, callbackSuccess, callbackError) {
    const formData = new FormData();
    formData.append("url", url);

    axios
        .post(baseUrl + "/api/tracks/key", formData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then((response) => {
            // handle the response
            callbackSuccess(response);
        })
        .catch((error) => {
            callbackError(error)
        });
}

export async function mix(tracks, token, callbackSuccess, callbackError) {

    axios
        .post(baseUrl + "/api/tracks/mix", tracks, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        })
        .then((response) => {
            // handle the response
            callbackSuccess(response);
        })
        .catch((error) => {
            callbackError(error)
        });
}

export async function changeTempo(params, callbackSuccess, callbackError) {
    const {url, tempo, newTempo} = params;

    const formData = new FormData();
    formData.append("url", url);
    formData.append('tempo', tempo);
    formData.append('newTempo', newTempo);

    axios
        .post(baseUrl + "/api/tracks/tempo", formData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then((response) => {
            // handle the response
            callbackSuccess(response);
        })
        .catch((error) => {
            callbackError(error)
        });
}

export async function createProject(token, params, callbackSuccess, callbackError) {
    axios
        .post(baseUrl + "/api/projects", params, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        })
        .then((response) => {
            // handle the response
            callbackSuccess(response);
        })
        .catch((error) => {
            callbackError(error)
        });
}

export async function updateProject(token, projectId, params, callbackSuccess, callbackError) {
    axios
        .put(baseUrl + "/api/projects/" + projectId, params, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        })
        .then((response) => {
            // handle the response
            callbackSuccess(response);
        })
        .catch((error) => {
            callbackError(error)
        });
}

export async function deleteProject(token, projectId, callbackSuccess, callbackError) {
    axios
        .delete(baseUrl + "/api/projects/" + projectId, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        })
        .then((response) => {
            // handle the response
            callbackSuccess(response);
        })
        .catch((error) => {
            callbackError(error)
        });
}

export async function getProjects(token, callbackSuccess, callbackError) {
    axios
        .get(baseUrl + "/api/projects", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        })
        .then((response) => {
            // handle the response
            callbackSuccess(response);
        })
        .catch((error) => {
            callbackError(error)
        });
}

export async function getProjectData(token, projectId, callbackSuccess, callbackError) {
    axios
        .get(baseUrl + "/api/projects/" + projectId, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        })
        .then((response) => {
            // handle the response
            callbackSuccess(response);
        })
        .catch((error) => {
            callbackError(error)
        });
}


export async function loadOrCreateProjectForTrack(trackId, token, callbackSuccess, callbackError) {
    axios
        .get(baseUrl + `/api/projects/externalTrack/${trackId}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        })
        .then((response) => {
            // handle the response
            callbackSuccess(response);
        })
        .catch((error) => {
            callbackError(error)
        });
}

export async function getTrackDetails(trackId, callbackSuccess, callbackError)
{
    axios
        .get(baseUrl + `/api/projects/externalTrack/${trackId}/detail`, {
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then((response) => {
            callbackSuccess(response);
        })
        .catch((error) => {
            callbackError(error)
        });
}