import React, {useState} from "react";
import { SwiperSlide } from "swiper/react";
import useMediaQuery from "../../../utils/hooks/common/useMediaQuery";
import EffectsBody from "../../EffectsBody/EffectsBody";
import Slider from "../../Slider/Slider";
import SliderCarousel from "../../SliderCarousel/SliderCarousel";
import GradientButton from "../../UI/GradientButton/GradientButton";
import styles from "./fxForm.module.scss";

export default function FxForm({
    active,
    setActive,
    pitch,
    setPitch,
    formant,
    setFormant,
    onSubmit
}) {
    const mob915 = useMediaQuery(915);

    const stepsVariants = [
        { number: "12" },
        { number: "8" },
        { number: "4" },
        { number: "0" },
        { number: "-4" },
        { number: "-8" },
        { number: "-12" },
    ];

    const changePitch = (e) => {
        setPitch(e.target.value);
    }

    const changeFormant = (e) => {
        setFormant(e.target.value);
    }

    return (
        <EffectsBody
            color={"orange"}
            title={"Formant"}
            thumbColor={"orange"}
            width={mob915 !== true && "sm"}
            active={active}
            setActive={setActive}
            buttonsColor={"orangeRevert"}
            onSubmit={onSubmit}
        >
            <div className={styles.slider}>
                <Slider number={stepsVariants} color={2} min={-12} max={12} step={0.1} value={pitch} setValue={changePitch} footer={"Pitch"} colorInput={"orange"} />
                <Slider number={stepsVariants} color={2} min={-12} max={12} step={0.1} value={formant} setValue={changeFormant} footer={"Formant"} colorInput={"orange"} />
            </div>
        </EffectsBody>
    );
}
