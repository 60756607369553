import cn from "classnames";
import React, { useState } from "react";
import GradientButton from "../UI/GradientButton/GradientButton";
import styles from "./modal.module.scss";

export default function Modal({ svg, type, title, sub, violet, gray, color, visible, onClose }) {
    return (
        <>
            <div
                className={styles.modal__background}
                style={
                    !visible ? { display: "none" } : { display: "flex" }
                }
            ></div>
            <div
                className={styles.wrapper}
                style={
                    !visible ? { display: "none" } : { display: "flex" }
                }
            >
                <div className={styles.container}>
                    <div className={cn(styles.title, styles[color])}>
                        <span className={styles.loader}></span>
                        <p>{type}</p>
                    </div>
                    <div className={styles.boody}>
                        <p>{title}</p>
                        <p>{sub}</p>
                    </div>
                    <div className={styles.footer}>
                        {violet !== undefined && (
                            <GradientButton size={"modal"} color={"violet"}>
                                {violet}
                            </GradientButton>
                        )}

                        {onClose && (
                            <GradientButton
                                onClick={() => onClose()}
                                size={"modal"}
                            >
                                {gray}
                            </GradientButton>
                        )}

                    </div>
                </div>
            </div>
        </>
    );
}
