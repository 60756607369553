export const gridVariants = [
    {
        id: 1,
        secondsInPixels: 5,
        step: 30,
        className: 'grid-5'
    },

    {
        id: 2,
        secondsInPixels: 15,
        step: 10,
        className: 'grid-15'
    },

    {
        id: 3,
        secondsInPixels: 30,
        step: 5,
        className: 'grid-30'
    },

    {
        id: 4,
        secondsInPixels: 50,
        step: 3,
        className: 'grid-50'
    },

    {
        id: 5,
        secondsInPixels: 80,
        step: 1,
        className: 'grid-80'
    }
];