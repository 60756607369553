import React, {useContext, useEffect, useRef, useState} from "react";
import { ReactComponent as Volume } from "../../assets/icons/up_volume.svg";
import { ReactComponent as OffVolume } from "../../assets/icons/off_volume.svg";
import { ReactComponent as UploadIcon } from "../../assets/icons/upload.svg";

import cn from "classnames";
import HorizontalRangeSlider from "../HorizontalRangeSlider/HorizontalRangeSlider";
import useMediaQuery from "../../utils/hooks/common/useMediaQuery";
import styles from "./trackleftside.module.scss";
import context from "../../context/Context";
import ModalProcessing from "../Modal/Modals/ModalProcessing";
import {concat, findKey, normalize, upload} from "../../api/api";

import useBeatDetect from "../../utils/hooks/project/useBeatDetect";
import ModalText from "../Modals/ModalText/modalText";
import {Tooltip} from "react-tooltip";

import load from "audio-loader"
import { analyze } from 'web-audio-beat-detector';



export default function TrackLeftSide({
    title,
    pan,
    muteColor,
    handleRecord,
    recButton,
    uploadButton,
    recActive,
    containerBg,
    id,
    flag,
    isMute,
    volume,
    balance,
    audio,
    samples
}) {

    const beatDetect = useBeatDetect();

    const mob = useMediaQuery(915);

    const uploadFileInputRef = useRef();
    const uploadFileInputRef2 = useRef();

    const {
        isRecording,
        updateAudioById,
        getAudioById,
        toggleAudioMute,
        currentAudioTrack,
        setCurrentAudioTrack,
        setCurrentAudioFragment,
        minusTempo,
        setMinusTempo,
        isPlaying,
        playingPosition,
        gridVariantsItems,
        gridVariant,
        addCommandToQueue,
        addToHistoryHandler,
        setIsMainFocused,
        projectText,
        setProjectText,

        isUploadModalVisible,
        setIsUploadModalVisible
    } = useContext(context)

    const gridOptions = gridVariantsItems.filter((item) => item.id === gridVariant)[0];

    const [isModalTextVisible, setIsModalTextVisible] = useState(false);

    const onModalTextShow = () => {
        setIsModalTextVisible(true);
        setIsMainFocused(false);
    }

    const onModalTextClose = () => {
        setIsModalTextVisible(false);
        setIsMainFocused(true);
    }


    const handleUpload = () => {
        uploadFileInputRef.current.click();
    }

    const handleUploadToServer = (e) => {

        if (e.target.files[0]) {
            setIsUploadModalVisible(true);

            //const baseUrl = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:5001';

            upload(e.target.files[0], function (response) {
                uploadFileInputRef.current.value = "";
                setIsUploadModalVisible(false);

                let item = getAudioById(id)
                item.samples = [];
                item.samples.push({
                    id: Math.floor(Date.now() / 1000) + '-' + Math.floor(Math.random() * 50000),
                    audio: "/" + response.data.fileOrigin,
                    mp3: "/" + response.data.fileProcessed,
                    start: 0,
                    duration: response.data.duration.seconds,
                    position: 0,
                    volume_up_duration: 0,
                    volume_down_duration: 0,
                    bpm: 0,
                    fadeIn: 0,
                    fadeOut: 0,
                    minus: true
                });

                updateAudioById(id, item);

                // Detect BPM
                beatDetect.getBeatInfo({
                    url: "/" + response.data.fileOrigin
                }).then(info => {
                    item.bpm = info.bpm;
                    updateAudioById(id, item);
                    setMinusTempo(Math.round(info.bpm));

                }).catch(error => {
                    // The error string
                });

                /*load("/" + response.data.fileOrigin).then(function (buffer) {
                    analyze(buffer)
                        .then((tempo) => {
                            item.bpm = tempo;
                            updateAudioById(id, item);
                            setMinusTempo(Math.round(tempo));
                        })
                        .catch((err) => {
                            // something went wrong
                        });
                })*/

                // Find key
                addCommandToQueue(findKey, item.samples[0].audio, function (response) {
                    let item = getAudioById(id)
                    item.key = response.data.key;
                    item.audio = item.samples[0].mp3;
                    updateAudioById(id, item);

                    // add to history
                    addToHistoryHandler();

                }, function (error) {
                    console.error(error);
                });

            }, function (error) {
                console.error(error);
                uploadFileInputRef.current.value = "";
            });
        }
    }

    const handleUploadNormalize = () => {
        uploadFileInputRef2.current.click();
    }

    const handleUploadNormalizeToServer = (e) => {

        if (e.target.files[0]) {
            setIsUploadModalVisible(true);

            normalize(e.target.files[0], function (response) {
                uploadFileInputRef2.current.value = "";
                setIsUploadModalVisible(false);

                let item = getAudioById(id)
                item.FX.normalize = {
                    active: true,
                    params: {
                        gain: 7,
                        ceiling: -5.5
                    }
                }

                const start = playingPosition * gridOptions.secondsInPixels + 1

                item.samples = [];
                item.samples.push({
                    id: Math.floor(Date.now() / 1000) + '-' + Math.floor(Math.random() * 50000),
                    audio: "/" + response.data.fileOrigin,
                    mp3: "/" + response.data.fileProcessed,
                    start: start,
                    duration: response.data.duration.seconds,
                    position: 0,
                    volume_up_duration: 0,
                    volume_down_duration: 0,
                    bpm: 0,
                    fadeIn: 0,
                    fadeOut: 0,
                    minus: false
                });
                item.audio = item.samples[0].mp3;

                // New BPM DETECT
                /*load("/" + response.data.fileOrigin).then(function (buffer) {
                    //console.log(buffer) // => <AudioBuffer>

                    analyze(buffer)
                        .then((tempo) => {
                            // the tempo could be analyzed
                            //console.log('TEMP: ', tempo)

                            item.bpm = tempo;
                            updateAudioById(id, item);

                            // add to history
                            addToHistoryHandler();
                        })
                        .catch((err) => {
                            // something went wrong
                        });
                })*/

                // Detect BPM
                beatDetect.getBeatInfo({
                    url: "/" + response.data.fileOrigin
                }).then(info => {
                    item.bpm = info.bpm;
                    updateAudioById(id, item);

                    // add to history
                    addToHistoryHandler();

                }).catch(error => {
                    // The error string
                });



            }, function (error) {
                console.error(error);

                setIsUploadModalVisible(false);

                uploadFileInputRef2.current.value = "";
            });
        }
    }

    const handleRecButton = () => {
        if (currentAudioTrack !== id) {
            setCurrentAudioTrack(id);
        }

        handleRecord();
    }

    const changeVolume = (id, volume) => {
        let item = getAudioById(id);
        item.volume = volume;

        updateAudioById(id, item);
    }

    const changeBalance = (id, balance) => {
        let item = getAudioById(id);
        item.balance = balance;

        updateAudioById(id, item);
    }

    const handleClick = (id) => {
        if (isRecording) {
            return;
        }

        if (currentAudioTrack !== id) {
            setCurrentAudioFragment(0);
        }

        setCurrentAudioTrack(id);
    }

    return (
        <>
            <div className={styles.track__container}>
                <div className={styles.progressbar}>
                    <div className={cn(styles.progressbar__line, isPlaying && !isMute && audio && styles.progressbar__line__animate)}></div>
                </div>
                <div
                    className={cn(styles.sound__button, currentAudioTrack === id && !isMute && styles[muteColor])}
                    style={{background: currentAudioTrack === id && !isMute ? containerBg : ""}}
                    onClick={() => toggleAudioMute(id)}
                >
                    {isMute === true ? <OffVolume /> : <Volume />}
                </div>
                <div
                    className={cn(
                        styles.track__header__wrapper,
                        isMute === true && styles.mutedBg
                    )}
                    style={{background: currentAudioTrack === id && containerBg}}
                    onClick={() => {
                        handleClick(id);
                    }}
                >
                    <div className={styles.track__header}>
                        <p className={styles.title}>{title}</p>

                        {recButton === true && (
                            <div style={
                                isMute === true
                                    ? { display: "flex", visibility: "hidden" }
                                    : { display: "flex", visibility: "visible" }
                            }>
                                {mob !== true && (
                                    <div className={styles.uploadButtonWrapper}>
                                        <div className={styles.uploadButton} data-tooltip-id="my-tooltip-upload-vocal" onClick={handleUploadNormalize}>
                                            <UploadIcon />
                                        </div>
                                        <input type="file" ref={uploadFileInputRef2} accept={`.mp3,.wav`} onChange={handleUploadNormalizeToServer} />
                                    </div>
                                )}

                                <div data-tooltip-id="my-tooltip-start-record"
                                    onClick={handleRecButton}
                                    className={cn(styles[recActive], styles.rec)}
                                >
                                    <p>rec</p>
                                </div>
                            </div>
                        )}

                        {uploadButton && (
                            <div className={styles.minusButtonsWrapper}>
                                <div className={styles.uploadButtonWrapper} style={
                                    isMute === true
                                        ? { visibility: "hidden" }
                                        : { visibility: "visible" }
                                }>
                                    <div className={styles.textButton} onClick={onModalTextShow}>
                                        <p>Текст</p>
                                    </div>
                                </div>

                                <div className={styles.uploadButtonWrapper} style={
                                    isMute === true
                                        ? { visibility: "hidden" }
                                        : { visibility: "visible" }
                                }>
                                    <div className={styles.uploadButton} data-tooltip-id="my-tooltip-upload-minus" onClick={handleUpload}>
                                        {mob ? (<UploadIcon />) : (<p>Загрузить</p>)}
                                    </div>
                                    <input type="file" ref={uploadFileInputRef} accept={`.mp3,.wav`} onChange={handleUploadToServer} />
                                </div>
                            </div>
                        )}
                    </div>
                    {mob !== true && (
                        <div className={styles.mixer}>
                            <HorizontalRangeSlider id={id} pan={pan} balance={balance} volume={volume} changeVolume={changeVolume} changeBalance={changeBalance} />
                        </div>
                    )}
                </div>
            </div>

            {uploadButton && (
                <ModalText visible={isModalTextVisible} text={projectText} setText={setProjectText} onClose={onModalTextClose} />
            )}

            <Tooltip
                id="my-tooltip-upload-minus"
                place="left"
                content="Загрузить минус"
            />

            <Tooltip
                id="my-tooltip-upload-vocal"
                place="left"
                content="Загрузить файл"
            />

            <Tooltip
                id="my-tooltip-start-record"
                place="left"
                content="Начать запись"
            />
        </>
    );
}
