import React, {useState} from "react";
import useMediaQuery from "../../../utils/hooks/common/useMediaQuery";
import EffectsBody from "../../EffectsBody/EffectsBody";
import Slider from "../../Slider/Slider";
import styles from "./fxReverb.module.scss";

export default function FxReverb({
                                     active,
                                     setActive,
                                     mix,
                                     setMix,
                                     pre,
                                     setPre,
                                     el,
                                     setEl,
                                     decay,
                                     setDecay, size, setSize, onSubmit
}) {
    const mob915 = useMediaQuery(915);


    const stepsVariants = [
        { number: "100" },
        { number: "80" },
        { number: "60" },
        { number: "40" },
        { number: "20" },
        { number: "0" },
    ];

    const changeMix = (e) => {
        setMix(e.target.value);
    }

    const changePre = (e) => {
        setPre(e.target.value);
    }

    const changeEl = (e) => {
        setEl(e.target.value);
    }

    const changeDecay = (e) => {
        setDecay(e.target.value);
    }

    const changeSize = (e) => {
        setSize(e.target.value);
    }


    return (
        <EffectsBody
            color={"orange"}
            title={"Reverb"}
            thumbColor={"orange"}
            width={"md"}
            active={active}
            setActive={setActive}
            buttonsColor={"orangeRevert"}
            onSubmit={onSubmit}
        >
            <div className={styles.slider}>
                <Slider number={stepsVariants} color={2} min={0} max={100} step={0.1} value={mix} setValue={changeMix} footer={"Mix"} colorInput={"orange"} />
                <Slider number={stepsVariants} color={2} min={0} max={100} step={0.1} value={pre} setValue={changePre} footer={"Pre"} colorInput={"orange"} />
                <Slider number={stepsVariants} color={2} min={0} max={100} step={0.1} value={el} setValue={changeEl} footer={"El/Mx"} colorInput={"orange"} />
                <Slider number={stepsVariants} color={2} min={0} max={100} step={0.1} value={size} setValue={changeSize} footer={"Size"} colorInput={"orange"} />
                <Slider number={stepsVariants} color={2} min={0} max={100} step={0.1} value={decay} setValue={changeDecay} footer={"Decay"} colorInput={"orange"} />
            </div>
        </EffectsBody>
    );
}
