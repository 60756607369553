import React, {useState} from "react";
import useMediaQuery from "../../../utils/hooks/common/useMediaQuery";
import EffectsBody from "../../EffectsBody/EffectsBody";
import Slider from "../../Slider/Slider";
import styles from "./fxChorus.module.scss";

export default function FxChorus({ active, setActive, width, setWidth, mix, setMix, onSubmit }) {
    const mob915 = useMediaQuery(915);

    const widthVariants = [
        { number: "180" },
        { number: "150" },
        { number: "120" },
        { number: "90" },
        { number: "60" },
        { number: "30" },
        { number: "0" },
    ];

    const mixVariants = [
        { number: "100" },
        { number: "80" },
        { number: "60" },
        { number: "40" },
        { number: "20" },
        { number: "0" },
    ];

    const changeWidth = (e) => {
        setWidth(e.target.value);
    }

    const changeMix = (e) => {
        setMix(e.target.value);
    }

    return (
        <EffectsBody
            color={"green"}
            title={"Chorus"}
            thumbColor={"green"}
            width={mob915 !== true && "sm"}
            active={active}
            setActive={setActive}
            buttonsColor={"greenRevert"}
            onSubmit={onSubmit}
        >
            <div className={styles.slider}>
                <Slider number={widthVariants} color={3} min={0} max={180} step={1} value={width} setValue={changeWidth} footer={"Width"} colorInput={"green"} />
                <Slider number={mixVariants} color={3} min={0} max={100} step={1} value={mix} setValue={changeMix} footer={"Mix"} colorInput={"green"} />
            </div>
        </EffectsBody>
    );
}
