import React, {useState, useRef, useEffect} from 'react'

import styles from "./modalProjectsList.module.scss";
import GradientButton from "../../UI/GradientButton/GradientButton";

import {deleteProject, getProjects} from "../../../api/api";

import { ReactComponent as FolderIcon } from "../../../assets/icons/folder.svg";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import cn from "classnames";

import { ReactComponent as Basket } from "../../../assets/icons/basket.svg";

export default function ModalProjectsList({ currentProjectId, visible, onClose, onLoad })
{
    const user = useSelector((state) => state.auth.user)

    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedProjectId, setSelectedProjectId] = useState(null);

    const selectProject = (id) => {
        setSelectedProjectId(id)
    }

    const loadSelectedProject = (id) => {
        if (id) {
            onClose()
            onLoad(id)
        }
    }

    const handleRemove = (id) => {
        if (id === currentProjectId) {
            toast.error('Нельзя удалить текущий проект')
            return false
        }

        if (window.confirm('Вы действительно хотите удалить проект?')) {
            deleteProject(user.token, id, function (response) {
                toast.success('Проект успешно удален')
                setItems(items.filter(item => item._id !== id))
            }, function (error) {
                toast.error(error.message)
            });
        }
    }

    useEffect(() => {
        if (visible) {
            setLoading(true)

            getProjects(user.token, function (response) {
                setItems(response.data)
                setLoading(false)
            }, function (error) {
                toast.error(error.message)
                setLoading(false)
            });
        }
    }, [visible]);

    return (
        <>
            <div
                className={styles.modal__background}
                style={
                    !visible ? { display: "none" } : { display: "flex" }
                }
            ></div>
            <div
                className={styles.wrapper}
                style={
                    !visible ? { display: "none" } : { display: "flex" }
                }
            >

                <div className={styles.container}>
                    <div className={styles.title}>
                        <div className={styles.title__img}>
                            <FolderIcon />
                        </div>
                        <p>Открыть проект</p>
                    </div>
                    <div className={styles.body}>
                        <div className={styles.body__list}>
                            {items.length > 0 && items.map((item) => (
                                <div key={item._id} className={cn(styles.body__list__item, selectedProjectId === item._id && styles.body__list__item__active)} onClick={() => selectProject(item._id)}>
                                    <div className={styles.body__list__item__title}>
                                        <p>{item.title}</p>
                                        <small>
                                            {new Date(item.updatedAt).toLocaleString('ru-RU', {
                                                day: 'numeric',
                                                month: 'numeric',
                                                year: 'numeric',
                                                hour: 'numeric',
                                                minute: 'numeric',
                                            })}
                                        </small>
                                    </div>
                                    <div className={styles.body__list__item__remove} onClick={() => !item.trackId && handleRemove(item._id)}>
                                        {!item.trackId && (
                                            <Basket />
                                        )}
                                    </div>
                                </div>
                            ))}

                            {items.length === 0 && !loading && (
                                <div className={styles.body__list__state}>
                                    <p>У вас нет проектов</p>
                                </div>
                            )}

                            {loading && (
                                <div className={styles.body__list__state}>
                                    <p>Загрузка...</p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={styles.footer}>
                        <GradientButton disabled={!selectedProjectId} size={"modal"} color={"violet"} onClick={() => loadSelectedProject(selectedProjectId)}>
                            Открыть
                        </GradientButton>
                        <GradientButton size={"modal"} onClick={onClose}>
                            Отмена
                        </GradientButton>
                    </div>
                </div>
            </div>
        </>
    );
}