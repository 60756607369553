import React from "react";
import styles from "./fxValueSwitcher.module.scss";

export default function FxValueSwitcher({ active, setActive }) {
    const timeItems = [
        { id: "1", text: "1" },
        { id: "1/2'", text: "1/2'" },
        { id: "1/2", text: "1/2" },
        { id: "1/2T", text: "1/2T" },
        { id: "1/4'", text: "1/4'" },
        { id: "1/4", text: "1/4" },
        { id: "1/4T", text: "1/4T" },
        { id: "1/8'", text: "1/8'" },
        { id: "1/8", text: "1/8" },
        { id: "1/8T", text: "1/8T" },
        { id: "1/16", text: "1/16" },
    ];
    const handleClick = (id) => {
        if (active === id) {
            setActive(false);
        } else {
            setActive(id);
        }
    };
    return (
        <div className={styles.delay__wrapper}>
            <ul className={styles.delay__time}>
                {timeItems.map((item) => (
                    <li
                        className={active === item.id ? styles.li_active : ""}
                        onClick={() => handleClick(item.id)}
                    >
                        {item.text}
                    </li>
                ))}
            </ul>
            <p>time</p>
        </div>
    );
}
