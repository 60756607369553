import React, { useState } from "react";
import styles from "./horizontalRangeSlider.module.scss";

export default function HorizontalRangeSlider({ id, pan, volume, changeVolume, balance, changeBalance }) {
    const handlerChangeVolume = (e) => {
        changeVolume(id, e.target.value);
    }

    const handlerChangeBalance = (e) => {
        changeBalance(id, e.target.value);
    }

    return (
        <div className={styles.wrapper}>
            {pan === true && (
                <>
                    <div>
                        <p>pan</p>
                        <input
                            type="range"
                            name="pan"
                            min="-1"
                            max="1"
                            step="0.1"
                            value={balance}
                            onChange={handlerChangeBalance}
                        />
                        <p>{balance}</p>
                    </div>
                </>
            )}
            <div>
                <p>vol.</p>
                <input
                    type="range"
                    name="vol"
                    min="0"
                    max="1"
                    step="0.1"
                    value={volume}
                    onChange={handlerChangeVolume}
                    style={{
                        background: `linear-gradient(to right, white 0%, white ${
                            volume * 10 * 10
                        }%, rgba(0, 0, 0, 0.25) ${
                            0
                        }%, rgba(0, 0, 0, 0.25) 100%)`,
                    }}
                />
                <p className={styles.db}>{volume}</p>
            </div>
        </div>
    );
}
