import React, {useState} from "react";
import useMediaQuery from "../../../utils/hooks/common/useMediaQuery";
import EffectsBody from "../../EffectsBody/EffectsBody";
import styles from "./fxEq.module.scss";
import Slider from "../../Slider/Slider";

export default function FxEq({
                                 active,
                                 setActive,
                                 band1,
                                 band2,
                                 band3,
                                 band4,
                                 band5,
                                 band6,
                                 band7,
                                 band8,
                                 setBand1,
                                 setBand2,
                                 setBand3,
                                 setBand4,
                                 setBand5,
                                 setBand6,
                                 setBand7,
                                 setBand8,
    onSubmit
}) {
    const mob915 = useMediaQuery(915);

    const stepsVariants = [
        { number: "12" },
        { number: "10" },
        { number: "8" },
        { number: "6" },
        { number: "4" },
        { number: "2" },
        { number: "0" },
        { number: "-2" },
        { number: "-4" },
        { number: "-6" },
        { number: "-8" },
        { number: "-10" },
        { number: "-12" },
    ];

    const changeBand1 = (e) => {
        setBand1(e.target.value);
    }

    const changeBand2 = (e) => {
        setBand2(e.target.value);
    }

    const changeBand3 = (e) => {
        setBand3(e.target.value);
    }

    const changeBand4 = (e) => {
        setBand4(e.target.value);
    }

    const changeBand5 = (e) => {
        setBand5(e.target.value);
    }

    const changeBand6 = (e) => {
        setBand6(e.target.value);
    }

    const changeBand7 = (e) => {
        setBand7(e.target.value);
    }

    const changeBand8 = (e) => {
        setBand8(e.target.value);
    }

    return (
        <EffectsBody
            color={"primary"}
            title={"EQUALIZER"}
            thumbColor={"primary"}
            width={"xl"}
            active={active}
            setActive={setActive}
            buttonsColor={"primaryRevert"}
            onSubmit={onSubmit}
        >
            <div className={styles.slider}>
                <Slider number={stepsVariants} color={0} min={-12} max={12} step={1} value={band1} setValue={changeBand1} footer={"120"} colorInput={"primary"} />
                <Slider number={stepsVariants} color={0} min={-12} max={12} step={1} value={band2} setValue={changeBand2} footer={"346"} colorInput={"primary"} />
                <Slider number={stepsVariants} color={0} min={-12} max={12} step={1} value={band3} setValue={changeBand3} footer={"950"} colorInput={"primary"} />
                <Slider number={stepsVariants} color={0} min={-12} max={12} step={1} value={band4} setValue={changeBand4} footer={"2000"} colorInput={"primary"} />
                <Slider number={stepsVariants} color={0} min={-12} max={12} step={1} value={band5} setValue={changeBand5} footer={"4000"} colorInput={"primary"} />
                <Slider number={stepsVariants} color={0} min={-12} max={12} step={1} value={band6} setValue={changeBand6} footer={"5040"} colorInput={"primary"} />
                <Slider number={stepsVariants} color={0} min={-12} max={12} step={1} value={band7} setValue={changeBand7} footer={"6140"} colorInput={"primary"} />
                <Slider number={stepsVariants} color={0} min={-12} max={12} step={1} value={band8} setValue={changeBand8} footer={"8000"} colorInput={"primary"} />
            </div>
        </EffectsBody>
    );
}
