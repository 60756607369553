import cn from "classnames";
import React, { useState } from "react";
import styles from "./slider.module.scss";

export default function Slider({
    min,
    max,
    step,
    value,
    setValue,
    colorInput,
    footer,
    leftSide,
    color,
    number,
}) {
    const colorItems = [
        {
            left: "var(--primary-color-top)",
            right: "var(--primary-color-bottom)",
        },
        {
            left: "var(--violet-color-top)",
            right: "var(--violet-color-bottom)",
        },
        {
            left: "var(--orange-color-top)",
            right: "var(--orange-color-bottom)",
        },
        {
            left: "var(--green-color-top)",
            right: "var(--green-color-bottom)",
        },
        {
            left: "var(--yellow-color-top)",
            right: "var(--yellow-color-bottom)",
        },
    ];


    const changeValue = (e) => {
        setValue(e.target.value);
    }

    let stepsCount = 0;

    for (let i = min; i <= max; i += step) {
        stepsCount++;
    }

    const getCurrentStep = (val) => {
        let result = 0

        for (let i = min; i <= val; i += step) {
            result++
        }

        return result
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.slider}>
                <div className={styles.slider__body}>
                    <input
                        style={{
                            background: `linear-gradient(to right, ${colorItems[color]?.left} 0%, ${colorItems[color]?.right} ${getCurrentStep(value) * 100 / stepsCount}%, #2f2f2f ${getCurrentStep(value) * 100 / stepsCount}%, #2f2f2f 100%)`,
                        }}
                        type="range"
                        min={min}
                        max={max}
                        step={step}
                        value={value}
                        onChange={setValue}
                        className={cn(styles[colorInput], styles.slider__input)}
                    />
                    {number !== undefined && (
                        <ul>
                            {number?.map((item, index) => (
                                <li key={item.number}>{item.number}</li>
                            ))}
                        </ul>
                    )}
                    <div className={styles.input__leftside}>{leftSide}</div>
                </div>
                {/* <div className={styles.slider__footer}>
                <p className={styles[colorInput]}>{footer}</p>
            </div> */}
            </div>
            <div className={styles.slider__footer}>
                <p className={styles[colorInput]}>{footer}</p>
            </div>
        </div>
    );
}
