import React, { useState, useEffect } from 'react'

import styles from "./modalBuyTrack.module.scss";
import GradientButton from "../../UI/GradientButton/GradientButton";
import {getTrackDetails} from "../../../api/api";

export default function ModalBuyTrack({ visible, onClose, trackId })
{
    const [track, setTrack] = useState(null)

    const goToBuy = () => {

        window.open(`${process.env.REACT_APP_MUZNAVIGATOR_URL}/${track?.data.url}`, '_blank')
        onClose();
    }

    useEffect(() => {
        if (trackId) {
            // fetch track data
            getTrackDetails(trackId, (data) => {
                setTrack(data.data)
            }, (error) => {
                console.error(error)
            });
        }
    }, [trackId]);

    return (
        <>
            <div
                className={styles.modal__background}
                style={
                    !visible ? { display: "none" } : { display: "flex" }
                }
            ></div>
            <div
                className={styles.wrapper}
                style={
                    !visible ? { display: "none" } : { display: "flex" }
                }
            >
                <div className={styles.container}>
                    <div className={styles.body}>
                        <p>Для скачивания трека необходимо его приобрести</p>
                        <p>Вы можете это сделать на сайте <a target="_blank" href={"https://muznavigator.com"}>muznavigator.com</a></p>
                    </div>
                    <div className={styles.footer}>
                        <GradientButton color={"violet"} onClick={goToBuy} style={{width: 300}}>
                            Перейти на сайт для покупки
                        </GradientButton>
                    </div>
                </div>
            </div>
        </>
    );
}