import React, { useState, useRef, useEffect, useContext } from 'react'
import Tuna from 'tunajs';
import styles from "./modalMicOptions.module.scss";
import cn from "classnames";
import GradientButton from "../../UI/GradientButton/GradientButton";
import context from "../../../context/Context";

export default function ModalMicOptions({ visible, onClose })
{
    const [inputDevices, setInputDevices] = useState([]);

    const impulses = [
        {
            name: 'Basic Reverb',
            file: 'impulse_rev.wav'
        },

        {
            name: 'Reverb Short',
            file: 'ir_rev_short.wav'
        },

        {
            name: 'Block Inside',
            file: 'Block_Inside.wav'
        },

        {
            name: 'Bottle Hall',
            file: 'Bottle_Hall.wav'
        },

        {
            name: 'Cement Blocks 1',
            file: 'Cement_Blocks_1.wav'
        },

        {
            name: 'Cement Blocks 2',
            file: 'Cement_Blocks_2.wav'
        },

        {
            name: 'Chateau de Logne Outside',
            file: 'Chateau_de_Logne_Outside.wav'
        },

        {
            name: 'Conic Long Echo Hall',
            file: 'Conic_Long_Echo_Hall.wav'
        },

        {
            name: 'Deep Space',
            file: 'Deep_Space.wav'
        },

        {
            name: 'Derlon Sanctuary',
            file: 'Derlon_Sanctuary.wav'
        },

        {
            name: 'Direct Cabinet N1',
            file: 'Direct_Cabinet_N1.wav'
        },

        {
            name: 'Direct Cabinet N2',
            file: 'Direct_Cabinet_N2.wav'
        },

        {
            name: 'Direct Cabinet N3',
            file: 'Direct_Cabinet_N3.wav'
        },

        {
            name: 'Direct Cabinet N4',
            file: 'Direct_Cabinet_N4.wav'
        },

        {
            name: 'Five Columns',
            file: 'Five_Columns.wav'
        },

        {
            name: 'Five Columns Long',
            file: 'Five_Columns_Long.wav'
        },

        {
            name: 'French 18th Century Salon',
            file: 'French_18th_Century_Salon.wav'
        },

        {
            name: 'Going Home',
            file: 'Going_Home.wav'
        },

        {
            name: 'Greek 7 Echo Hall',
            file: 'Greek_7_Echo_Hall.wav'
        },

        {
            name: 'Highly Damped Large Room',
            file: 'Highly_Damped_Large_Room.wav'
        },

        {
            name: 'In The Silo',
            file: 'In_The_Silo.wav'
        },

        {
            name: 'In The Silo Revised',
            file: 'In_The_Silo_Revised.wav'
        },

        {
            name: 'Large Bottle Hall',
            file: 'Large_Bottle_Hall.wav'
        },

        {
            name: 'Large Long Echo Hall',
            file: 'Large_Long_Echo_Hall.wav'
        },

        {
            name: 'Large Wide Echo Hall',
            file: 'Large_Wide_Echo_Hall.wav'
        },

        {
            name: 'Masonic Lodge',
            file: 'Masonic_Lodge.wav'
        },

        {
            name: 'Musikvereinsaal',
            file: 'Musikvereinsaal.wav'
        },

        {
            name: 'Narrow Bumpy Space',
            file: 'Narrow_Bumpy_Space.wav'
        },

        {
            name: 'Nice Drum Room',
            file: 'Nice_Drum_Room.wav'
        },

        {
            name: 'On a Star',
            file: 'On_a_Star.wav'
        },

        {
            name: 'Parking Garage',
            file: 'Parking_Garage.wav'
        },

        {
            name: 'Rays',
            file: 'Rays.wav'
        },

        {
            name: 'Right Glass Triangle',
            file: 'Right_Glass_Triangle.wav'
        },

        {
            name: 'Ruby Room',
            file: 'Ruby_Room.wav'
        },

        {
            name: 'Scala Milan Opera Hall',
            file: 'Scala_Milan_Opera_Hall.wav'
        },

        {
            name: 'Small Drum Room',
            file: 'Small_Drum_Room.wav'
        },

        {
            name: 'Small Prehistoric Cave',
            file: 'Small_Prehistoric_Cave.wav'
        },

        {
            name: 'St Nicolaes Church',
            file: 'St_Nicolaes_Church.wav'
        },

        {
            name: 'Sweetspot1M',
            file: 'Sweetspot1M.wav'
        },

        {
            name: 'Trig Room',
            file: 'Trig_Room.wav'
        },

        {
            name: 'Vocal Duo',
            file: 'Vocal_Duo.wav'
        }
    ];

    const { micOptions, saveMicOptions } = useContext(context)

    const [selectedDeviceId, setSelectedDeviceId] = useState(micOptions.deviceId !== undefined ? micOptions.deviceId : '');
    const [selectedImpulse, setSelectedImpulse] = useState(micOptions.impulse !== undefined ? micOptions.impulse : impulses[0]['file']);

    const [playback, setPlayback] = useState(micOptions.playback !== undefined ? micOptions.playback :  false);
    const [reverb, setReverb] = useState(micOptions.effect !== undefined ? micOptions.effect : false);

    const mediaRecorder = useRef(null);
    const audioSource = useRef(null);
    const audioContext = useRef(null);
    const [permission, setPermission] = useState(false);
    const [stream, setStream] = useState(null);
    const [audioChunks, setAudioChunks] = useState([]);

    const changeAudioDevice = (e) => {
        setSelectedDeviceId(e.target.value)
    }

    const changePlayback = () => {
        setPlayback(!playback)
    }

    const changeReverb = () => {
        setReverb(!reverb)
    }

    const changeImpulse = (e) => {
        setSelectedImpulse(e.target.value)
    }

    const getMicrophonePermission = async () => {
        if ("MediaRecorder" in window) {
            try {
                navigator.mediaDevices.getUserMedia({
                    audio: {
                        echoCancellation: false,
                        autoGainControl: true,
                        noiseSuppression: true,
                        latency: 0.003,
                        deviceId: selectedDeviceId
                    },
                    video: false,
                }).then(function (stream) {
                    setPermission(true);
                    setStream(stream);
                }).catch(function (error) {
                    alert(error);
                });

            } catch (err) {
                alert(err.message);
            }
        } else {
            alert("The MediaRecorder API is not supported in your browser.");
        }
    };

    const saveHandler = () => {
        if (mediaRecorder.current !== null) {
            mediaRecorder.current.stop();

            audioSource.current = null;

            if (audioContext.current !== null && audioContext.current.state !== "closed") {
                audioContext.current.close();
            }
        }

        saveMicOptions(selectedDeviceId, playback, reverb, selectedImpulse);
    }

    const closeHandler = () => {
        if (mediaRecorder.current !== null) {
            mediaRecorder.current.stop();

            audioSource.current = null;

            if (audioContext.current !== null && audioContext.current.state !== "closed") {
                audioContext.current.close();
            }
        }

        onClose();
    }

    useEffect(() => {
        if (visible) {
            setSelectedDeviceId(micOptions.deviceId);
            setSelectedImpulse(micOptions.impulse);
            setReverb(micOptions.effect);
            setPlayback(micOptions.playback);
        }

    }, [micOptions, visible]);

    useEffect(() => {
        let devices = navigator.mediaDevices;
        if (devices && 'enumerateDevices' in devices) {
            let promise = devices.enumerateDevices();
            promise
                .then(function(devices) {
                    var audio = [];
                    for (let i = 0; i < devices.length; ++i) {
                        let device = devices[i];
                        switch (device.kind) {
                            case 'audioinput': audio.push(device); break;
                        }
                    }

                    setInputDevices(audio);
                });
        }
    }, []);

    useEffect(() => {
        getMicrophonePermission();
    }, [selectedDeviceId]);


    useEffect(() => {
        if (permission && visible) {
            if (mediaRecorder.current !== null) {
                mediaRecorder.current.stop();

                audioSource.current = null;

                if (audioContext.current !== null && audioContext.current.state !== "closed") {
                    audioContext.current.close();
                }
            }

            //set the MediaRecorder instance to the mediaRecorder ref
            mediaRecorder.current = new MediaRecorder(stream, {type: "audio/webm"});
            //invokes the start method to start the recording process
            mediaRecorder.current.start();

            if (playback) {
                audioContext.current = new AudioContext({latencyHint: 0});
                audioSource.current = audioContext.current.createMediaStreamSource(stream);
            }

            if (reverb && playback) {
                let tuna = new Tuna(audioContext.current);
                let effect = new tuna.Convolver({
                    highCut: 22050,                         //20 to 22050
                    lowCut: 20,                             //20 to 22050
                    dryLevel: 1,                            //0 to 1+
                    wetLevel: 1,                            //0 to 1+
                    level: 1,                               //0 to 1+, adjusts total output of both wet and dry
                    impulse: "../assets/impulses/" + selectedImpulse,    //the path to your impulse response
                    bypass: 0
                });

                audioSource.current.connect(effect);
                effect.connect(audioContext.current.destination);
            } else if (playback) {
                audioSource.current.connect(audioContext.current.destination);
            }
        }


    }, [playback, reverb, selectedDeviceId, selectedImpulse, permission, visible])


    return (
        <>
            <div
                className={styles.modal__background}
                style={
                    !visible ? { display: "none" } : { display: "flex" }
                }
            ></div>
            <div
                className={styles.wrapper}
                style={
                    !visible ? { display: "none" } : { display: "flex" }
                }
            >
                <div className={styles.container}>
                    <div className={styles.body}>
                        <p>Настройки микрофона</p>
                        <p>Пожалуйста, проверьте свой микрофон <br />и установите оптимальные значения параметров:</p>
                    </div>
                    <div className={styles.formWrapper}>

                        <div className={styles.form_group}>
                            <label>
                                Устройство записи
                            </label>
                            <select onChange={changeAudioDevice} value={selectedDeviceId}>
                                {inputDevices.map((device, index) => {
                                    return (
                                        <option key={index} value={device.deviceId}>{device.label}</option>
                                    )
                                })}
                            </select>
                        </div>

                        <div className={styles.form_group}>
                            <label className="form-check-label" htmlFor="add-playback">
                                Включить мониторинг
                            </label>
                            <input checked={playback} onChange={changePlayback} className="form-check-input" type="checkbox" role="switch" id="add-playback" />
                        </div>

                        {playback && (
                            <div className={styles.form_group}>
                                <label className="form-check-label" htmlFor="add-effect">
                                    Добавлять эффект
                                </label>
                                <input checked={reverb} onChange={changeReverb} className="form-check-input" type="checkbox" role="switch" id="add-effect" />
                            </div>
                        )}

                        {playback && reverb && (
                            <div className='mb-2'>
                                <div className={styles.form_group}>
                                    <label>
                                        Выберите эффект
                                    </label>
                                    <select onChange={changeImpulse}>
                                        {impulses.map((item, index) => {
                                            return (
                                                <option selected={selectedImpulse === item.file} key={index} value={item.file}>{item.name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                        )}

                    </div>
                    <div className={styles.footer}>
                        <GradientButton size={"modal"} color={"violet"} onClick={saveHandler}>
                            Применить
                        </GradientButton>

                        {onClose !== undefined && (
                            <GradientButton onClick={closeHandler} size={"modal"}>
                                Отменить
                            </GradientButton>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}