import React from 'react'

import styles from "./modalText.module.scss";
import GradientButton from "../../UI/GradientButton/GradientButton";

import { ReactComponent as FolderIcon } from "../../../assets/icons/folder.svg";

export default function ModalText({ visible, onClose, text, setText })
{
    return (
        <>
            <div
                className={styles.wrapper}
                style={
                    !visible ? { display: "none" } : { display: "flex" }
                }
            >

                <div className={styles.container}>
                    <div className={styles.title}>
                        <p>Текст песни</p>
                    </div>
                    <div className={styles.body}>
                        <div className={styles.body__text_wrapper}>
                            <textarea defaultValue={text} onChange={(e) => setText(e.target.value)}></textarea>
                        </div>
                    </div>
                    <div className={styles.footer}>
                        <GradientButton size={"modal"} onClick={onClose}>
                            Закрыть
                        </GradientButton>
                    </div>
                </div>
            </div>
        </>
    );
}