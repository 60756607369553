import cn from "classnames";
import React, { useState } from "react";
import styles from "./thumb.module.scss";

export default function Thumb({ thumbColor, onclick, value = false })
{

    return (
        <label className={styles.thumb}>
            <div>
                <p style={{visibility: value === true ? "visible" : "hidden"}}>
                    on
                </p>
                <p style={{visibility: value === false ? "visible" : "hidden"}}>
                    off
                </p>
            </div>
            <input
                type={"checkbox"}
                onChange={onclick}
                checked={!value}
            ></input>
            <span className={cn(styles.slider, styles[thumbColor])} />
        </label>
    );
}
