import useState from 'react-usestateref';

const useErrorModal = (title, subtitle, message) =>
{
    const [visible, setVisible, visibleRef] = useState(false);

    const show = () => {
        setVisible(true);
    }

    const hide = () => {
        setVisible(false);
    }

    return {
        show,
        hide,
        title,
        subtitle,
        message,
        visible
    }
}

export default useErrorModal;