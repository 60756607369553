import React, { useState } from "react";
import styles from "./checkbox.module.scss";

export default function Checkbox({ children, style, isChecked }) {
    return (
        <label className={`${styles.wrapper} ${styles[style]}`}>
            <p>{children}</p>
            <input
                type={"checkbox"}
                checked={isChecked}
                readOnly={true}
            ></input>
            <span className={`${styles.circle} ${styles[style]}`}></span>
        </label>
    );
}
