import React from "react";
import PropTypes from 'prop-types';
import Modal from "../Modal";
import { ReactComponent as HourGlass } from "../../../assets/icons/hourglass.svg";

export default function ModalProcessing({ title, subtitle, isVisible, onClose, type })
{
    let typeText = "ИДЁТ ОБРАБОТКА";
    let color = "purple";

    switch (type) {

        case "upload":
            typeText = "ИДЁТ ЗАГРУЗКА ФАЙЛА";
            color = "primary";
            title = "Идет отправка файла на наш сервер для дальнейшей работы.";
            subtitle = "Пожалуйста, подождите, пока процесс не завершится.";
        break;

        case "export":
            typeText = "ИДЁТ ЭКСПОРТ";
            color = "green";
            title = "Идет сведение трека и экспорт в формат mp3.";
            subtitle = "Пожалуйста, подождите, пока процесс не завершится.";
        break;


        default:
            typeText = "ИДЁТ ОБРАБОТКА";
            color = "purple";
            title = "Идет обработка трека на нашем сервере и подготовка к воспроизведению."
            subtitle = "Пожалуйста, подождите, пока процесс не завершится.";
    }


    return (
        <>
            <Modal
                type={typeText}
                title={title}
                sub={subtitle}
                gray={"отменить"}
                svg={<HourGlass />}
                visible={isVisible}
                onClose={onClose}
                color={color}
            />
        </>
    );
}

ModalProcessing.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
};

ModalProcessing.defaultProps = {
    title: "Пожалуйста, подождите, пока процесс не завершится.",
    subtitle: "Внимание! Это может занять некоторое время."
}

