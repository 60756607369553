import React, {useContext, useEffect, useState} from "react";
import useMediaQuery from "../../utils/hooks/common/useMediaQuery";
import HorizontalRangeSlider from "../HorizontalRangeSlider/HorizontalRangeSlider";
import GradientButton from "../UI/GradientButton/GradientButton";
import RemoveTrack from "../UI/RemoveTrack/RemoveTrack";
import { ReactComponent as CloseArrow } from "../../assets/icons/close_arrow.svg";
import { ReactComponent as Cancel } from "../../assets/icons/cancel.svg";

import context from "../../context/Context";

import "swiper/css";

import styles from "./mixers.module.scss";
import FxChorus from "../FX/Chorus/FxChorus";
import FxForm from "../FX/Form/FxForm";
import FxDelay from "../FX/Delay/FxDelay";
import FxSnap from "../FX/Snap/FxSnap";
import FxReverb from "../FX/Reverb/FxReverb";
import FxComp from "../FX/Comp/FxComp";
import FxEq from "../FX/EQ/FxEq";
import FxNormalize from "../FX/Normalize/FxNormalize";

export default function Mixers({ mixerIsActive, setMixerIsActive }) {

    //console.log(presets);

    const mob915 = useMediaQuery(915);

    const {
        currentAudioTrack,
        setCurrentAudioTrack,
        getAudioById,
        updateAudioById,
        setIsNeedToProcess,
        isNeedToProcess,
        prepareToPlay,
        presets,
        currentPresetId,
        currentEffectId,
        setCurrentPresetId,
        setCurrentEffectId,
        addToHistoryHandler,
        isPlaying
    } = useContext(context)

    const [mixersTitle, setMixersTitle] = useState('');

    const [activePreset, setActivePreset] = useState(currentPresetId);

    const [audioTrackData, setAudioTrackData] = useState({});

    const [volume, setVolume] = useState(0);
    const [balance, setBalance] = useState(0);

    // EQ
    const [eqActive, setEqActive] = useState(false);
    const [eqBand1, setEqBand1] = useState(0);
    const [eqBand2, setEqBand2] = useState(0);
    const [eqBand3, setEqBand3] = useState(0);
    const [eqBand4, setEqBand4] = useState(0);
    const [eqBand5, setEqBand5] = useState(0);
    const [eqBand6, setEqBand6] = useState(0);
    const [eqBand7, setEqBand7] = useState(0);
    const [eqBand8, setEqBand8] = useState(0);

    // CHORUS
    const [chorusActive, setChorusActive] = useState(false);
    const [chorusWidth, setChorusWidth] = useState(0);
    const [chorusMix, setChorusMix] = useState(0);

    // REVERB
    const [reverbActive, setReverbActive] = useState(false);
    const [reverbMix, setReverbMix] = useState(0);
    const [reverbPre, setReverbPre] = useState(0);
    const [reverbEl, setReverbEl] = useState(0);
    const [reverbDecay, setReverbDecay] = useState(0);
    const [reverbSize, setReverbSize] = useState(0);

    // FORMANT
    const [formantActive, setFormantActive] = useState(false);
    const [formantPitch, setFormantPitch] = useState(0);
    const [formantFormant, setFormantFormant] = useState(0);

    // DELAY
    const [delayActive, setDelayActive] = useState(false);
    const [delayTime, setDelayTime] = useState(0);
    const [delayFeedback, setDelayFeedback] = useState(-40);
    const [delayEffect, setDelayEffect] = useState(-40);

    // SNAP
    const [snapActive, setSnapActive] = useState(false);
    const [snapSpeed, setSnapSpeed] = useState(1);
    const [snapAmount, setSnapAmount] = useState(0);

    // COMPRESSOR
    const [compActive, setCompActive] = useState(false);
    const [compSoftness, setCompSoftness] = useState(75);

    // NORMALIZE
    const [normalizeActive, setNormalizeActive] = useState(false);
    const [normalizeGain, setNormalizeGain] = useState(7);

    const changeVolume = (id, volume) => {
        let item = getAudioById(id);
        item.volume = volume;

        updateAudioById(id, item);
        setVolume(volume)
    }

    const changeBalance = (id, balance) => {
        let item = getAudioById(id);
        item.balance = balance;

        updateAudioById(id, item);
        setBalance(balance)
    }

    const handleClearTrack = (id) => {
        if (!isPlaying && id) {

            let item = getAudioById(id);
            item.audio = '';
            item.samples = [];
            item.FX = {};
            item.preset = '';
            item.bpm = 0;
            item.key = '';
            updateAudioById(id, item);

            addToHistoryHandler();
        }

        setMixerIsActive(false);
    }

    useEffect(() => {
        setChorusActive(false);
        setChorusWidth(0);
        setChorusMix(0);

        setReverbActive(false);
        setReverbMix(0);
        setReverbPre(0);
        setReverbEl(0);
        setReverbDecay(0);
        setReverbSize(0);

        setFormantActive(false);
        setFormantPitch(0);
        setFormantFormant(0);

        setDelayActive(false);
        setDelayTime(0);
        setDelayFeedback(-40);
        setDelayEffect(-40);

        setSnapActive(false);
        setSnapSpeed(1);
        setSnapAmount(0);

        setCompActive(false);
        setCompSoftness(75);

        setEqActive(false);
        setEqBand1(0);
        setEqBand2(0);
        setEqBand3(0);
        setEqBand4(0);
        setEqBand5(0);
        setEqBand6(0);
        setEqBand7(0);
        setEqBand8(0);

        setNormalizeActive(false);
        setNormalizeGain(7);

        if (currentEffectId !== "") {
            let audioTrack = null;

            if (currentAudioTrack) {
                audioTrack = getAudioById(currentAudioTrack);
            }

            setMixersTitle(audioTrack.title);

            switch (currentEffectId) {
                case 'eq':
                    setEqActive(true);

                    /*if (audioTrack !== null && typeof audioTrack.FX.eq !== "undefined" && audioTrack.FX.eq.active) {
                        setEqBand1(audioTrack.FX.eq.params.band_1_level);
                        setEqBand2(audioTrack.FX.eq.params.band_2_level);
                        setEqBand3(audioTrack.FX.eq.params.band_3_level);
                        setEqBand4(audioTrack.FX.eq.params.band_4_level);
                        setEqBand5(audioTrack.FX.eq.params.band_5_level);
                        setEqBand6(audioTrack.FX.eq.params.band_6_level);
                        setEqBand7(audioTrack.FX.eq.params.band_7_level);
                        setEqBand8(audioTrack.FX.eq.params.band_8_level);
                    }*/

                    break;
                case 'chorus':
                    setChorusActive(true);

                    /*if (audioTrack !== null && typeof audioTrack.FX.chorus !== "undefined" && audioTrack.FX.chorus.active) {
                        setChorusWidth(audioTrack.FX.chorus.params.width);
                        setChorusMix(audioTrack.FX.chorus.params.mix);
                    }*/
                    break;
                case 'reverb':
                    setReverbActive(true);

                    /*if (audioTrack !== null && typeof audioTrack.FX.reverb !== "undefined" && audioTrack.FX.reverb.active) {
                        setReverbMix(audioTrack.FX.reverb.params.mix);
                        setReverbPre(audioTrack.FX.reverb.params.pre);
                        setReverbEl(audioTrack.FX.reverb.params.elMx);
                        setReverbDecay(audioTrack.FX.reverb.params.decay);
                        setReverbSize(audioTrack.FX.reverb.params.size);
                    }*/
                    break;
                case 'form':
                    setFormantActive(true);

                    /*if (audioTrack !== null && typeof audioTrack.FX.form !== "undefined" && audioTrack.FX.form.active) {
                        setFormantPitch(audioTrack.FX.form.params.pitch);
                        setFormantFormant(audioTrack.FX.form.params.formant);
                    }*/
                    break;
                case 'delay':
                    setDelayActive(true);

                    /*if (audioTrack !== null && typeof audioTrack.FX.delay !== "undefined" && audioTrack.FX.delay.active) {
                        setDelayTime(audioTrack.FX.delay.params.delay);
                        setDelayFeedback(audioTrack.FX.delay.params.feedback);
                        setDelayEffect(audioTrack.FX.delay.params.effect);
                    }*/
                    break;
                case 'snap':
                    setSnapActive(true);

                    /*if (audioTrack !== null && typeof audioTrack.FX.snap !== "undefined" && audioTrack.FX.snap.active) {
                        setSnapSpeed(audioTrack.FX.snap.params.speed);
                        setSnapAmount(audioTrack.FX.snap.params.amount);
                    }*/
                    break;
                case 'comp':
                    setCompActive(true);
                    break;

                case 'normalize':
                    setNormalizeActive(true);

                    /*if (audioTrack !== null && typeof audioTrack.FX.normalize !== "undefined" && audioTrack.FX.normalize.active) {
                        setNormalizeGain(audioTrack.FX.normalize.params.gain);
                    }*/
                    break;
            }
        }

    }, [currentEffectId]);


    useEffect(() => {

        if (currentAudioTrack) {
            const audioTrack = getAudioById(currentAudioTrack);

            if (audioTrack !== null) {
                setAudioTrackData(audioTrack);
                setVolume(audioTrack.volume);
                setBalance(audioTrack.balance);

                // EQ
                if (typeof audioTrack.FX.eq !== "undefined") {
                    setEqActive(audioTrack.FX.eq.active);

                    if (typeof audioTrack.FX.eq.params !== "undefined") {
                        setEqBand1(audioTrack.FX.eq.params.band_1_level);
                        setEqBand2(audioTrack.FX.eq.params.band_2_level);
                        setEqBand3(audioTrack.FX.eq.params.band_3_level);
                        setEqBand4(audioTrack.FX.eq.params.band_4_level);
                        setEqBand5(audioTrack.FX.eq.params.band_5_level);
                        setEqBand6(audioTrack.FX.eq.params.band_6_level);
                        setEqBand7(audioTrack.FX.eq.params.band_7_level);
                        setEqBand8(audioTrack.FX.eq.params.band_8_level);
                    }
                } else if (currentEffectId === 'eq') {
                    setEqActive(true);
                } else {
                    setEqActive(false);
                }

                // CHORUS
                if (typeof audioTrack.FX.chorus !== "undefined") {
                    setChorusActive(audioTrack.FX.chorus.active);

                    if (typeof audioTrack.FX.chorus.params !== "undefined") {
                        setChorusWidth(audioTrack.FX.chorus.params.width);
                        setChorusMix(audioTrack.FX.chorus.params.mix);
                    }
                } else if (currentEffectId === 'chorus') {
                    setChorusActive(true);
                } else {
                    setChorusActive(false);
                }

                // REVERB
                if (typeof audioTrack.FX.reverb !== "undefined") {
                    setReverbActive(audioTrack.FX.reverb.active);

                    if (typeof audioTrack.FX.reverb.params !== "undefined") {
                        setReverbMix(audioTrack.FX.reverb.params.mix);
                        setReverbPre(audioTrack.FX.reverb.params.pre);
                        setReverbEl(audioTrack.FX.reverb.params.elMx);
                        setReverbDecay(audioTrack.FX.reverb.params.decay);
                        setReverbSize(audioTrack.FX.reverb.params.size);
                    }
                } else if (currentEffectId === 'reverb') {
                    setReverbActive(true);
                } else {
                    setReverbActive(false);
                }

                // FORMANT
                if (typeof audioTrack.FX.form !== "undefined") {
                    setFormantActive(audioTrack.FX.form.active);

                    if (typeof audioTrack.FX.form.params !== "undefined") {
                        setFormantPitch(audioTrack.FX.form.params.pitch);
                        setFormantFormant(audioTrack.FX.form.params.formant);
                    }
                } else if (currentEffectId === 'form') {
                    setFormantActive(true);
                } else {
                    setFormantActive(false);
                }

                // DELAY
                if (typeof audioTrack.FX.delay !== "undefined") {
                    setDelayActive(audioTrack.FX.delay.active);

                    if (typeof audioTrack.FX.delay.params !== "undefined") {
                        setDelayTime(audioTrack.FX.delay.params.delay);
                        setDelayFeedback(audioTrack.FX.delay.params.feedback);
                        setDelayEffect(audioTrack.FX.delay.params.effect);
                    }
                } else if (currentEffectId === 'delay') {
                    setDelayActive(true);
                } else {
                    setDelayActive(false);
                }

                // SNAP
                if (typeof audioTrack.FX.snap !== "undefined") {
                    setSnapActive(audioTrack.FX.snap.active);

                    if (typeof audioTrack.FX.snap.params !== "undefined") {
                        setSnapSpeed(audioTrack.FX.snap.params.speed);
                        setSnapAmount(audioTrack.FX.snap.params.amount);
                    }
                } else if (currentEffectId === 'snap') {
                    setSnapActive(true);
                } else {
                    setSnapActive(false);
                }

                // COMP
                if (typeof audioTrack.FX.comp !== "undefined") {
                    setCompActive(audioTrack.FX.comp.active);

                    if (typeof audioTrack.FX.comp.params !== "undefined") {
                        setCompSoftness(audioTrack.FX.comp.params.softness);
                    }
                } else if (currentEffectId === 'comp') {
                    setCompActive(true);
                } else {
                    setCompActive(false);
                }

                // NORMALIZE
                if (typeof audioTrack.FX.normalize !== "undefined") {
                    setNormalizeActive(audioTrack.FX.normalize.active);
                    if (typeof audioTrack.FX.normalize.params !== "undefined") {
                        setNormalizeGain(audioTrack.FX.normalize.params.gain);
                    }
                } else if (currentEffectId === 'normalize') {
                    setNormalizeActive(true);
                } else {
                    setNormalizeActive(false);
                }
            }


        }

    }, [currentAudioTrack, mixerIsActive, currentEffectId]);

    const handleOnSubmit = () => {
        if (currentAudioTrack) {
            let audioTrack = getAudioById(currentAudioTrack);
            audioTrack.audio = ''

            switch (currentEffectId) {
                case 'eq':
                    if (eqActive) {
                        audioTrack.FX.eq = {};
                        audioTrack.FX.eq.active = true;
                        audioTrack.FX.eq.params = {
                            band_1_level: eqBand1,
                            band_2_level: eqBand2,
                            band_3_level: eqBand3,
                            band_4_level: eqBand4,
                            band_5_level: eqBand5,
                            band_6_level: eqBand6,
                            band_7_level: eqBand7,
                            band_8_level: eqBand8
                        }
                    } else {
                        audioTrack.FX.eq = {};
                        audioTrack.FX.eq.active = false;
                    }

                    break;

                case 'chorus':
                    if (chorusActive) {
                        audioTrack.FX.chorus = {};
                        audioTrack.FX.chorus.active = true;

                        audioTrack.FX.chorus.params = {
                            width: chorusWidth,
                            mix: chorusMix
                        }
                    } else {
                        audioTrack.FX.chorus = {};
                        audioTrack.FX.chorus.active = false;
                    }
                    break;

                case 'reverb':
                    if (reverbActive) {
                        audioTrack.FX.reverb = {};
                        audioTrack.FX.reverb.active = true;
                        audioTrack.FX.reverb.params = {
                            mix: reverbMix,
                            pre: reverbPre,
                            elMx: reverbEl,
                            decay: reverbDecay,
                            size: reverbSize

                        }
                    } else {
                        audioTrack.FX.reverb = {};
                        audioTrack.FX.reverb.active = false;
                    }
                    break;

                case 'form':
                    if (formantActive) {
                        audioTrack.FX.form = {};
                        audioTrack.FX.form.active = true;
                        audioTrack.FX.form.params = {
                            pitch: formantPitch,
                            formant: formantFormant
                        }
                    } else {
                        audioTrack.FX.form = {};
                        audioTrack.FX.form.active = false;
                    }
                    break;

                case 'delay':
                    if (delayActive) {
                        audioTrack.FX.delay = {};
                        audioTrack.FX.delay.active = true;
                        audioTrack.FX.delay.params = {
                            delay: delayTime,
                            feedback: delayFeedback,
                            effect: delayEffect
                        }
                    } else {
                        audioTrack.FX.delay = {};
                        audioTrack.FX.delay.active = false;
                    }
                    break;

                case 'snap':
                    if (snapActive) {
                        audioTrack.FX.snap = {};
                        audioTrack.FX.snap.active = true;
                        audioTrack.FX.snap.params = {
                            speed: snapSpeed,
                            amount: snapAmount
                        }
                    } else {
                        audioTrack.FX.snap = {};
                        audioTrack.FX.snap.active = false;
                    }
                    break;

                case 'comp':
                    if (compActive) {
                        audioTrack.FX.comp = {};
                        audioTrack.FX.comp.active = true;
                        audioTrack.FX.comp.params = {
                            softness: compSoftness
                        };
                    } else {
                        audioTrack.FX.comp = {};
                        audioTrack.FX.comp.active = false;
                    }
                    break;

                case 'normalize':
                    if (normalizeActive) {
                        audioTrack.FX.normalize = {};
                        audioTrack.FX.normalize.active = true;
                        audioTrack.FX.normalize.params = {
                            gain: normalizeGain,
                            ceiling: -5.5 + (normalizeGain - 7) * 0.3
                        }
                    } else {
                        audioTrack.FX.normalize = {};
                        audioTrack.FX.normalize.active = false;
                    }
                    break;
            }

            updateAudioById(currentAudioTrack, audioTrack)
            setIsNeedToProcess(true)
            setMixerIsActive(false)
            prepareToPlay();

            setCurrentPresetId('');
            setCurrentEffectId('');
            //setCurrentAudioTrack('');

            addToHistoryHandler();
        }
    }

    return (
        <div
            className={styles.mixers__wrapper}
            style={
                mixerIsActive === true
                    ? { display: "flex" }
                    : { display: "none" }
            }
        >
            <div className={styles.title}>
                <div>{mixersTitle}</div>


                {mob915 !== true && (
                    <div
                        className={styles.title__close_desctop}
                        onClick={() => setMixerIsActive(false)}
                    >
                        <div>
                            <Cancel />
                        </div>
                        <p>закрыть</p>
                    </div>
                )}
                {mob915 === true && (
                    <>
                        <HorizontalRangeSlider id={currentAudioTrack} pan={currentAudioTrack == 1 ? false : true} balance={balance} volume={volume} changeVolume={changeVolume} changeBalance={changeBalance} />
                        <div className={styles.title__basket} onClick={() => handleClearTrack(currentAudioTrack)}>
                            <RemoveTrack />
                            <p>очистить дорожку</p>
                        </div>
                        <div
                            className={styles.title__close}
                            onClick={() => setMixerIsActive(false)}
                        >
                            <CloseArrow />
                            <p>закрыть</p>
                        </div>
                    </>
                )}
            </div>

            {/*
            {mob915 === true ? (
                <div className={styles.pressets__wrapper_mob}>
                    <Swiper
                        direction={"vertical"}
                        slidesPerView={"auto"}
                        spaceBetween={0}
                        navigation={{
                            nextEl: `.next-pressets`,
                            prevEl: ".prev",
                            lockClass: "disabled",
                        }}
                        modules={[Navigation]}
                        className={styles.pressets}
                    >
                        {presets.map((item, index) => (
                            <SwiperSlide>
                                <GradientButton
                                    size={"xxl"}
                                    color={
                                        activePreset == item.id
                                            ? `${
                                                  mob915 === true
                                                      ? "mobPrimary"
                                                      : "primary"
                                              }`
                                            : ""
                                    }
                                    onClick={() => handleClick(item.id)}
                                >
                                    {item.name}
                                </GradientButton>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div className={`${styles.slider__next}`}>
                        <img
                            src={`${sliderArrow}`}
                            className={`next-pressets`}
                        />
                    </div>
                </div>
            ) : (
                <div className={styles.pressets}>
                    {presets.map((item, index) => (
                        <GradientButton
                            size={"xxl"}
                            color={
                                activePreset == item.id
                                    ? `${
                                          mob915 === true
                                              ? "mobPrimary"
                                              : "primary"
                                      }`
                                    : ""
                            }
                            onClick={() => handleClick(item.id)}
                        >
                            {item.name}
                        </GradientButton>
                    ))}
                </div>
            )} */}
            <div className={styles.effects__wrapper}>
                <div>
                    {currentEffectId === 'eq' && (
                        <FxEq
                            active={eqActive}
                            setActive={setEqActive}
                            band1={eqBand1}
                            setBand1={setEqBand1}
                            band2={eqBand2}
                            setBand2={setEqBand2}
                            band3={eqBand3}
                            setBand3={setEqBand3}
                            band4={eqBand4}
                            setBand4={setEqBand4}
                            band5={eqBand5}
                            setBand5={setEqBand5}
                            band6={eqBand6}
                            setBand6={setEqBand6}
                            band7={eqBand7}
                            setBand7={setEqBand7}
                            band8={eqBand8}
                            setBand8={setEqBand8}
                            onSubmit={mob915 ? handleOnSubmit : null}
                        />
                    )}


                    {currentEffectId === 'reverb' && (
                        <FxReverb
                            active={reverbActive}
                            setActive={setReverbActive}
                            mix={reverbMix}
                            setMix={setReverbMix}
                            pre={reverbPre}
                            setPre={setReverbPre}
                            el={reverbEl}
                            setEl={setReverbEl}
                            decay={reverbDecay}
                            setDecay={setReverbDecay}
                            size={reverbSize}
                            setSize={setReverbSize}
                            onSubmit={mob915 ? handleOnSubmit : null}
                        />
                    )}

                    {currentEffectId === 'chorus' && (
                        <FxChorus
                            active={chorusActive}
                            setActive={setChorusActive}
                            width={chorusWidth}
                            mix={chorusMix}
                            setMix={setChorusMix}
                            setWidth={setChorusWidth}
                            onSubmit={mob915 ? handleOnSubmit : null}
                        />
                    )}

                    {currentEffectId === 'form' && (
                        <FxForm
                            active={formantActive}
                            setActive={setFormantActive}
                            pitch={formantPitch}
                            setPitch={setFormantPitch}
                            formant={formantFormant}
                            setFormant={setFormantFormant}
                            onSubmit={mob915 ? handleOnSubmit : null}
                        />
                    )}

                    {currentEffectId === 'delay' && (
                        <FxDelay
                            active={delayActive}
                            setActive={setDelayActive}
                            feedback={delayFeedback}
                            setFeedback={setDelayFeedback}
                            time={delayTime}
                            setTime={setDelayTime}
                            effect={delayEffect}
                            setEffect={setDelayEffect}
                            onSubmit={mob915 ? handleOnSubmit : null}
                        />
                    )}

                    {currentEffectId === 'snap' && (
                        <FxSnap
                            active={snapActive}
                            setActive={setSnapActive}
                            speed={snapSpeed}
                            setSpeed={setSnapSpeed}
                            amount={snapAmount}
                            setAmount={setSnapAmount}
                            onSubmit={mob915 ? handleOnSubmit : null}
                        />
                    )}

                    {currentEffectId === 'normalize' && (
                        <FxNormalize
                            active={normalizeActive}
                            setActive={setNormalizeActive}
                            gain={normalizeGain}
                            setGain={setNormalizeGain}
                            onSubmit={mob915 ? handleOnSubmit : null}
                        />
                    )}

                    {currentEffectId === 'comp' && (
                        <FxComp
                            active={compActive}
                            setActive={setCompActive}
                            softness={compSoftness}
                            setSoftness={setCompSoftness}
                            onSubmit={mob915 ? handleOnSubmit : null}
                        />
                    )}
                </div>
            </div>
            <div className={styles.actions}>

                {mob915 !== true && (
                    <>
                        <GradientButton size={"modal"} onClick={() => setMixerIsActive(false)}>
                            Закрыть
                        </GradientButton>

                        <GradientButton size={"modal"} color={"violet"} onClick={handleOnSubmit}>
                            Применить
                        </GradientButton>
                    </>
                )}
            </div>
        </div>
    );
}
