import React, {useState} from "react";
import useMediaQuery from "../../../utils/hooks/common/useMediaQuery";
import EffectsBody from "../../EffectsBody/EffectsBody";
import Slider from "../../Slider/Slider";
import styles from "./fxNormalize.module.scss";

export default function FxNormalize({ active, setActive, gain, setGain, onSubmit }) {
    const mob915 = useMediaQuery(915);

    const gainVariants = [
        { number: "24" },
        { number: "20" },
        { number: "16" },
        { number: "12" },
        { number: "7" },
    ];

    const changeGain = (e) => {
        setGain(e.target.value);
    }

    return (
        <EffectsBody
            color={"violet"}
            title={"Normalize"}
            thumbColor={"violet"}
            width={mob915 !== true && "sm"}
            active={active}
            setActive={setActive}
            buttonsColor={"violetRevert"}
            onSubmit={onSubmit}
        >
            <div className={styles.slider}>
                <Slider number={gainVariants} color={1} min={7} max={24} step={1} value={gain} setValue={changeGain} footer={"Gain"} colorInput={"violet"} />
            </div>
        </EffectsBody>
    );
}
