
import React, {useRef} from 'react';
import styles from './cookiesConsent.module.scss';
import GradientButton from "../UI/GradientButton/GradientButton";

export default function CookiesConsent()
{
    const consentRef = useRef(null);
    const cookiesAllowed = localStorage.getItem('cookiesAllowed') || false;

    if (!cookiesAllowed && consentRef.current) {
        consentRef.current.style.display = 'block';
    }

    const handleConsent = () => {
        consentRef.current.style.display = 'none';
        localStorage.setItem('cookiesAllowed', true);
    }

    return (
        <div ref={consentRef} className={styles.cookiesWrap}>
            <div className={styles.cookiesConsent}>
                <div className={styles.cookiesConsent__text}>
                    Данный сайт использует cookie-файлы для хранения информации на персональном устройстве пользователя. Некоторые из этих файлов необходимы для работы нашего сайта, другие позволяют улучшить пользовательский интерфейс и выявить недостатки работы сервисов сайта. Пользование сайтом означает согласие на хранение cookie-файлов. Просим внимательно ознакомиться с <a target="_blank" href="https://muznavigator.com/rules-secure/">Правилами защиты информации о пользователях</a> и <a target="_blank" href="https://muznavigator.com/polzovatelskoe-soglashenie/">Пользовательским соглашением</a>.
                </div>
                <div className={styles.cookiesConsent__btn}>
                    <GradientButton size={"modal"} color={"violet"} onClick={handleConsent}>
                        Принять
                    </GradientButton>
                </div>
            </div>
        </div>
    )
}