import React, { useEffect, useState, useRef, useContext } from "react";
import { Tooltip } from 'react-tooltip'
import cn from "classnames";
import Metronome from "../Metronome/Metronome";

import GradientButton from "../UI/GradientButton/GradientButton";
import useMediaQuery from "../../utils/hooks/common/useMediaQuery";
import Context from "../../context/Context";

import styles from "./controlpanel.module.scss";

import { ReactComponent as CursorIcon } from "../../assets/icons/cursor_icon.svg";
import { ReactComponent as Stop } from "../../assets/icons/stop.svg";
import { ReactComponent as Play } from "../../assets/icons/play.svg";
import { ReactComponent as Copy } from "../../assets/icons/copy.svg";
import { ReactComponent as MetronomeIcon } from "../../assets/icons/metronome.svg";
import { ReactComponent as SyncIcon } from "../../assets/icons/sync.svg";
import { ReactComponent as Delete } from "../../assets/icons/delete.svg";
import { ReactComponent as Cut } from "../../assets/icons/cut.svg";
import { ReactComponent as Plus } from "../../assets/icons/plus.svg";
import { ReactComponent as Minus } from "../../assets/icons/minus.svg";
import { ReactComponent as Replay } from "../../assets/icons/replay.svg";
import { ReactComponent as MicrophoneIcon } from "../../assets/icons/microphone.svg";
import { ReactComponent as TextIcon } from "../../assets/icons/text_icon.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";

import click1 from '../../assets/metronome_sounds/click1.wav';
import click2 from '../../assets/metronome_sounds/click2.wav';
import useTrackHistory from "../../utils/hooks/project/useTrackHistory";
import TempoControl from "../ControlPanelFeatures/TempoControl/TempoControl";
import Synchronizer from "../Synchronizer/Synchronizer";


export default function ControlPanel({ hide, playRecording, showMicrophoneModal})
{
    const {
        metronomeVolume,
        setMetronomeVolume,
        isMetronomeEnabled,
        setIsMetronomeEnabled,
        activeCursor,
        changeActiveCursor,
        isPlaying,
        isNeedToProcess,
        prepareToPlay,
        history,
        currentHistoryIndex,
        undoHandler,
        redoHandler,
        currentAudioFragment
    } = useContext(Context)

    const [showMetronome, setShowMetronome] = useState(false);
    const [showSynchronizer, setShowSynchronizer] = useState(false);

    const handleVolumeChange = (event) => {
          const newVolume = event.target.value / 100;
          setMetronomeVolume(newVolume);
    };

    const handlePlayPause = (e) => {
        playRecording();
        document.body.focus();
    }

    const handleMetronomeClick = () => {
        setShowSynchronizer(false)
        setShowMetronome(!showMetronome);
    };

    const handleSynchronizerClick = () => {
        setShowMetronome(false)

        if (currentAudioFragment) {
            setShowSynchronizer(!showSynchronizer);
        }
    };

    const handleMicrophoneClick = () => {
        showMicrophoneModal();
    }

    useEffect(() => {
        if (!currentAudioFragment) {
            setShowSynchronizer(false);
        }
    }, [currentAudioFragment]);

    if (hide) {
        return null;
    }

    return (
        <>
            {showMetronome && <Metronome isEnabled={isMetronomeEnabled} handleEnabledChange={() => setIsMetronomeEnabled(!isMetronomeEnabled)} volume={metronomeVolume} handleVolumeChange={handleVolumeChange} />}
            {showSynchronizer && <Synchronizer /> }

            <div className={styles.control__wrapper}>
                <div className={styles.first__section}>
                    <div>
                        <GradientButton data-tooltip-id="my-tooltip-play" img={true} color={isPlaying ? "primary" : ""} onClick={handlePlayPause} >
                            <div className={cn(styles.img, styles['play'], isPlaying && styles[`play_active`])}>
                                {isPlaying ? (<Stop />) : (<Play />)}
                            </div>
                        </GradientButton>

                        <GradientButton data-tooltip-id="my-tooltip-mic" img={true} color={""} onClick={handleMicrophoneClick}>
                            <div className={cn(styles.img, styles['microphone'])}>
                                <MicrophoneIcon />
                            </div>
                        </GradientButton>

                        <GradientButton data-tooltip-id="my-tooltip-metronome" img={true} color={""} onClick={handleMetronomeClick}>
                            <div className={cn(styles.img, styles['metronome'])}>
                                <MetronomeIcon />
                            </div>
                        </GradientButton>

                        <GradientButton data-tooltip-id="my-tooltip-sync" img={true} color={""} disabled={!currentAudioFragment} onClick={handleSynchronizerClick}>
                            <div className={cn(styles.img, styles['sync'])}>
                                <SyncIcon />
                            </div>
                        </GradientButton>
                    </div>
                    <div>
                        <GradientButton data-tooltip-id="my-tooltip-cursor-default" disabled={isPlaying} img={true} color={activeCursor === 'default' ? "primary" : ""} onClick={() => changeActiveCursor('default')}>
                            <div className={cn(styles.img, styles['default'], activeCursor === 'default' || isPlaying ? styles[`default_active`] : null)}>
                                <CursorIcon />
                            </div>
                        </GradientButton>

                        <GradientButton data-tooltip-id="my-tooltip-cursor-cut" disabled={isPlaying} img={true} color={activeCursor === 'cut' ? "primary" : ""} onClick={() => changeActiveCursor('cut')}>
                            <div className={cn(styles.img, styles['cut'], activeCursor === 'cut' || isPlaying ? styles[`cut_active`] : null)}>
                                <Cut />
                            </div>
                        </GradientButton>

                        <GradientButton data-tooltip-id="my-tooltip-cursor-copy" disabled={isPlaying} img={true} color={activeCursor === 'copy' ? "primary" : ""} onClick={() => changeActiveCursor('copy')}>
                            <div className={cn(styles.img, styles['copy'], activeCursor === 'copy' || isPlaying ? styles[`copy_active`] : null)}>
                                <Copy />
                            </div>
                        </GradientButton>

                        <GradientButton data-tooltip-id="my-tooltip-cursor-delete" disabled={isPlaying} img={true} color={activeCursor === 'delete' ? "primary" : ""} onClick={() => changeActiveCursor('delete')}>
                            <div className={cn(styles.img, styles['delete'], activeCursor === 'delete' || isPlaying ? styles[`delete_active`] : null)}>
                                <Delete />
                            </div>
                        </GradientButton>

                    </div>
                    <div>
                        <GradientButton data-tooltip-id="my-tooltip-undo" img={true} color={""} onClick={undoHandler} disabled={currentHistoryIndex <= 0 || isPlaying}>
                            <div className={cn(styles.img, styles['prev'])}>
                                <Replay />
                            </div>
                        </GradientButton>

                        <GradientButton data-tooltip-id="my-tooltip-redo" img={true} color={""} onClick={redoHandler} disabled={currentHistoryIndex === history.length - 1 || isPlaying}>
                            <div className={cn(styles.img, styles['next'])}>
                                <Replay />
                            </div>
                        </GradientButton>
                    </div>
                </div>
                <TempoControl />
            </div>

            <Tooltip
                id="my-tooltip-play"
                place="top"
                content="Нажмите для воспроизведения / паузы"
            />

            <Tooltip
                id="my-tooltip-text"
                place="top"
                content="Текст песни"
            />

            <Tooltip
                id="my-tooltip-mic"
                place="top"
                content="Настройки микрофона"
            />

            <Tooltip
                id="my-tooltip-metronome"
                place="top"
                content="Настройки метронома"
            />

            <Tooltip
                id="my-tooltip-cursor-default"
                place="top"
                content="Выбор / перемещение"
            />

            <Tooltip
                id="my-tooltip-cursor-cut"
                place="top"
                content="Разрезание"
            />

            <Tooltip
                id="my-tooltip-cursor-copy"
                place="top"
                content="Копирование"
            />

            <Tooltip
                id="my-tooltip-cursor-paste"
                place="top"
                content="Вставка"
            />

            <Tooltip
                id="my-tooltip-cursor-delete"
                place="top"
                content="Удаление"
            />

            <Tooltip
                id="my-tooltip-undo"
                place="top"
                content="Отменить действие"
            />

            <Tooltip
                id="my-tooltip-redo"
                place="top"
                content="Повторить отмененное действие"
            />

            <Tooltip
                id="my-tooltip-sync"
                place="top"
                content="Синхронизация вокала и минуса"
            />
        </>
    );
}
