import {useEffect, useRef} from 'react'
import useState from 'react-usestateref';

import metronomeClickPrimary from '../../../assets/metronome_sounds/click2.wav';
import metronomeClickSecondary from '../../../assets/metronome_sounds/click1.wav';

const useMetronome = () =>
{
    const [count, setCount, countRef] = useState(0);
    const [beatsPerMeasure, setBeatsPerMeasure] = useState(4);

    const metronomeClickPrimaryRef = useRef(null)
    const metronomeClickSecondaryRef = useRef(null)
    const timerRef = useRef(null)

    useEffect(() => {
        metronomeClickPrimaryRef.current = new Audio(metronomeClickPrimary);
        metronomeClickSecondaryRef.current = new Audio(metronomeClickSecondary);
    }, []);

    const start = (bpm, volume, startTime) => {
        if (bpm <= 0) bpm = 120;

        metronomeClickPrimaryRef.current.volume = volume;
        metronomeClickSecondaryRef.current.volume = volume;

        if (startTime > 0) {
            // calculate how many beats have passed since track startTime and delay the first click
            const beatsPassed = Math.floor(startTime / (60 / bpm));

            setCount(beatsPassed % beatsPerMeasure);

            const delay = (60 / bpm) - (startTime % (60 / bpm));

            // delay the first click
            setTimeout(() => {
                timerRef.current = setInterval(playClick, (60 / bpm) * 1000);
            }, delay * 1000);

        } else {
            setCount(0);
            timerRef.current = setInterval(playClick, (60 / bpm) * 1000);
        }
    }

    const playClick = () => {
        if (countRef.current % beatsPerMeasure === 0) {
            metronomeClickPrimaryRef.current.play();
        } else {
            metronomeClickSecondaryRef.current.play();
        }

        setCount((countRef.current + 1) % beatsPerMeasure);
    }

    const stop = () => {
        clearInterval(timerRef.current);
    }

    return [start, stop]
}

export default useMetronome;