import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import GradientButton from "../UI/GradientButton/GradientButton";
import sliderArrow from "../../assets/icons/sliderArrow.svg";
import { Navigation } from "swiper";
import Checkbox from "../UI/Checkbox/Checkbox";
import styles from "./mobileSliderCarousel.module.scss";
import cn from "classnames";

export default function MobileSliderCarousel({
    effects,
    color,
    id,
    header,
    effectsItems,
    headerStyle,
    onClick,
    disabled
}) {

    //console.log(effectsItems);

    return (
        <>
            <div className={`${styles.slider__prev}`}>
                <img src={`${sliderArrow}`} className={`prev-${id}`} />
            </div>
            <Swiper
                spaceBetween={4}
                slidesPerView={"auto"}
                // className={styles.pressets}
                navigation={{
                    nextEl: `.next-${id}`,
                    prevEl: `.prev-${id}`,
                    lockClass: "disabled",
                }}
                modules={[Navigation]}
                className={cn(styles[headerStyle], disabled && styles.disabled)}
            >
                {header === true ? (
                    <>
                        {effectsItems.map((item, i) => (
                            <SwiperSlide key={item.id} style={{ width: "auto" }} onClick={() => onClick(item.id)}>
                                <Checkbox style={`${item.color}`} isChecked={item.active}>
                                    {item.title}
                                </Checkbox>
                            </SwiperSlide>
                        ))}
                    </>
                ) : (
                    <>
                        {effects.map((item, i) => (
                            <SwiperSlide key={item.id} style={{ width: "auto" }}>
                                <GradientButton
                                    fs={"xs"}
                                    color={item.active ? color : ""}
                                >
                                    {item.title}
                                </GradientButton>
                            </SwiperSlide>
                        ))}
                    </>
                )}
            </Swiper>
            <div className={`${styles.slider__next}`}>
                <img src={`${sliderArrow}`} className={`next-${id}`} />
            </div>
        </>
    );
}
