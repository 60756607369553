import useState from 'react-usestateref';

/**
 * Track history hook
 *
 * @param init
 * @returns {(*[]|number|add|undo|redo)[]}
 */
const useTrackHistory = (init) =>
{
    const [history, setHistory, historyRef] = useState([init])
    const [currentIndex, setCurrentIndex, currentIndexRef] = useState(0)

    /**
     * Add a new history to the history array
     * @param data
     */
    const add = (data) => {
        if (currentIndexRef.current < historyRef.current.length - 1) {
            // Replace the current history with the new one

            /*setHistory([
                ...historyRef.current.slice(0, currentIndexRef.current + 1),
                data
            ]);
            setCurrentIndex((prevIndex) => prevIndex + 1);*/
        } else {
            // Add the new history to the end

            //setHistory((prevHistory) => [...prevHistory, data]);
            setHistory((prevHistory) => [...prevHistory, data]);
            setCurrentIndex((prevIndex) => prevIndex + 1);
        }
    }

    /**
     * Undo the last history
     */
    const undo = () => {
        setCurrentIndex((prevIndex) => prevIndex - 1)
    }

    /**
     * Redo the last history
     */
    const redo = () => {
        setCurrentIndex((prevIndex) => prevIndex + 1)
    }

    /**
     * Clear the history
     */
    const clear = () => {
        setHistory([init]);
        setCurrentIndex(0);
    }

    return [historyRef.current, currentIndexRef.current, add, undo, redo, clear]
}

export default useTrackHistory;