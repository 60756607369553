export const mockAudioItems = [
    {
        id: 1,
        title: "minus",
        recButton: false,
        uploadButton: true,
        style: "blue",
        pan: false,
        mute: false,
        containerBg: "linear-gradient(#36b4ff, #0099f1)",
        volume: 1,
        balance: 0,
        audio: '',
        samples: [],
        FX: {},
        bpm: 0,
        preset: "",
        key: "",
        minus: true
    },
    {
        id: 2,
        title: "#1",
        recButton: true,
        style: "orange",
        pan: true,
        mute: false,
        containerBg: "linear-gradient(#fd7f4d, #d95725)",
        volume: 0.5,
        balance: 0,
        audio: '',
        samples: [],
        FX: {},
        bpm: 0,
        preset: "",
        minus: false
    },
    {
        id: 3,
        title: "#2",
        recActive: "recActive",
        recButton: true,
        style: "green",
        pan: true,
        mute: false,
        containerBg: "linear-gradient(#00c963, #009449)",
        volume: 0.5,
        balance: 0,
        audio: '',
        samples: [],
        FX: {},
        bpm: 0,
        preset: "",
        minus: false
    },
    {
        id: 4,
        title: "#3",
        recActive: "recActive",
        recButton: true,
        style: "purple",
        pan: true,
        mute: false,
        containerBg: "linear-gradient(#9443ff, #7b1bf7)",
        volume: 0.5,
        balance: 0,
        audio: '',
        samples: [],
        FX: {},
        bpm: 0,
        preset: "",
        minus: false
    },
    {
        id: 5,
        title: "#4",
        recActive: "recActive",
        recButton: true,
        style: "yellow",
        pan: true,
        mute: false,
        containerBg: "linear-gradient(#d1e400, #aea704)",
        volume: 0.5,
        balance: 0,
        audio: '',
        samples: [],
        FX: {},
        bpm: 0,
        preset: "",
        minus: false
    },
    {
        id: 6,
        title: "#5",
        recActive: "recActive",
        recButton: true,
        style: "brown",
        pan: true,
        mute: false,
        containerBg: "linear-gradient(#833212, #5a1a00)",
        volume: 0.5,
        balance: 0,
        audio: '',
        samples: [],
        FX: {},
        bpm: 0,
        preset: "",
        minus: false
    },
    {
        id: 7,
        title: "#6",
        recActive: "recActive",
        recButton: true,
        style: "pink",
        pan: true,
        mute: false,
        containerBg: "linear-gradient(#f7a4a4, #f57c84)",
        volume: 0.5,
        balance: 0,
        audio: '',
        samples: [],
        FX: {},
        bpm: 0,
        preset: "",
        minus: false
    },
    {
        id: 8,
        title: "#7",
        recActive: "recActive",
        recButton: true,
        style: "red",
        pan: true,
        mute: false,
        containerBg: "linear-gradient(#ff0000, #c90000)",
        volume: 0.5,
        balance: 0,
        audio: '',
        samples: [],
        FX: {},
        bpm: 0,
        preset: "",
        minus: false
    }
]