import React, {useState, useEffect, useRef} from 'react'
import { toast } from "react-toastify"
import {useSelector, useDispatch} from "react-redux";

import {login, reset} from "../../../features/auth/authSlice";
import styles from "./modalLogin.module.scss";
import GradientButton from "../../UI/GradientButton/GradientButton";

export default function ModalLogin({ visible, onClose })
{
    const dispatch = useDispatch()
    const {user, isError, isLoading, isSuccess, message} = useSelector((state) => state.auth)

    const formRef = useRef()

    const [formData, setFormData] = useState({
        username: '',
        password: '',
    });

    const {
        username,
        password,
    } = formData;

    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const saveHandler = () => {
        const userData = {
            username,
            password,
        }
        dispatch(login(userData))
    }

    const submitHandler = (e) => {
        alert(123)
        e.preventDefault()

        const userData = {
            username,
            password,
        }
        dispatch(login(userData))
    }


    useEffect(() => {
        if (isError) {
            toast.error(message)
        }

        dispatch(reset())
    }, [isError, isSuccess, user, message, dispatch])


    return (
        <>
            <div
                className={styles.modal__background}
                style={
                    !visible ? { display: "none" } : { display: "flex" }
                }
            ></div>
            <div
                className={styles.wrapper}
                style={
                    !visible ? { display: "none" } : { display: "flex" }
                }
            >
                <div className={styles.container}>
                    <div className={styles.body}>
                        <p>Авторизация</p>
                        <p>Для дальнейшего использования приложения необходимо авторизоваться,<br />
                            используя данные с сайта <a target="_blank" href={"https://muznavigator.com"}>muznavigator.com</a></p>
                    </div>
                    <div className={styles.formWrapper}>

                        <form ref={formRef} onSubmit={submitHandler}>
                            <div className={styles.form_group}>
                                <label htmlFor="login">
                                    Логин:
                                </label>
                                <input
                                    type="text" name="username" id="username" required
                                    placeholder="" value={username} onChange={onChange} />
                            </div>

                            <div className={styles.form_group}>
                                <label htmlFor="password">
                                    Пароль:
                                </label>
                                <input
                                    type="password" name="password" id="password" required
                                    placeholder="" value={password} onChange={onChange} />
                            </div>
                        </form>
                    </div>
                    <div className={styles.footer}>
                        <GradientButton size={"modal"} color={"violet"} onClick={saveHandler}>
                            Войти
                        </GradientButton>
                        <GradientButton size={"modal"} onClick={onClose}>
                            Отмена
                        </GradientButton>
                    </div>
                </div>
            </div>
        </>
    );
}