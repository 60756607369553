import React, { useContext, useState } from 'react';
import styles from "./metronome.module.scss";
import { IoVolumeMediumSharp, IoVolumeHigh } from "react-icons/io5";
import Context from '../../context/Context';
 
export default function Metronome ({ isEnabled, handleEnabledChange, volume, handleVolumeChange })
{
  return (
    <div className={styles.container}>
      <div className={styles.metronome}>
        <div className={styles.metronome__toggle}>
          <input type="checkbox" checked={isEnabled} onChange={handleEnabledChange}/>
          <label htmlFor="" className={styles.onBtn}>on</label>
          <label htmlFor="" className={styles.offBtn}>off</label>
        </div>
        <div className={styles.metronome__volume}>
          <IoVolumeMediumSharp />
            <input
              type="range"
              name="volume_metronome"
              style={{background: `linear-gradient(to right, #0099f1 0%, #0099f1 ${volume * 100}%, #2f2f2f ${volume * 100}%, #2f2f2f 100%)`}}
              min="0"
              max="100"
              value={volume * 100}
              onChange={handleVolumeChange}
            />
          <IoVolumeHigh />
        </div>
      </div>
    </div>
  )
}