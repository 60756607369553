import React, {useContext, useEffect, useRef, useState} from "react";
import GradientButton from "../UI/GradientButton/GradientButton";
import { ReactComponent as Equalizer } from "../../assets/icons/equalizer.svg";
import { ReactComponent as Download } from "../../assets/icons/download.svg";
import { ReactComponent as Logout } from "../../assets/icons/logout.svg";
import { ReactComponent as Folder } from "../../assets/icons/folder.svg";
import { ReactComponent as Add } from "../../assets/icons/add.svg";
import { ReactComponent as Help } from "../../assets/icons/help.svg";
import "swiper/css";
import useMediaQuery from "../../utils/hooks/common/useMediaQuery";
import MobileSliderCarousel from "../MobileSliderCarousel/MobileSliderCarousel";
import styles from "./header.module.scss";
import context from "../../context/Context";
import Spacer from "../UI/Spacer/Spacer";
import ContextMenu from "../UI/ContextMenu/ContextMenu";
import { createProject, mix, updateProject, getProjectData } from "../../api/api";
import {useDispatch, useSelector} from "react-redux";

import {logout, reset} from "../../features/auth/authSlice";
import {toast} from "react-toastify";
import ModalProjectForm from "../Modals/ModalProjectForm/modalProjectForm";
import ModalSave from "../Modal/Modals/ModalSave";
import ModalProjectsList from "../Modals/ModalProjectsList/modalProjectsList";
import ModalLoad from "../Modal/Modals/ModalLoad";
import ModalText from "../Modals/ModalText/modalText";
import useAuthStatus from "../../utils/hooks/common/useAuthStatus";
import ModalBuyTrack from "../Modals/ModalBuyTrack/ModalBuyTrack";
import ModalProcessing from "../Modal/Modals/ModalProcessing";
import {Tooltip} from "react-tooltip";
import ModalHelp from "../Modals/ModalHelp/modalHelp";

export default function Header({ mixerIsActive, setMixerIsActive }) {
    const mob915 = useMediaQuery(915);

    const dispatch = useDispatch()
    const user = useSelector((state) => state.auth.user)
    const {loggedIn, checkingStatus} = useAuthStatus()

    const {
        currentAudioTrack,
        getAudioById,
        presets,
        setCurrentPresetId,
        setCurrentEffectId,
        currentEffectId,
        currentPresetId,
        updateAudioById,
        prepareToPlay,
        setIsNeedToProcess,
        isNeedToProcess,
        mockAudioData,
        addToHistoryHandler,
        projectName,
        projectId,
        setProjectName,
        setProjectId,
        projectDate,
        setProjectDate,
        setIsMainFocused,
        clearTimeline,
        projectText,
        projectTrackId,
        setProjectText,
        setIsLoginModalVisible,
        setProjectTrackId,

        setMinusTempo,
        initAudioPlayer,
        helpIsRead
    } = useContext(context)

    const headerItems = [
        { title: "Название трека, присвсвсsdsdsdsdsdsd", id: "153120" },
    ];

    const audioTrack = useRef();

    const [currentAudioTrackData, setCurrentAudioTrackData] = useState(null);
    const [isExportProcessModalVisible, setIsExportProcessModalVisible] = useState(false);
    const [isEffectsMenuVisible, setIsEffectsMenuVisible] = useState(false);
    const [isPresetsMenuVisible, setIsPresetsMenuVisible] = useState(false);
    const [isExportVisible, setIsExportVisible] = useState(false);

    const [isModalProjectFormVisible, setIsModalProjectFormVisible] = useState(false);
    const [isSavingModalVisible, setIsSavingModalVisible] = useState(false);
    const [isLoadingModalVisible, setIsLoadingModalVisible] = useState(false);

    const [isModalProjectsListVisible, setIsModalProjectsListVisible] = useState(false);

    const [effectsItems, setEffectsItems] = useState( [
        { title: "EQ", color: "primary", id: "eq", active: false },
        { title: "Reverb", color: "orange", id: "reverb", active: false },
        { title: "Chorus", color: "green", id: "chorus", active: false },
        { title: "Formant", color: "orange", id: "form", active: false },
        { title: "Delay", color: "violet", id: "delay", active: false },
        { title: "Autotune", color: "yellow", id: "snap", active: false },
        { title: "Compressor", color: "primary", id: "comp", active: false },
        { title: "Normalize", color: "violet", id: "normalize", active: false },
    ]);

    const [activeEffectsItems, setActiveEffectsItems] = useState([]);

    const [presetsItems, setPresetsItems] = useState([
        {
            "id": "",
            "name": "Нет"
        }
    ]);

    const [selectedEffectsIds, setSelectedEffectsIds] = useState([]);


    const exportItems = [
        { id: "session", title: "Сохранить сессию" },
        { id: "track", title: "Сохранить трек"},
        //{ id: "session_and_track", title: "Сохранить сессию и трек" },
    ];

    const [isPrepareToExport, setIsPrepareToExport] = useState(false);
    const [exportLink, setExportLink] = useState("");
    const [exportName, setExportName] = useState("");
    const exportLinkRef = useRef();

    const [isModalBuyTrackVisible, setIsModalBuyTrackVisible] = useState(false);

    const [isHelpModalVisible, setIsHelpModalVisible] = useState(false);

    const handleHelp = () => {
        setIsHelpModalVisible(true);
    }

    const handleHelpClose = () => {
        localStorage.setItem('helpIsRead', true);

        setIsHelpModalVisible(false);
    }

    const handleLogout = () =>
    {
        if (!loggedIn) {
            setIsLoginModalVisible(true);
            return false;
        }

        dispatch(logout())
        dispatch(reset())
    }

    const prepareTracksData = () => {
        let tracksData = [];

        mockAudioData.forEach((track) => {

            let localTrackData = {
                title: track.title,
                audio: track.audio,
                bpm: track.bpm,
                key: track.key,
                volume: track.volume,
                balance: track.balance,
                position: track.id,
                color: track.style,
                type: track.minus ? 'minus' : 'vocal',
                muted: track.mute,
                fx: track.FX,
                preset: track.preset,
                samples: []
            }

            if (track.samples && track.samples.length) {
                track.samples.forEach((sample) => {
                    localTrackData.samples.push({
                        title: sample.title,
                        audio: sample.audio,
                        start: sample.start,
                        duration: sample.duration,
                        fadeIn: sample.fadeIn,
                        fadeOut: sample.fadeOut
                    });
                });
            }

            tracksData.push(localTrackData);

        });

        return tracksData;
    }

    const onExportClick = (id) => {

        setExportLink("");
        setExportName("");

        switch (id) {
            case "session":
                if (!loggedIn) {
                    setIsLoginModalVisible(true);
                    return false;
                }

                onSaveSessionClick();

                break;
            case "track":
                onExportTrackClick();

                break;
            case "session_and_track":
                onSaveSessionClick();
                onExportTrackClick();

                break;
        }
    }

    const onExportTrackClick = () => {

        if (isNeedToProcess) {
            setIsPrepareToExport(true);
            prepareToPlay();
        } else {
            exportMp3File();
            setIsExportVisible(false);
        }
    }

    const onSaveSessionClick = () => {
        setIsExportVisible(false);
        const tracksData = prepareTracksData();

        if (projectId) {
            setIsSavingModalVisible(true);

            // update project
            updateProject(user.token, projectId, {
                title: projectName,
                text: projectText,
                tracks: tracksData,
                trackId: projectTrackId

            }, function (response) {
                toast.success("Сессия сохранена");
                setIsSavingModalVisible(false);
            }, function (error) {
                toast.error("Ошибка сохранения сессии");
                setIsSavingModalVisible(false);
            });

        } else {
            setIsModalProjectFormVisible(true);
            setIsMainFocused(false);
        }
    }

    const onSubmit = (formData) => {
        setIsSavingModalVisible(true);

        const tracksData = prepareTracksData();

        createProject(user.token, {
            title: formData.title,
            text: projectText,
            tracks: tracksData,
            trackId: projectTrackId
        }, function (response) {
            toast.success("Сессия сохранена");

            setProjectId(response.data._id);
            setProjectName(response.data.title);
            setProjectDate(response.data.updatedAt);

            setIsSavingModalVisible(false);
            setIsMainFocused(true);
        }, function (error) {
            toast.error("Ошибка сохранения сессии");

            setIsSavingModalVisible(false);
            setIsMainFocused(true);
        });
    }

    const onClose = () => {
        setIsModalProjectFormVisible(false);
        setIsMainFocused(true);
    }

    const onModalProjectsListClose = () => {
        setIsModalProjectsListVisible(false);
        setIsMainFocused(true);
    }

    const onModalProjectsListLoad = (id) => {
        setIsLoadingModalVisible(true);

        getProjectData(user.token, id, function (response) {

            const project = response.data;

            // Set project meta-data
            setProjectId(project._id);
            setProjectName(project.title);
            setProjectText(project.text);
            setProjectTrackId(project.trackId);
            setProjectDate(project.updatedAt);

            // Clear current tracks
            clearTimeline();

            // Add data from file
            if (project.tracks && project.tracks.length) {
                project.tracks.forEach((track) => {
                    let localTrackData = getAudioById(track.position);
                    localTrackData.audio = track.audio;
                    localTrackData.bpm = track.bpm;
                    localTrackData.key = track.key;
                    localTrackData.volume = track.volume;
                    localTrackData.balance = track.balance;
                    localTrackData.style = track.color;
                    localTrackData.minus = track.type === 'minus';
                    localTrackData.mute = track.isMuted;
                    localTrackData.FX = track.fx ? track.fx : {};
                    localTrackData.preset = track.preset;

                    let samples = [];

                    if (track.samples && track.samples.length) {

                        track.samples.forEach((sample) => {
                            samples.push({
                                id: sample._id,
                                title: sample.title,
                                audio: sample.audio,
                                mp3: sample.audio,
                                start: sample.start,
                                duration: sample.duration,
                                fadeIn: sample.fadeIn ? sample.fadeIn : 0,
                                fadeOut: sample.fadeOut ? sample.fadeOut : 0,
                                minus: track.position != '1' ? false : true
                            });
                        });
                    }

                    localTrackData.samples = samples;
                    updateAudioById(track.position, localTrackData);
                });

                setMinusTempo()
                initAudioPlayer();

                setTimeout(() => {
                    setIsLoadingModalVisible(false);
                }, 3000);
            }


        }, function (error) {
            console.error(error);

            setIsLoadingModalVisible(false);
        });
    }

    const onModalProjectsListShow = () => {
        if (!loggedIn) {
            setIsLoginModalVisible(true);
            return false;
        }

        setIsModalProjectsListVisible(true);
        setIsMainFocused(false);
    }


    const exportMp3File = () => {
        setIsExportProcessModalVisible(true);
        const baseUrl = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:5001';

        let tracksToExport = [];

        mockAudioData.forEach((track) => {
            if (track.audio && !track.mute && track.volume > 0) {
                tracksToExport.push({
                    id: track.id,
                    fileName: track.audio,
                    volume: track.volume,
                    pan: track.balance
                });
            }
        });

        mix({
            tracks: tracksToExport,
            projectId: projectId,
            trackId: projectTrackId
        }, user?.token, function (response) {

            setIsExportProcessModalVisible(false);

            if (response.data.success) {
                setExportLink(baseUrl + "/" + response.data.file);
                setExportName("export.mp3");
            } else if (response.data.message === 'Track is not bought') {
                //toast.error("Для экспорта трека необходимо его купить");

                // Show modal buy track
                setIsModalBuyTrackVisible(true);

            } else {
                toast.error("Ошибка экспорта трека");
            }

        }, function (error) {
            console.error(error);
        });
    }

    useEffect(() => {
        if (isPrepareToExport) {
            setIsPrepareToExport(false);
            exportMp3File();
            setIsExportVisible(false);
        }
    }, [mockAudioData]);

    useEffect(() => {
        if (exportLink) {
            exportLinkRef.current.click();
        }
    }, [exportLink]);


    const handlerSelectPreset = (id) => {

        const audioTrackObject = getAudioById(currentAudioTrack);
        audioTrackObject.audio = '';

        setCurrentEffectId("");
        if (id === "") {
            // disable preset
            audioTrackObject.preset = "";
            audioTrackObject.FX = {};
        } else {
            // enable preset

            const preset = presets.filter(p => p.id === id)[0];

            audioTrackObject.FX = {};
            audioTrackObject.preset = preset.id;

            // EQ
            if (typeof preset.FX.eq !== "undefined" && preset.FX.eq) {
                audioTrackObject.FX.eq = {
                    active: true,
                    params: {
                        band_1_level: preset.FX.eq.band1,
                        band_2_level: preset.FX.eq.band2,
                        band_3_level: preset.FX.eq.band3,
                        band_4_level: preset.FX.eq.band4,
                        band_5_level: preset.FX.eq.band5,
                        band_6_level: preset.FX.eq.band6,
                        band_7_level: preset.FX.eq.band7,
                        band_8_level: preset.FX.eq.band8
                    }
                }
            }

            // CHORUS
            if (typeof preset.FX.chorus !== "undefined" && preset.FX.chorus) {
                audioTrackObject.FX.chorus = {
                    active: true,
                    params: {
                        mix: preset.FX.chorus.mix,
                        width: preset.FX.chorus.width
                    }
                }
            }

            // REVERB
            if (typeof preset.FX.reverb !== "undefined" && preset.FX.reverb) {
                audioTrackObject.FX.reverb = {
                    active: true,
                    params: {
                        mix: preset.FX.reverb.mix,
                        pre: preset.FX.reverb.pre,
                        decay: preset.FX.reverb.decay,
                        elMx: preset.FX.reverb.el,
                        size: preset.FX.reverb.size
                    }
                }
            }

            // FORMANT
            if (typeof preset.FX.form !== "undefined" && preset.FX.form) {
                audioTrackObject.FX.form = {
                    active: true,
                    params: {
                        pitch: preset.FX.form.pitch,
                        formant: preset.FX.form.formant
                    }
                }
            }

            // DELAY
            if (typeof preset.FX.delay !== "undefined" && preset.FX.delay) {
                audioTrackObject.FX.delay = {
                    active: true,
                    params: {
                        effect: preset.FX.delay.effect,
                        delay: preset.FX.delay.time,
                        feedback: preset.FX.delay.feedback
                    }
                }
            }

            // SNAP
            if (typeof preset.FX.snap !== "undefined" && preset.FX.snap) {
                audioTrackObject.FX.snap = {
                    active: true,
                    params: {
                        speed: preset.FX.snap.speed,
                        amount: preset.FX.snap.amount
                    }
                }
            }

            // COMPRESSOR
            if (typeof preset.FX.comp !== "undefined" && preset.FX.comp) {
                audioTrackObject.FX.comp = {
                    active: true
                }
            }
        }

        updateAudioById(currentAudioTrack, audioTrackObject)
        setIsNeedToProcess(true)
        prepareToPlay();

        setCurrentPresetId('');
        setCurrentEffectId('');

        addToHistoryHandler();
    }

    const handlerSelectEffect = (id) => {

        if (id === 'snap' && mockAudioData[0].key === '') {
            alert('Для использования эффекта Snap (автотюн) необходимо дождаться определения тональности минуса');

            return false;
        }

        setCurrentEffectId(id);
        setCurrentPresetId("");
        setMixerIsActive(true);
    }

    const handlerToggleEffectsMenu = () => {
        if (currentAudioTrack === null) return;

        if (currentAudioTrackData && currentAudioTrackData.minus === true) {
            return false;
        }

        setIsEffectsMenuVisible((prev) => !prev);
        setIsPresetsMenuVisible(false);
    }

    const handlerTogglePresetsMenu = () => {
        if (currentAudioTrack === null) return;

        if (currentAudioTrackData && currentAudioTrackData.minus === true) {
            return false;
        }

        setIsPresetsMenuVisible((prev) => !prev);
        setIsEffectsMenuVisible(false);
    }

    const handleNewProject = () => {
        window.open(window.location.origin, '_blank');
    }

    const onCloseModalExport = () => {
        setIsExportProcessModalVisible(false);
    }

    useEffect(() => {
        setPresetsItems([...presetsItems, ...presets]);
    }, [presets]);

    useEffect(() => {
        audioTrack.current = getAudioById(currentAudioTrack);

    }, [currentAudioTrack, isNeedToProcess]);

    useEffect(() => {
        if (currentAudioTrack) {
            const audioTrackObject = getAudioById(currentAudioTrack);

            setCurrentAudioTrackData(audioTrackObject);

            setEffectsItems((prev) => {
                return prev.map((item) => {
                    if (audioTrackObject && typeof audioTrackObject.FX[item.id] !== "undefined" && audioTrackObject.FX[item.id].active === true) {

                        return {
                            ...item,
                            active: true
                        }
                    } else {
                        return {
                            ...item,
                            active: false
                        }
                    }
                })
            })

            setSelectedEffectsIds(Object.keys(audioTrackObject.FX).filter((item) => audioTrackObject.FX[item].active === true));
        } else {
            setSelectedEffectsIds([]);
        }
    }, [currentAudioTrack, mixerIsActive, currentEffectId, currentPresetId, isNeedToProcess, mockAudioData]);

    useEffect(() => {
        setActiveEffectsItems(effectsItems.filter((item) => item.active === true))
    }, [effectsItems]);

    useEffect(() => {
        if (!helpIsRead) {
            handleHelp();
        }
    }, []);

    return (
        <header>
            <div className={styles.title}>

                {/*<div className={styles.title__image}>
                    <img
                        src="/assets/logo.png"
                        alt=""
                        style={{width: 27, height: 27}}
                    />
                </div>*/}
                <div className={styles.title__name}>
                    {headerItems.map((item, index) => (
                        <div key={item.id}>
                            <p>{projectName ? projectName : 'Новый проект...'}</p>
                            <p>{projectDate && new Date(projectDate).toLocaleString('ru-RU', {
                                    day: 'numeric',
                                    month: 'numeric',
                                    year: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                })}</p>
                        </div>
                    ))}
                </div>
            </div>

            <GradientButton
                onClick={onModalProjectsListShow}
                style={"onlyImg"}
                size={mob915 === true && "xl"}
                color={"violet"}
            >
                <div className={styles.pressets_svg}>
                    <Folder />
                </div>
            </GradientButton>

            <Spacer width={10} />

            <GradientButton
                onClick={handleNewProject}
                style={"onlyImg"}
                size={mob915 === true && "xl"}
                color={"violet"}
            >
                <div className={styles.pressets_svg}>
                    <Add />
                </div>
            </GradientButton>

            <Spacer width={20} />

            <div style={{display: 'flex', flexDirection: 'row'}}>
                {!mob915 && (
                    <>
                        <GradientButton
                            onClick={() => window.open('https://muznavigator.com')}
                            style={"withImg"}
                            size={mob915 === true && "xl"}
                            color={"green"}
                        >
                            <p>Каталог</p>

                        </GradientButton>
                        <Spacer width={10} />
                    </>
                )}



                <GradientButton
                    onClick={handlerTogglePresetsMenu}
                    style={"withImg"}
                    size={mob915 === true && "xl"}
                    color={"primary"}
                    disabled={currentAudioTrack === null || (currentAudioTrackData && currentAudioTrackData.minus === true)}
                >
                    {mob915 === true ? (<p>пресеты</p>) : (
                        <>
                            <p>пресеты</p>
                            <div className={styles.pressets_svg}>
                                <Equalizer />
                            </div>
                        </>
                    )}


                    <ContextMenu
                        visible={isPresetsMenuVisible}
                        items={presetsItems}
                        itemsActive={[audioTrack.current ? audioTrack.current.preset : null]}
                        left={true}
                        top={true}
                        onClick={handlerSelectPreset}
                    />
                </GradientButton>

                <Spacer width={10} />

                <GradientButton
                    onClick={handlerToggleEffectsMenu}
                    style={"withImg"}
                    size={mob915 === true && "xl"}
                    color={"orange"}
                    disabled={currentAudioTrack === null || (currentAudioTrackData && currentAudioTrackData.minus === true)}
                >
                    {mob915 === true ? (<p>эффекты</p>) : (
                        <>
                            <p>эффекты</p>
                            <div className={styles.pressets_svg}>
                                <Equalizer />
                            </div>
                        </>
                    )}

                    <ContextMenu
                        visible={isEffectsMenuVisible}
                        items={effectsItems}
                        itemsActive={selectedEffectsIds}
                        width={140}
                        left={true}
                        top={true}
                        onClick={handlerSelectEffect}
                    />
                </GradientButton>
            </div>

            <div className={styles.pressets}>
                <MobileSliderCarousel
                    id={"header"}
                    effectsItems={activeEffectsItems}
                    header={true}
                    headerStyle={"pressets"}
                    onClick={(id) => currentAudioTrack ? handlerSelectEffect(id) : null}
                    disabled={currentAudioTrack === null}
                />
            </div>

            <div className={styles.login}>
                <GradientButton
                    color={"primary"}
                    onClick={() => setIsExportVisible((prev) => !prev)}
                >
                    <div className={styles.login_svg}>
                        <Download />
                    </div>
                </GradientButton>

                <GradientButton
                    style={"withImg"}
                    size={mob915 === true && "xl"}
                    color={"violet"}
                    onClick={handleLogout}
                >
                    {mob915 === true ? "" : <p>{user ? user.username : 'Anonymous'}</p>}
                    <div className={styles.user_svg}>
                        <Logout />
                    </div>
                </GradientButton>

                <ContextMenu visible={isExportVisible} items={exportItems} onClick={onExportClick} left={true} top={true} />
                <a href={exportLink} download={exportName} target={"_blank"} ref={exportLinkRef} style={{ display: "none" }}></a>


                <GradientButton
                    color={"red"}
                    onClick={handleHelp}
                    data-tooltip-id="my-tooltip-help"
                >
                    <div className={styles.help_svg}>
                        <Help />
                    </div>
                </GradientButton>
            </div>

            <ModalProjectForm visible={isModalProjectFormVisible} onSubmit={onSubmit} onClose={onClose} />
            <ModalSave isVisible={isSavingModalVisible} />

            <ModalProjectsList currentProjectId={projectId} visible={isModalProjectsListVisible} onClose={onModalProjectsListClose} onLoad={onModalProjectsListLoad} />
            <ModalLoad isVisible={isLoadingModalVisible} />

            <ModalBuyTrack trackId={projectTrackId} visible={isModalBuyTrackVisible} onClose={() => setIsModalBuyTrackVisible(false)} />

            <ModalProcessing type="export" isVisible={isExportProcessModalVisible} onClose={onCloseModalExport} />

            <Tooltip
                id="my-tooltip-help"
                place="left"
                content="Инструкция"
            />

            <ModalHelp visible={isHelpModalVisible} onClose={handleHelpClose} />
        </header>
    );
}
