import React, {useRef, useEffect, useContext} from 'react'
import useState from 'react-usestateref'
import { useDrag } from 'react-dnd'
import WaveSurfer from 'wavesurfer.js'
import cn from 'classnames'
import styles from './audioTrackFragment.module.scss'
import Context from "../../context/Context";
import { cut } from "../../api/api";
import {toast} from "react-toastify";

import { ReactComponent as TriangleTopLeftIcon } from "../../assets/icons/triangle_top_left.svg";
import { ReactComponent as TriangleTopRightIcon } from "../../assets/icons/triangle_top_right.svg";
import useMediaQuery from "../../utils/hooks/common/useMediaQuery";

export default function AudioTrackFragment(
    {
        style,
        left,
        width,
        audio,
        mute,
        id,
        trackId,
        duration,
        fadeIn,
        fadeOut,
        setFadeIn,
        setFadeOut,
        isMinus
    })
{
    const mob = useMediaQuery(915);

    const top = 0;

    const maxFadeInOut = duration < 10 ? duration / 2 : 3;

    const { currentAudioFragment, activeCursor, gridVariantsItems, gridVariant } = useContext(Context);

    const gridOptions = gridVariantsItems.filter((item) => item.id === gridVariant)[0];

    const [localSecondsPos, setLocalSecondsPos] = useState(0);
    const [isDragDisabled, setIsDragDisabled, isDragDisabledRef] = useState(false)

    const [fadeInInProccess, setFadeInInProcess] = useState(false);
    const [fadeOutInProccess, setFadeOutInProcess] = useState(false);

    const handleMouseMove = (event) => {

        /*let tgt = event.target;
        if (!event.target.classList.contains(styles.wrapper)) {
            if (event.target.parentNode && event.target.parentNode.classList.contains(styles.wrapper)) {
                tgt = event.target.parentNode;
            } else {
                if (event.target.parentNode && event.target.parentNode.parentNode && event.target.parentNode.parentNode.classList.contains(styles.wrapper)) {
                    tgt = event.target.parentNode.parentNode;
                } else {
                    if (event.target.parentNode && event.target.parentNode.parentNode && event.target.parentNode.parentNode.parentNode && event.target.parentNode.parentNode.parentNode.classList.contains(styles.wrapper)) {
                        tgt = event.target.parentNode.parentNode.parentNode;
                    }
                }
            }
        }

        console.log(tgt)*/
        //console.log(event.target)


        const bounds = wavesurferRef.current.getBoundingClientRect();

        const localX = event.clientX - bounds.x + 1;

        setLocalSecondsPos(localX / gridOptions.secondsInPixels);

        if (
            event.target.classList.contains(styles.fadeInPicker) ||
            (event.target.parentNode && event.target.parentNode.parentNode && event.target.parentNode.parentNode.classList.contains(styles.fadeInPicker)) ||
            event.target.classList.contains(styles.fadeOutPicker) ||
            (event.target.parentNode && event.target.parentNode.parentNode && event.target.parentNode.parentNode.classList.contains(styles.fadeOutPicker))
        ) {
            setIsDragDisabled(true)
        } else {
            setIsDragDisabled(false)
        }

        if (fadeInInProccess) {
            if (localSecondsPos > maxFadeInOut) {
                setFadeIn(maxFadeInOut);
            } else if (localSecondsPos < 0) {
                setFadeIn(0);
            } else {
                setFadeIn(localSecondsPos);
            }

        }

        if (fadeOutInProccess) {
            if (duration - localSecondsPos < 0) {
                setFadeOut(0);
            } else if (duration - localSecondsPos > maxFadeInOut) {
                setFadeOut(maxFadeInOut);
            } else {
                setFadeOut(duration - localSecondsPos);
            }

            //console.log('FADE OUT PICKER END')
        }

        //console.log('FADE IN:' , localX);
    };

    const handleOnFadeInStart = (e) => {
        setFadeInInProcess(true);
        //console.log('START FADE IN PICKER')
    }

    const handleOnFadeOutStart = (e) => {
        setFadeOutInProcess(true);
        //console.log('START FADE OUT PICKER')
    }

    const handleOnFadeInOutEnd = (e) => {
        setFadeInInProcess(false);
        setFadeOutInProcess(false);
        //console.log('END FADE IN OUT PICKER')
    }

    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: 'audio',
            item: { id, left, top, sourceTrackId: trackId },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
            canDrag: () => !isDragDisabledRef.current,
        }),
        [id, left, top],
    )

    const wavesurferRef = useRef(null);

    useEffect(() => {

        //console.log('AUDIO TRACK FRAGMENT UPDATE');


        if(wavesurferRef.current) {
            wavesurferRef.current.innerHTML = ''

            const wavesurfer = WaveSurfer.create({
                container: wavesurferRef.current,
                waveColor: 'rgb(0,0,0, 0.5)',
                progressColor: 'rgb(0, 0, 0)',
                height: mob ? 60 : 80,
                interact: false,
                cursorWidth: 0,
                className: styles.wavesurfer,
            })

            wavesurfer.load(audio)
        }
    }, [width, audio, mob]);

    const wrapperStyle = {
        left: left,
        width: width,
        "--widthFadeIn": fadeIn * gridOptions.secondsInPixels + "px",
        "--degFadeIn": 94 + fadeIn * 16 + "deg",
        "--widthFadeOut": fadeOut * gridOptions.secondsInPixels + "px",
        "--degFadeOut": -(94 + fadeOut * 16) + "deg",
    }

    useEffect(() => {
        window.addEventListener("mouseup", handleOnFadeInOutEnd);

        return () => window.removeEventListener("mouseup", handleOnFadeInOutEnd);
    }, [fadeInInProccess, fadeOutInProccess]);


    return (
        <div
            onMouseMove={handleMouseMove}
            onMouseUp={handleOnFadeInOutEnd}
            ref={drag}
            className={
            cn(styles.wrapper,
                styles[style],
                mute && styles.mute,
                currentAudioFragment === id && styles.selected,
                styles[activeCursor],
            )}
            style={wrapperStyle}
        >
            <div ref={wavesurferRef}></div>


            {!isMinus && !mob && (
                <span className={styles.fadeInPicker} style={{left: fadeIn * gridOptions.secondsInPixels - 7 }} onMouseDown={handleOnFadeInStart}>
                    <TriangleTopLeftIcon />
                </span>
            )}

            {!isMinus && !mob && (
                <span className={styles.fadeOutPicker} style={{right: fadeOut * gridOptions.secondsInPixels - 7 }} onMouseDown={handleOnFadeOutStart}>
                    <TriangleTopRightIcon />
                </span>
            )}

        </div>
    )
}