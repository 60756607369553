import cn from "classnames";
import React, { useState } from "react";
import useMediaQuery from "../../utils/hooks/common/useMediaQuery";
import Thumb from "../UI/Thumb/Thumb";
import styles from "./effectsBody.module.scss";
import MobileSliderCarousel from "../MobileSliderCarousel/MobileSliderCarousel";
import GradientButton from "../UI/GradientButton/GradientButton";

export default function EffectsBody({
    children,
    title,
    color,
    width,
    thumbColor,
    active,
    setActive,
    buttonsColor,
    onSubmit
}) {
    const mob915 = useMediaQuery(915);


    return (
        <>
            <div
                className={cn(
                    styles.wrapper,
                    styles[width],
                    !active && mob915 ? styles.body_disable : ""
                )}
            >
                <div className={cn(styles.header, styles[color])}>
                    <div className={styles.header__title}>
                        <p>{title}</p>
                        <Thumb
                            onclick={() => setActive((prev) => !prev)}
                            thumbColor={thumbColor}
                            value={active}
                        />
                    </div>

                    {onSubmit && (
                        <GradientButton size={"modal"} color={"violet"} onClick={onSubmit} elementStyle={{width: 'auto'}}>
                            Применить
                        </GradientButton>
                    )}
                </div>
                <div className={styles.body}>
                    <div
                        className={
                            active === false &&
                            mob915 !== true &&
                            styles.body_disable
                        }
                    >
                        {children}
                    </div>
                </div>

            </div>
        </>
    );
}
