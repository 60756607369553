import React, {useContext, useEffect, useState, useRef} from "react";
import GradientButton from "../UI/GradientButton/GradientButton";
import { ReactComponent as MuteMic } from "../../assets/icons/mute_microphone.svg";
import { ReactComponent as Mic } from "../../assets/icons/microphone_record.svg";
import styles from "./recordingPanel.module.scss";
import context from "../../context/Context";

export default function RecordingPanel({ hide, active, setActive }) {
    const [isPause, setIsPause] = useState(false);

    const [timeFormatted, setTimeFormatted] = useState("00:00:00");
    const [mixersTitle, setMixersTitle] = useState("");


    const {
        stopRecordingHandler,
        setIsMetronomePlaying,
        audioStreamRef,
        mediaRecorderRef,
        isRecording,
        recordingTime,
        getAudioById,
        currentAudioTrack
    } = useContext(context)

    const handleStopRecording =() => {
        setIsPause(false);
        stopRecordingHandler();
        //setIsMetronomePlaying(false);
        setActive(false);
    }

    const handlePause = () => {
        if (isRecording) {
            mediaRecorderRef.current.pause();
            audioStreamRef.current.pause();
            setIsPause(true);
        } else {
            mediaRecorderRef.current.resume();
            audioStreamRef.current.resume();
            setIsPause(false)
        }
    }

    useEffect(() => {
        const formatted = new Date(recordingTime * 1000).toISOString().substr(11, 8);
        setTimeFormatted(formatted);

    }, [recordingTime]);

    useEffect(() => {
        if (currentAudioTrack !== null) {
            const audioTrack = getAudioById(currentAudioTrack);
            setMixersTitle(audioTrack.title);
        }
    }, [currentAudioTrack])
    
    return (
        <>
            {hide === true ? (
                ""
            ) : (
                <div className={styles.record__wrapper}>
                    <div className={styles.first__section}>
                        <div>
                            {isPause === true ? (
                                <>
                                    <div className={styles.svg}>
                                        <MuteMic />
                                    </div>
                                    <p>запись на паузе</p>
                                </>
                            ) : (
                                <>
                                    <div className={styles.svg}>
                                        <Mic />
                                    </div>
                                    <p>
                                        ИДЁТ ЗАПИСЬ ДОРОЖКИ{" "}
                                        <span>{mixersTitle}...</span>
                                    </p>
                                </>
                            )}
                        </div>
                        <div>
                            {/*<GradientButton
                                color={!isPause ? "red" : "red_active"}
                                onClick={handlePause}
                            >
                                {isPause === true ? "запись" : "пауза"}
                            </GradientButton>*/}
                            <GradientButton
                                color={"red_active"}                                
                                onClick={handleStopRecording}
                            >
                                стоп
                            </GradientButton>
                            {/*<GradientButton
                                onClick={() => downloadRecording()}
                            >
                                скачать
                            </GradientButton>*/ }
                        </div>
                    </div>
                    <div className={styles.second__section}>
                        <p>время записи</p>
                        <div>{timeFormatted}</div>
                    </div>
                </div>
            )}
        </>
    );
}
