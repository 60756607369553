import React, {useContext, useState, useRef, useEffect, useCallback} from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'

import styles from './audioTracks.module.scss'
import cn from 'classnames'
import TrackLeftSide from "../TrackLeftSide/TrackLeftSide";
import { ReactComponent as Basket } from "../../assets/icons/basket.svg";
import { ReactComponent as Minus } from "../../assets/icons/minus.svg";
import { ReactComponent as Plus } from "../../assets/icons/plus.svg";
import { ReactComponent as Cog } from "../../assets/icons/cog.svg";
import context from "../../context/Context";
import AudioTrack from "../AudioTrack/audioTrack";
import ModalError from "../Modal/Modals/ModalError";
import useErrorModal from "../../utils/hooks/project/useErrorModal";
import useMediaQuery from "../../utils/hooks/common/useMediaQuery";

export default function AudioTracks({ items, setActive })
{
    const mob = useMediaQuery(915);

    const {
        setIsRecording,
        startRecordingHandler,
        setIsRecordingPanelActive,
        isRecording,
        stopRecordingHandler,
        isMetronomePlaying,
        setIsMetronomePlaying,
        maxTrackDuration,
        gridVariantsItems,
        gridVariant,
        changeGridMinus,
        changeGridPlus,
        currentAudioTrack,
        getAudioById,
        updateAudioById,

        isPlaying,
        playingPosition,

        activeCursor,
        setPlayingPositionHandler,
        addToHistoryHandler,

        mixerIsActive,
        setMixerIsActive,

        setCurrentPresetId,
        setCurrentEffectId,
        setCurrentAudioTrack
    } = useContext(context)

    const [localMousePos, setLocalMousePos] = useState({x: 0, y: 0});

    const myBodyRef = useRef(null)
    const myWrapperRef = useRef(null)

    const handleMouseMove = (event) => {

        //console.log(event.target.classList);

        if (!event.target.classList.contains(styles.wrapper__grid__tracks) &&
            !event.target.classList.contains(styles.wrapper__grid__tracks__cut_line) &&
            !event.target.classList.contains(styles.wrapper__grid__tracks__play_line)
        ) {

            let targetElement = event.target;
            if (!targetElement.classList.length) {
                targetElement = event.target.parentNode;
            }

            const bounds = myWrapperRef.current.getBoundingClientRect();
            const localX = event.clientX - bounds.x + 1 + targetElement.offsetLeft;
            const localY = event.clientY - bounds.y + 1 + targetElement.offsetTop;

            setLocalMousePos({x: localX, y: localY});


            //console.log('MOUSE POS: ', localX, 'x', localY)
            //console.log('eTarget: ', targetElement);
            /*console.log('clientX: ', event.clientX);
            console.log('boundsX: ', event.target.getBoundingClientRect().x);
            console.log('offsetLeft: ', event.target.offsetLeft);
            console.log('bodyOffsetLeft: ', myWrapperRef.current.getBoundingClientRect().x);*/
        }
    };

    const [recordActive, setRecordActive] = useState(false);

    const {
        show: showErrorRecordModal,
        hide: hideErrorRecordModal,
        visible: isErrorRecordModalVisible,
        title: errorRecordModalTitle,
        subtitle: errorRecordModalSubtitle,
        message: errorRecordModalMessage
    } = useErrorModal('Ошибка',  'Вы не можете начать запись на эту дорожку так как на ней уже есть аудио-фрагменты.', 'Пожалуйста, выберите другую дорожку.');


    const handleRecordClick = (id) => {

        if (id) {
            const item = getAudioById(id);
            if (item.samples.length > 0) {
                showErrorRecordModal();
                return;
            }
        }

        if (isRecording) {
            setRecordActive(null);
            setActive(true);
            stopRecordingHandler();
            setIsMetronomePlaying(!isMetronomePlaying);
            setIsRecording(true);
            setIsRecordingPanelActive(false);

        } else {
            setRecordActive(id);
            setActive(true);
            startRecordingHandler(id);
            setIsMetronomePlaying(!isMetronomePlaying);
            setIsRecording(true);
        }

    };

    const handleClearTrack = (id) => {
        if (!isPlaying) {

            let item = getAudioById(id);
            item.audio = '';
            item.samples = [];
            item.FX = {};
            item.preset = '';
            item.bpm = 0;
            item.key = '';
            updateAudioById(id, item);

            addToHistoryHandler();
        }
    }

    const handleTrackSettings = (id) => {
        // show mixers modal without mixer
        setCurrentPresetId("");
        setCurrentEffectId("");
        setCurrentAudioTrack(id);
        setMixerIsActive(true);
    }

    const gridOptions = gridVariantsItems.filter((item) => item.id === gridVariant)[0];

    let ruler = [];

    for (let i = 1; i < maxTrackDuration / gridOptions.step; i++) {
        ruler.push({
            label: i * gridOptions.step,
            position: (i * gridOptions.step) * gridOptions.secondsInPixels - gridOptions.secondsInPixels,
            width: gridOptions.secondsInPixels
        });
    }

    const setCurrentPosition = () => {
        const position = localMousePos.x / gridOptions.secondsInPixels < 0.5 ? 0 : localMousePos.x / gridOptions.secondsInPixels;

        if (!isPlaying && activeCursor !== 'cut') {
            setPlayingPositionHandler(position);
        }
    }

    useEffect(() => {
        if (isPlaying || isRecording) {
            //console.log(myBodyRef.current.scrollLeft);

            if (playingPosition === 0) {
                myBodyRef.current.scrollLeft = 0;
            }

            if (myBodyRef.current.scrollLeft < playingPosition * gridOptions.secondsInPixels - 300) {
                myBodyRef.current.scrollLeft = playingPosition * gridOptions.secondsInPixels - 300;
            } else if (myBodyRef.current.scrollLeft > playingPosition * gridOptions.secondsInPixels + 300) {
                myBodyRef.current.scrollLeft = playingPosition * gridOptions.secondsInPixels - 300;
            }
        } else {
            if (playingPosition === 0) {
                myBodyRef.current.scrollLeft = 0;
            }
        }
    }, [isPlaying, isRecording, playingPosition]);



    /* SCROLL BARS */
    const scrollTrackRef = useRef(null);
    const scrollThumbRef = useRef(null);
    const scrollbarRef = useRef(null);
    const observer = useRef(null);
    const [thumbWidth, setThumbWidth] = useState(20);
    const [scrollBarWidth, setScrollBarWidth] = useState(0);
    const [scrollStartPosition, setScrollStartPosition] = useState(null);
    const [initialScrollLeft, setInitialScrollLeft] = useState(0);
    const [isDragging, setIsDragging] = useState(false);

    function handleResize(ref, trackSize) {
        const { clientWidth, scrollWidth } = ref;
        setScrollBarWidth(trackSize);
        //console.log('clientWidth: ', clientWidth, 'scrollWidth: ', scrollWidth, 'trackSize: ', trackSize);

        setThumbWidth(Math.max((clientWidth / scrollWidth) * trackSize, 20));
    }

    // If the content and the scrollbar track exist, use a ResizeObserver to adjust height of thumb and listen for scroll event to move the thumb
    useEffect(() => {
        if (myBodyRef.current && scrollTrackRef.current) {
            const ref = myBodyRef.current;
            const {clientWidth: trackSize} = myBodyRef.current;
            observer.current = new ResizeObserver(() => {
                //console.warn('ResizeObserver: ', trackSize)

                handleResize(ref, trackSize);
            });
            observer.current.observe(ref);
            ref.addEventListener('scroll', handleThumbPosition);
            return () => {
                observer.current?.unobserve(ref);
                ref.removeEventListener('scroll', handleThumbPosition);
            };
        }
    }, []);

    const handleThumbPosition = useCallback(() => {
        if (!myBodyRef.current || !scrollTrackRef.current || !scrollThumbRef.current) {
            return;
        }

        const { scrollLeft: contentLeft, scrollWidth: contentWidth } = myBodyRef.current;
        const { clientWidth: trackWidth } = scrollTrackRef.current;

        let newLeft = (+contentLeft / +contentWidth) * trackWidth;
        newLeft = Math.min(newLeft, trackWidth - thumbWidth);
        const thumb = scrollThumbRef.current;
        thumb.style.left = `${newLeft}px`;
    }, []);


    const handleTrackClick = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            const { current: trackCurrent } = scrollTrackRef;
            const { current: contentCurrent } = myBodyRef;
            if (trackCurrent && contentCurrent) {
                // First, figure out where we clicked
                const { clientX } = e;
                // Next, figure out the distance between the top of the track and the top of the viewport
                const target = e.target;
                const rect = target.getBoundingClientRect();
                const trackLeft = rect.left;
                // We want the middle of the thumb to jump to where we clicked, so we subtract half the thumb's height to offset the position
                const thumbOffset = -(thumbWidth / 2);
                // Find the ratio of the new position to the total content length using the thumb and track values...
                const clickRatio =
                    (clientX - trackLeft + thumbOffset) / trackCurrent.clientWidth;
                // ...so that you can compute where the content should scroll to.
                const scrollAmount = Math.floor(
                    clickRatio * contentCurrent.scrollWidth
                );
                // And finally, scroll to the new position!
                contentCurrent.scrollTo({
                    left: scrollAmount,
                    behavior: 'smooth',
                });
            }
        },
        [thumbWidth]
    );

    const handleThumbMousedown = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setScrollStartPosition(e.clientX);
        if (myBodyRef.current) setInitialScrollLeft(myBodyRef.current.scrollLeft);
        setIsDragging(true);
    }, []);

    const handleThumbMouseup = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (isDragging) {
                setIsDragging(false);
            }
        },
        [isDragging]
    );

    const handleThumbMousemove = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (isDragging) {
                const {
                    scrollWidth: contentScrollWidth,
                    offsetWidth: contentOffsetWidth,
                } = myBodyRef.current;

                // Subtract the current mouse x position from where you started to get the pixel difference in mouse position.
                // Multiply by ratio of visible content width to thumb width to scale up the difference for content scrolling.
                const deltaX = (e.clientX - scrollStartPosition) * (contentOffsetWidth / thumbWidth);
                const newScrollLeft = Math.min(initialScrollLeft + deltaX, contentScrollWidth - contentOffsetWidth);

                myBodyRef.current.scrollLeft = newScrollLeft;
            }
        },
        [isDragging, scrollStartPosition, thumbWidth]
    );

    // Listen for mouse events to handle scrolling by dragging the thumb
    useEffect(() => {
        if (scrollbarRef.current === null) return;

        scrollbarRef.current.addEventListener('mousemove', handleThumbMousemove);
        scrollbarRef.current.addEventListener('mouseup', handleThumbMouseup);
        scrollbarRef.current.addEventListener('mouseleave', handleThumbMouseup);

        return () => {
            if (scrollbarRef.current === null) return;

            scrollbarRef.current.removeEventListener('mousemove', handleThumbMousemove);
            scrollbarRef.current.removeEventListener('mouseup', handleThumbMouseup);
            scrollbarRef.current.removeEventListener('mouseleave', handleThumbMouseup);
        };
    }, [handleThumbMousemove, handleThumbMouseup]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.wrapper__grid}>
                {/*<div style={{minWidth: 200, paddingTop: 20}}>
                    <h3>DEBUG:</h3>
                    <div style={{marginTop: 10}}>
                        <h4>HISTORY:</h4>
                        <p>length: {history.length}</p>
                        <p>current index: {currentHistoryIndex}</p>
                        <p>data: {history.map((item, key) => {
                            return (
                                <p>
                                    # {key} - {item[1].samples.length}
                                </p>
                            )
                        })} </p>
                    </div>
                </div>*/}
                <div className={styles.wrapper__grid__headers}>

                    {items.map((item, key) => (
                        <TrackLeftSide
                            // isMute={isMute}
                            // setIsMute={setIsMute}
                            key={key}
                            id={item.id}
                            pan={item.pan}
                            title={item.title}
                            muteColor={
                                recordActive === item.id
                                    ? item.style
                                    : ""
                            }
                            recButton={item.recButton}
                            uploadButton={item.uploadButton}
                            handleRecord={() => handleRecordClick(item.id)}

                            recActive={
                                recordActive === item.id && isRecording ? "recActive" : ""
                            }
                            containerBg={item.containerBg}
                            isMute={item.mute}
                            volume={item.volume}
                            balance={item.balance}
                            audio={item.audio}
                            samples={item.samples}
                        />
                    ))}
                </div>
                <div className={cn(styles.wrapper__grid__tracks, styles[gridOptions.className])}
                     ref={myBodyRef}
                     onMouseMove={handleMouseMove}
                     onClick={setCurrentPosition}
                >
                    <div className={styles.wrapper__grid__tracks__play_line} style={{
                        left: playingPosition * gridOptions.secondsInPixels,
                        display: 'block'
                    }}></div>

                    <div className={styles.wrapper__grid__tracks__cut_line} style={{
                        left: localMousePos.x,
                        display: activeCursor === 'cut' ? 'block' : 'none'
                    }}></div>

                    <div className={styles.wrapper__grid__tracks__ruler} ref={myWrapperRef}>
                        {ruler.map((item) => (
                            <div key={item.position} style={{left: item.position, width: item.width}}>
                                {item.label}
                            </div>
                        ))}
                    </div>


                    <DndProvider backend={mob ? TouchBackend : HTML5Backend}>
                        {items.map((item, key) => (
                            <AudioTrack key={item.id} item={item} localMousePos={localMousePos} />
                        ))}
                    </DndProvider>

                    <div className={styles.wrapper__grid__tracks__custom_scrollbars__scrollbar} style={{
                        width: `${scrollBarWidth}px`,
                    }} ref={scrollbarRef}>
                        <div className={styles.wrapper__grid__tracks__custom_scrollbars__track_and_thumb}>
                            <div className={styles.wrapper__grid__tracks__custom_scrollbars__track}
                                 ref={scrollTrackRef}
                                 onClick={handleTrackClick}
                                 style={{ cursor: isDragging ? 'grabbing' : 'pointer' }}
                            ></div>
                            <div className={styles.wrapper__grid__tracks__custom_scrollbars__thumb}
                                 ref={scrollThumbRef}
                                 onMouseDown={handleThumbMousedown}
                                 style={{
                                     width: `${thumbWidth}px`, cursor: isDragging ? 'grabbing' : 'grab',
                                 }}
                            ></div>
                        </div>
                    </div>
                </div>
                <div className={styles.wrapper__grid__actions}>
                    {items.map((item) => (
                        <div key={item.id} onClick={() => mob ? handleTrackSettings(item.id) : handleClearTrack(item.id)} className={cn(styles.wrapper__grid__actions__row, currentAudioTrack === item.id && styles[item.style], item.mute && styles.mute)}>
                            {mob ? (<Cog />) : (<Basket />)}
                        </div>
                    ))}
                </div>
            </div>

            <div className={styles.wrapper__grid__bottom}>
                <div className={cn(styles.wrapper__grid__bottom__minus, gridVariant == 1 && styles['disabled'])} onClick={changeGridMinus}>
                    <Minus />
                </div>
                <p>сетка</p>
                <div className={cn(styles.wrapper__grid__bottom__plus, gridVariant == gridVariantsItems.length && styles['disabled'])} onClick={changeGridPlus}>
                    <Plus />
                </div>
            </div>

            <ModalError title={errorRecordModalTitle} subtitle={errorRecordModalSubtitle} message={errorRecordModalMessage} visible={isErrorRecordModalVisible} onClose={hideErrorRecordModal} />
        </div>
    )
}